import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import axios from "axios";

import _ from "lodash";

/**
 * get BestClientApi.
 * @public
 */

export const BestClientApi = createAsyncThunk(
  "statistique/BestClientApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { date_debut, date_fin , organisation_id , societe_id } =  data;
     
      const response = await axios.get(
        `statistique/BestClientApi/${date_debut}/${date_fin}/${organisation_id}/${societe_id}`, config );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * get  BestVendeurApi.
 * @public
 */

export const BestVendeurApi = createAsyncThunk(
    "statistique/BestVendeurApi",
    async (data: any, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event: any) => {
            console.log(
              `Current progress:`,
              Math.round((event.loaded * 100) / event.total)
            );
          },
        };
  
        const { date_debut, date_fin , organisation_id , societe_id } =  data;
       
        const response = await axios.get(
          `statistique/BestVendeurApi/${date_debut}/${date_fin}/${organisation_id}/${societe_id}`, config );
        return response.data;
      } catch (error: any) {
        console.log(error);
        return rejectWithValue({
          success: false,
          message: error.response.data.message,
        });
      }
    }
  );

/**
 * get  BestOrganisation.
 * @public
 */

export const BestOrganisationApi = createAsyncThunk(
    "statistique/BestOrganisationApi",
    async (data: any, { rejectWithValue }) => {
      try {
        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event: any) => {
            console.log(
              `Current progress:`,
              Math.round((event.loaded * 100) / event.total)
            );
          },
        };
  
        const { date_debut, date_fin  , societe_id } =  data;
       
        const response = await axios.get(
          `statistique/BestOrganisationApi/${date_debut}/${date_fin}/${societe_id}`, config );
        return response.data;
      } catch (error: any) {
        console.log(error);
        return rejectWithValue({
          success: false,
          message: error.response.data.message,
        });
      }
    }
  );
  

interface statistique {
    listClient: any[];
    listVendeur: any[];
    listOrganisation:any[]
   
  }

  
  const initialState: statistique = {
    listClient: [],
    listVendeur: [],
    listOrganisation:[]

  
  };
  export const statistiqueSlice = createSlice({
    name: "statistiques",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(BestClientApi.fulfilled, (state, action) => {
        return {
          ...state,
          listClient: action.payload,
        };
      });
      builder.addCase(BestVendeurApi.fulfilled, (state, action) => {
        return {
          ...state,
          listVendeur: action.payload,
        };
      });
      builder.addCase(BestOrganisationApi.fulfilled, (state, action) => {
        return {
          ...state,
          listOrganisation: action.payload,
        };
      });
    },
  });
  

  export const ListClientstat = (state: RootState) => state.statistiques.listClient

  export const ListVendeurstat = (state: RootState) => state.statistiques.listVendeur

  export const ListOrganisationstat = (state: RootState) => state.statistiques.listOrganisation

  export default statistiqueSlice.reducer;
