import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  FactureItemType,
  FactureType,
} from "../../../Types/Entites/FactureType";
import { RootState } from "../../store";
type CreateFactureParams = {
  facture: FactureType;
  items: any[];
};
// Async Thunks for API Calls
export const saveFactureApi = createAsyncThunk(
  "facture/createFactureApi",
  async (data: CreateFactureParams, { rejectWithValue }) => {
    try {
      const response = await axios.post("facture/createFactureApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFactures = createAsyncThunk(
  "facture/getFacturesApi",
  async (x: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("facture/getFacturesApi");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFacture = createAsyncThunk(
  "facture/updateFactureApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `facture/updateFactureApi/${data.id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFacture = createAsyncThunk(
  "facture/deleteFactureApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`facture/deleteFactureApi/${id}`);
      return { id };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFactureByJournal = createAsyncThunk(
  "facture/getFactureApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`facture/getFactureApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Initial state for the facture slice
interface factureState {
  listFacture: any[];
  error: string | null;
  success: boolean;
}

const initialState: factureState = {
  listFacture: [],
  error: null,
  success: false,
};

// Create facture slice
export const factureSlice = createSlice({
  name: "factures",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle actions for saving a facture
    builder.addCase(saveFactureApi.fulfilled, (state, action) => {});

    // Handle actions for fetching all factures
    builder.addCase(fetchFactures.fulfilled, (state, action) => {
      return {
        ...state,
        listFacture: action.payload.data,
      };
    });
  },
});

// Export actions for dispatching, if necessary

// Selectors to access the state in components
export const selectFactures = (state: RootState) => state.facture.listFacture;

export default factureSlice.reducer;
