import React from 'react'
import PageTitle from '../../../Shared/PageTitle/PageTitle'
import AddVente from '../../../Components/Ventes/AddVentes/AddVente'
export default function AddVentePage() {
  return (
    
    <div>
      <PageTitle title="Ventes" />
      <AddVente />
      </div>
  )
}
