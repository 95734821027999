import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import JsBarcode from "jsbarcode";
import { formatFloat } from "../../_App/Helpers/helpers";
import { useAppSelector } from "../../_App/Redux/hooks";
import { SelectedOrganisation } from "../../_App/Redux/Slices/Auth/authSlice";

export default function TicketArticle({
  ShowModal,
  setShowModal,
  tickets,
  t,
  stateQte
}: any) {
  const organisation = useAppSelector(SelectedOrganisation);
  const componentRef = useRef(null);
  const [list, setlist] = useState(tickets);
  const [qtearticle, setqtearticle] = useState<number>(0);

  useEffect(() => {
    if (qtearticle !== 0) {
      const newtickets = tickets.map((item: any) => {
        return { ...item, qte: qtearticle };
      });
      setlist(newtickets);
    } else {
      setlist(tickets);
    }
  }, [tickets, qtearticle]); 
  const generateBarcode = (code_a_barre: any) => {
    // Create a canvas element to render the barcode
    const canvas = document.createElement("canvas");

    JsBarcode(canvas, code_a_barre, {
      format: "CODE128",
      width: 1,
      height: 20,
      displayValue: true,
      fontSize: 20,
    });
    return canvas.toDataURL("image/png"); // Return the barcode as a data URL
  };
  const [model, setmodel] = useState("m2");

  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      className="modal-9"
      backdropClassName="modal-backdrop-9"
    >
      <Modal.Body>
        {model === "m1" ? (
          <div ref={componentRef} className="print-content-p row">
            {list.map((ticket: any, index: number) =>
              Array.from({ length: ticket.qte }, (_, i: number) => i + 1).map(
                (n: number, j: number) => (
                  <div className="ticket-p" key={index + "_" + j}>
                    <div  className="ticketcontenu-p" > 
                    <div>
                      {t("Réf")}: <strong> {ticket?.ref?.split('-')[0] }</strong>
                    </div>
                   
                    <div>
                      {t("Taille")}: <strong>{ticket?.taille ? ticket?.taille  : ticket?.titre?.split(' ')[2]  }</strong>
                    </div>
                    <div>
                      {t("Coul")}: <strong>{ticket?.couleur ? ticket?.couleur : ticket?.titre?.split(' ')[3]}</strong>
                    </div>
                    <div>
                      {t("F")}: {ticket?.marque}
                    </div>
                    <div>
                      {t("Prix")}:{" "}
                      <strong className={ticket.solde !== null ? "baree" : ""}>
                        {formatFloat(ticket?.prix)} {organisation?.currency}
                      </strong>
                    </div>
                    {ticket.solde !== null && (
                    <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        color: "white",
                        fontSize: "15px",
                        fontWeight: "bold",
                        lineHeight: "50px",
                      }}
                    >
                      {ticket.solde}%
                    </div>

                    <div className="row">
                      <strong
                        style={{
                          color: "red",
                          margin: 0,
                          fontSize: "12px",
                        }}
                      >
                        {formatFloat(
                          ticket.prix * ((100 - ticket.solde) / 100)
                        )}{" "}
                        {organisation?.currency}
                      </strong>
                     
                    </div>
                  </div>
                    )}
                    <div style={{ fontSize: "10px" }}>
                      {ticket?.coderecption}
                    </div>
                    <div>
                      <img
                        style={{ width: "100px" }}
                        src={generateBarcode(ticket?.code_a_barre)}
                        alt="Barcode"
                      />
                    </div>
                  </div>
                  </div>
                )
              )
            )}
          </div>
        ) : (
          <div ref={componentRef} className="print-content ">
            {list.map((ticket: any, index: number) =>
              Array.from({ length: ticket.qte }, (_, i: number) => i + 1).map(
                (n: number, j: number) => (
                  <div className="ticket " key={index + "_" + j}>
                    <div
                      className="ticketcontenu"
                    >
                      <div>
                        {t("Réf")}: <strong>  {ticket?.ref?.split('-')[0] }</strong>
                      </div>
                    
                      <div>
                        {t("Taille")}: <strong>{ticket?.taille ? ticket?.taille  : ticket?.titre?.split(' ')[2]  }</strong>
                      </div>
                      <div>
                        {t("Coul")}: <strong>{ticket?.couleur ? ticket?.couleur.libelle || ticket?.couleur :ticket?.titre?.split(' ')[3]}</strong>
                      </div>
                      <div>
                        {t("F")}: <span style={{fontSize:'15px'}}>{ticket?.marque}</span>
                      </div>
                      <div style={{ fontSize: "10px" }}>
                        {ticket?.coderecption}
                      </div>
                      <div>
                        <img
                          style={{ width: "100px" }}
                          src={generateBarcode(ticket?.code_a_barre)}
                          alt="Barcode"
                        />
                      </div>
                      <div>
                        {ticket.solde !== null ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "red",
                                color: "white",
                                fontSize: "15px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                              }}
                            >
                              {ticket.solde}%
                            </div>

                            <div className="row">
                              <strong
                                style={{
                                  color: "red",
                                  margin: 0,
                                  fontSize: "16px",
                                }}
                              >
                                {formatFloat(
                                  ticket.prix * ((100 - ticket.solde) / 100)
                                )}{" "}
                                {organisation?.currency}
                              </strong>
                              <p className="baree">
                                {formatFloat(ticket.prix)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <><strong style={{ fontSize: "20px" }}>
                              Prix{" "}
                            </strong><strong style={{ fontSize: "20px" }}>
                                {  formatFloat(ticket?.prix)}
                               
                              </strong></>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="row">
        <ReactToPrint
          trigger={() => (
            <Button className="col-6" variant="primary">
              Imprimer
            </Button>
          )}
          content={() => componentRef.current}
        />
        <div className="col-2">
          {" "}
          <input
            type="radio"
            onClick={() => setmodel("m1")}
            checked={model === "m1"}
          />{" "}
          {t("model1")}
          <input
            type="radio"
            onClick={() => setmodel("m2")}
            checked={model === "m2"}
          />{" "}
          {t("model2")}
        </div>
        {stateQte&&
         <><div className="col-1"> Qte ticket</div><div className="col-2">
            <input
              type="number"
              className="form-control "
              value={qtearticle}
              onChange={(e) => setqtearticle(parseInt(e.target.value) || 0)} />
          </div></>}
       
      </Modal.Footer>
    </Modal>
  );
}
/*  
<div className="ticket col-3" key={index + "_" + j}>
                  <div>
                    {t("Réf")}: <strong> {ticket?.ref}</strong>
                  </div>
                  <div>
                    {t("Marque")}: <strong> {ticket?.marque}</strong>
                  </div>
                  <div>
                    {t("Taille")}: <strong>{ticket?.taille}</strong>
                  </div>
                  <div>
                    {t("Couleur")}: <strong>{ticket?.couleur}</strong>
                  </div>
                    <div style={{ fontSize: "10px" }}>{ticket?.coderecption}
                  </div>
                  <div>
                    <img
                      style={{ width: "100px" }}
                      src={generateBarcode(ticket?.code_a_barre)}
                      alt="Barcode"
                    />
                  </div>
                  <div>
                    {ticket.solde !== null ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            backgroundColor: "red",
                            color: "white",
                            fontSize: "15px",
                            fontWeight: "bold",
                            lineHeight: "50px",
                          }}
                        >
                          {ticket.solde}%
                        </div>

                        <div className="row">
                          <strong style={{ color: "red", margin: 0 ,fontSize:'16px' }}>
                            Prix soldé:
                            {formatFloat(
                              ticket.prix * ((100 - ticket.solde) / 100)
                            )}{' '}{organisation?.currency}{organisation?.currency}
                          </strong>
                          <p className="baree">{formatFloat(ticket.prix)}</p>
                        </div>
                      </div>
                    ) : (
                      <strong style={{ fontSize: "20px" }}>
                        Prix {formatFloat(ticket?.prix)}{' '}{organisation?.currency}{organisation?.currency}
                      </strong>
                    )}
                  </div>
                </div>*/
