import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ClientSchema } from "../../_App/Schemas/ClientSchema";
import ClientType from "../../_App/Types/Entites/ClientType";
import {
  createClientsApi,
  updateClientApi,
} from "../../_App/Redux/Slices/client/clientSlice";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";

export default function ClientForm({
  ShowModal,
  setShowModal,
  client,
  verifcationMsg,
}: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const societe = useAppSelector(societeId);

  // init form hooks
  const formOptions = {
    resolver: yupResolver(ClientSchema) as unknown as Resolver<ClientType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ClientType>(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (client) {
      reset({
        _id: client._id,
        nom: client.nom,
        prenom: client.prenom,
        mobile: client.mobile,
        email: client.email,
        date_naissance: client.date_naissance.split('T')[0],
        societe_id: client.societe_id,
        barCode: client.barCode,
        note: client.note,
        address: client.address,
      });
    } else {
      reset({
        nom: "",
        prenom: "",
        mobile: "",
        email: "",
        date_naissance: undefined,
        societe_id: "",
        barCode: "",
        note: "",
        address: "",
      });
    }
  }, [client]);
  const onSubmit = async (data: any) => {
    let response;

    data.societe_id = societe;
    if (data._id) {
      if (await verifcationMsg(t("vous voulez modifier le client")))
        response = await dispatch(updateClientApi(data));
    } else {
      response = await dispatch(createClientsApi(data));
    }
    if (response) {
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          nom: "",
          prenom: "",
          mobile: "",
          email: "",
          date_naissance: undefined,
          societe_id: "",
        });
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-1"
      dialogClassName="modal-50w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header>
        <Modal.Title className="c-primary"> {t("Ajouter client")}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={()=>setShowModal(false)}>&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="nom">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Nom")}
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.nom ? "is-invalid" : ""}`}
                  id="nom"
                  {...register("nom")}
                />

                <div className="invalid-feedback">
                  {errors.nom &&
                    errors.nom.message &&
                    errors.nom.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="prenom">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Prenom")}
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.prenom ? "is-invalid" : ""
                  }`}
                  id="prenom"
                  {...register("prenom")}
                />

                <div className="invalid-feedback">
                  {errors.prenom &&
                    errors.prenom.message &&
                    errors.prenom.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="barcode">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Code a barre")}
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.prenom ? "is-invalid" : ""
                  }`}
                  id="barcode"
                  {...register("barCode")}
                />

                <div className="invalid-feedback">
                  {errors.prenom &&
                    errors.prenom.message &&
                    errors.prenom.message.toString()}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="mobile">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Numero telephone")}
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.mobile ? "is-invalid" : ""
                  }`}
                  id="mobile"
                  {...register("mobile")}
                />

                <div className="invalid-feedback">
                  {errors.mobile &&
                    errors.mobile.message &&
                    errors.mobile.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="email">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Email")}
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  {...register("email")}
                />

                <div className="invalid-feedback">
                  {errors.email &&
                    errors.email.message &&
                    errors.email.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="date_naissance">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Date  naissance")}
                </label>
                <input
                  type="date"
                  className={`form-control ${
                    errors.date_naissance ? "is-invalid" : ""
                  }`}
                  id="date_naissance"
                  {...register("date_naissance")}
                />

                <div className="invalid-feedback">
                  {errors.date_naissance &&
                    errors.date_naissance.message &&
                    "date naissance obligatoire"}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label htmlFor="email">
                {" "}
                {t("Note")}
              </label>
              <textarea
                className={`form-control ${errors.note ? "is-invalid" : ""}`}
                id="note"
                {...register("note")}
              ></textarea>

              <div className="invalid-feedback">
                {errors.email &&
                  errors.email.message &&
                  errors.email.message.toString()}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" className="btn btn-primary-app">
            {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
