import React, { ChangeEvent, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  ConditionAchatType,
  ConditionClientType,
  ConditionProduitType,
  GratuiteType,
  ProgramFideliteType,
  RemiseType,
} from "../../_App/Types/Entites/RemiseType";
import { createFideliteProgramApi } from "../../_App/Redux/Slices/client/clientSlice";
import ConditionForms from "../Fidelite&RemiseConditionsForms/ConditionForms";
import {
  createConditionAchat,
  createConditionClient,
  createConditionProduit,
  createGratuite,
  createRemise,
  updateGratuiteApi,
} from "../../_App/Redux/Slices/remise/remiseSlice";
import { findArticleByBarCode } from "../../_App/Redux/Slices/article/articleSlice";
import {
  findCartesApi,
  ListCartes,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import {
  findCategoriesApi,
  ListCategoriesByCarte,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  CategorieSousCategories, 
  findSousCategoriesByCategorieApi,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import Select from "react-select";
import { formadate, formatdate } from "../../_App/Helpers/helpers";

export default function GratuiteForm({
  ShowModal,
  setShowModal,
  programFidelite,
  verifcationMsg,
}: any) {

  const today = new Date();
  const NextMonth = new Date(today);
  NextMonth.setMonth(today.getMonth() + 1); 

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentOrg = useAppSelector(SelectedOrganisationId);

  const [conditionClient, setConditionClient] =
    useState<ConditionClientType | null>(null);
  const [conditionAchat, setConditionAchat] =
    useState<ConditionAchatType | null>(null);
  const [conditionProduit, setConditionProduit] =
    useState<ConditionProduitType | null>(null);

  const [inputValue, setInputValue] = useState("");
  const [article, setArticle] = useState(null);
  const [rand, setRand] = useState(true);

  const { register, handleSubmit, reset, formState } = useForm<GratuiteType>();
  const { errors } = formState;

  const handleInputArticleChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const barcode = event.target.value;
    setInputValue(barcode);
    const resultArticle = await dispatch(findArticleByBarCode(barcode));
    if (resultArticle.payload) {
      setArticle(resultArticle.payload._id);
      setInputValue(resultArticle.payload.titre.fr); // Assuming the article name is in 'titre.fr'
    } else {
      // Handle the case where no article is found, if necessary
    }
  };

  const handleClearInput = () => {
    setInputValue("");
    setArticle(null);
  };

  useEffect(() => {
    if (programFidelite) {
      
      reset({
        _id: programFidelite._id,
        actif: programFidelite.actif,
        date_debut: formatdate(programFidelite.date_debut),
        date_fin: formatdate(programFidelite.date_fin),
        libelle: programFidelite.libelle,
        quantity: programFidelite.quantity,
        organisation_id: programFidelite.organisation_id,
        Article_id: programFidelite.Article_id?.titre?.fr,
        conditions: programFidelite.conditions,

        cumulable:programFidelite.cumulable
      });
      setInputValue(programFidelite.Article_id?.titre?.fr)
      setArticle(programFidelite.Article_id?._id);

    } else {
      reset({
        libelle: "",
        actif: true,
        date_debut: formatdate(today),
        date_fin: formatdate(NextMonth),
        quantity: 1,
        organisation_id: "",
        Article_id: "",
      });
    }
  }, [programFidelite]);

  const onSubmit = async (data: any) => {
    let response;

    data.organisation_id = currentOrg;
    data.Article_id = article;
    data.carte_id = selectedCarte;
    data.categorie_id = selectedCategorie;
    data.sous_categorie_id = selectedSousCategorie;
    if (data._id) {
 
      if (await verifcationMsg(t("vous voulez modifier le programme fidelité")))
       {
        const obj = {
          gratuite:data ,
          conditionProduit:{...conditionProduit,gratuite_id:data._id},
          conditionClient:{...conditionClient,gratuite_id:data._id},
          conditionAchat:{...conditionAchat,gratuite_id:data._id},
  
        }
        response = await dispatch(updateGratuiteApi(obj));

       } 
    } else {
      if (
        await verifcationMsg(t("vous voulez ajouter un programme fidelité"))
      ) {
        data.article_id = article;
        const response = await dispatch(createGratuite(data));
        if (response.payload.success) {
          setShowModal(false);
          if (conditionAchat) {
            conditionAchat.gratuite_id = response.payload.data._id;
            dispatch(createConditionAchat(conditionAchat));
          }
          if (conditionClient) {
            conditionClient.gratuite_id = response.payload.data._id;
            dispatch(createConditionClient(conditionClient));
          }
          if (conditionProduit) {
            conditionProduit.gratuite_id = response.payload.data._id;
            dispatch(createConditionProduit(conditionProduit));
          }
        }
      }
    }
    if (response) {
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          libelle: "",
          actif: true,
          date_debut: new Date(),
          date_fin: new Date(),
          quantity: 1,
          organisation_id: "",
        });
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };

  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [selectedCarte, setSelectedCarte] = useState<any>(null);
  const [selectedCategorie, setSelectedCategorie] = useState<any>(null);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any>(null);
  useEffect(() => {
    setConditionAchat(conditionAchat);
    setConditionClient(conditionClient);
    setConditionProduit(conditionProduit);
    if (currentOrganisation) {
      dispatch(findCartesApi(currentOrganisation));
      dispatch(findCategoriesApi(currentOrganisation));
      if (selectedCategorie)
        dispatch(findSousCategoriesByCategorieApi(selectedCategorie));
    }
  }, [rand, dispatch, selectedCategorie]);
  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategoriesByCarte);
  const sousCategories = useAppSelector(CategorieSousCategories);

  const handleChange = (option: any, type: string) => {
    if (type === "carte") {
      setSelectedCarte(option.value);
    } else if (type === "categorie") {
      setSelectedCategorie(option.value);
    } else {
      setSelectedSousCategorie(option.value);
    }
  };
  const CartesOptions = cartes?.map((option) => ({
    value: option._id,
    label: option.libelle.fr,
  }));
  const categoriesdOptions =
    selectedCarte &&
    categories[selectedCarte]?.map((option) => ({
      value: option._id,
      label: option.libelle.fr,
    }));

  const sousCategoriesOptions =
    selectedCategorie &&
    sousCategories.map((option) => ({
      value: option._id,
      label: option.libelle.fr,
    }));

  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-1"
      dialogClassName="modal-50w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {" "}
          {t("Ajouter un gratuité")}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="fidelite mb-5">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Libelle")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="libelle"
                    {...register("libelle")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="carte">
                    {" "}
                    <span style={{ color: "red" }}></span>
                    {t("Carte")}
                  </label>
                  <Select
                    value={selectedCarte?.label}
                    onChange={(e) => handleChange(e, "carte")}
                    options={CartesOptions}
                    isSearchable={true}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="categorie">
                    {" "}
                    <span style={{ color: "red" }}></span>
                    {t("Categorie")}
                  </label>
                  <Select
                    value={selectedCategorie?.label}
                    onChange={(e) => handleChange(e, "categorie")}
                    options={categoriesdOptions}
                    isSearchable={true}
                    isDisabled={selectedCarte ? false : true}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="sous">
                    {" "}
                    <span style={{ color: "red" }}></span>
                    {t("Sous categorie")}
                  </label>
                  <Select
                    value={selectedSousCategorie?.label}
                    onChange={(e) => handleChange(e, "sous_categorie")}
                    options={sousCategoriesOptions}
                    isSearchable={true}
                    isDisabled={selectedCategorie ? false : true}
                  />{" "}
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="Article">
                    {" "}
                    <span style={{ color: "red" }}></span>
                    {t("Article")}
                  </label>
                  <div className="input-button" style={{ display: "flex" }}>
                    <input
                      value={inputValue}
                      onChange={(e) => {
                        handleInputArticleChange(e);
                        setRand(!rand);
                      }}
                      type="text"
                      className="form-control"
                      id="Article"

                    />
                    {inputValue && (
                      <button
                        onClick={handleClearInput}
                        className="btn btn-secondary"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Date début")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    style={{
                      background: "#f1f2f3",
                      border: "none",
                      width: "100%",
                      height: "auto",
                    }}
                    id="datepicker"
                    {...register("date_debut")}
                  />{" "}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Date fin")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    style={{
                      background: "#f1f2f3",
                      border: "none",
                      width: "100%",
                      height: "auto",
                    }}
                    id="datepicker"
                    {...register("date_fin")}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Quantité")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="quantity"
                    {...register("quantity")}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="cumulable">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Cumulable")}
                  </label>
                  <input
                    type="checkbox"
                    id="cumulable"
                    {...register("cumulable")}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>
                    Et <span style={{ color: "red" }}></span>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="et"
                    {...register("conditions")}
                    value={"et"}
                  />
                  <label htmlFor="ou">
                    Ou <span style={{ color: "red" }}></span>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="ou"
                    value={"ou"}
                    {...register("conditions")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="conditions">
            <ConditionForms
              type={"gratuite"}
              setConditionAchat={setConditionAchat}
              setConditionClient={setConditionClient}
              setConditionProduit={setConditionProduit}
              programFidelite={programFidelite}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            className="btn btn-primary-app"
            onClick={(e) => setShowModal(false)}
          >
            {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
