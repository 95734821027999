import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const RetourEchangeConfirmationPage = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [error, setError] = useState('');
  const correctCode = '12345'; // Replace with the actual code

  const handleCodeSubmit = () => {
    if (inputCode === correctCode) {
      setIsAuthorized(true);
      setError('');
    } else {
      setError('Code incorrect. Veuillez réessayer.');
    }
  };

  return (
    <div className="container mt-5">
      {!isAuthorized ? (
        <Card>
          <Card.Body>
            <Card.Title>Accès Restreint</Card.Title>
            <Card.Text>
              Cette page est protégée. Veuillez entrer le code pour accéder à la confirmation du retour échange.
            </Card.Text>
            <Form.Group className="mb-3" controlId="formCode">
              <Form.Label>Code d'accès</Form.Label>
              <Form.Control
                type="password"
                placeholder="Entrez le code"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
            </Form.Group>
            {error && <p className="text-danger">{error}</p>}
            <Button variant="primary" onClick={handleCodeSubmit}>
              Valider
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>Confirmation du Retour Echange</Card.Title>
            <Card.Text>
              Votre RetourEchange pour le ticket ID <strong>123456789</strong> a été créé avec succès.
              <br />
              Veuillez vérifier les détails ci-dessous :
              <ul>
                <li>Articles à retourner : 2 articles</li>
                <li>Articles à échanger : 0 article</li>
                <li>ID de commande : <strong>ORD123456</strong></li>
              </ul>
              Êtes-vous sûr de vouloir confirmer le processus de retour échange ?
            </Card.Text>
            <div className="d-flex justify-content-around">
              <Button variant="success" onClick={() => console.log('Confirmé')}>
                Oui
              </Button>
              <Button variant="danger" onClick={() => console.log('Annulé')}>
                Non
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default RetourEchangeConfirmationPage;
