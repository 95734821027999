import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";

import { ReceptionArticlesType } from "../../../Types/Entites/ReceptionArticles/ReceptionArticlesType";
import { stat } from "fs";
/**
 * create ReceptionArticles
 * @private
 */

export const createReceptionApi = createAsyncThunk(
  "reception/createReceptionApi",
  async (data:any, { rejectWithValue }) => {
    try {
      const response = await axios.post("reception/createReceptionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * update ReceptionArticles
 * @private
 */

export const updateReceptionApi = createAsyncThunk(
  "reception/updateReceptionApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("reception/updateReceptionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


export const findReceptionbysocieteApi = createAsyncThunk(
  "reception/findReceptionbysocieteApi",
  async (idsociete: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`reception/findReceptionbysocieteApi/${idsociete}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const confirmerReceptionbysocieteApi = createAsyncThunk(
  "reception/confirmerReceptionbysocieteApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`reception/confirmerReceptionbysocieteApi`,data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


export const findArticlereceptionApi = createAsyncThunk(
  "reception/findArticlereceptionApi",
  async (idreception: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(`reception/findArticlereceptionApi/${idreception}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


export const findReceptionSoucheApi = createAsyncThunk(
  "reception/findReceptionSoucheApi",
  async (societeid: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`reception/findReceptionSoucheApi/${societeid}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const coloturerReceptionbysocieteApi = createAsyncThunk(
  "reception/coloturerReceptionbysocieteApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`reception/coloturerReceptionbysocieteApi`,data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


  
interface ReceptionState {
  listReception: ReceptionArticlesType[];
  souche : string|null
}


  const initialState: ReceptionState={
    listReception : [],
    souche:null
  }
  
  export const ReceptionArticleSlice = createSlice({
    name: "reception",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(
        findReceptionbysocieteApi.fulfilled,
         (state, action) => {
      
         return{
          ...state,
          listReception:action.payload.reverse()
         }
        }
      );
      builder.addCase(
        findReceptionSoucheApi.fulfilled,
         (state, action) => {

          return{
            ...state,
            souche:action.payload
           }


            }
      );
      builder.addCase(
        createReceptionApi.fulfilled,
         (state, action) => {
      
          state.listReception.splice(0, 0, action.payload.data);
        }
      );
    
      builder.addCase(updateReceptionApi.fulfilled, (state, action) => {
        const updated_reception = action.payload.data;
        var index = _.findIndex(state, {
          _id: updated_reception._id,
        });
        // Replace item at index using native splice
        state.listReception.splice(index, 1, updated_reception);
      });
      builder.addCase(coloturerReceptionbysocieteApi.fulfilled, (state, action) => {
        const updated_reception = action.payload.data;
        var index = _.findIndex(state, {
          _id: updated_reception._id,
        });
        // Replace item at index using native splice
        state.listReception.splice(index, 1, updated_reception);
      });
    },
  })

  
  export const Listreceptions_articles = (state: RootState) =>state.receptions.listReception;
  export const SoucheReception = (state: RootState) =>state.receptions.souche;

export default ReceptionArticleSlice.reducer;
