import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  findRemisesByOrganisation,
  ListRemises,
  updateRemiseApi,
} from "../../../_App/Redux/Slices/remise/remiseSlice";
import {
  findNiveauxFidelite,
  findZonesByOrganisation,
} from "../../../_App/Redux/Slices/client/clientSlice";
import { Navbar } from "react-bootstrap";
import RemiseTable from "../../../Components/Remises/RemiseTable";
import RemiseForm from "../../../Components/Remises/RemiseForm";
import { RemiseType } from "../../../_App/Types/Entites/RemiseType";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";

const RemisePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const remises = useAppSelector(ListRemises);

  useEffect(() => {
    if (currentOrg && societe) {
      dispatch(findRemisesByOrganisation(currentOrg));
      dispatch(findNiveauxFidelite(societe));
      dispatch(findZonesByOrganisation(currentOrg));
    }
  }, [dispatch, showModal]);

  const updateFunction = (item: any) => {
    setItem(item);
    setShowModal(true);
  };
  useEffect(() => {
    if (!showModal) {
      setItem(null);
    }
  }, [showModal]);
  const verifcationMsg = (msg: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  const desactiveFunction = async (item: RemiseType) => {
    const remise = { ...item };
    remise.actif = false;
    const obj = {
      remise: remise,
    };
    const response = await dispatch(updateRemiseApi(obj));
    if (response) {
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };
  const activeFunction = async (item: any) => {
    const remise = { ...item };
    remise.actif = true;
    const obj = {
      remise: remise,
    };
    const response = await dispatch(updateRemiseApi(obj));
    if (response) {
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };
  return (
    <div>
      <Navbar />

      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className="btn btn-primary-app"
          onClick={() => setShowModal(true)}
        >
          {t("Ajouter un remise")}
        </button>
      </div>
      <RemiseForm
        t={t}
        verifcationMsg={verifcationMsg}
        ShowModal={showModal}
        setShowModal={setShowModal}
        programFidelite={item}
      />
      {remises && remises.length !== 0 ? (
        <RemiseTable
          t={t}
          ProgramsFidelites={remises}
          setItem={setItem}
          updateFunction={updateFunction}
          desactiveFunction={desactiveFunction}
          activeFunction={activeFunction}
        />
      ) : (
        <p>aucun remise</p>
      )}
    </div>
  );
};

export default RemisePage;
