import React from "react";
export default function SousCategoriesList({
  sousCategoriesListe,
  onEditClick,
  setLibelle,
  libelle,
  handleSearch
}: any) {
  return (
    <div>
      <section className="container">
      <div className="d-flex justify-content-end mb-3 mt-5">
        <div className="col-3"><label>Nom de sousCategories:</label></div>
        <div className="col-4">
          <input
            type="text"
              className="form-control "
            value={libelle}
            onChange={(e) => {
              setLibelle(e.target.value);
            }}
          />
        </div>
        <div className="col-2">
          <div className="wrapper">
            <div className="icon-input-col6 " style={{fontSize:'30px'}} onClick={handleSearch}>
              {" "}
              <i className="fas fa-search"></i>
            </div>
          </div>
        </div>

       
      </div>
        <div className="row">
          {sousCategoriesListe&&sousCategoriesListe.map((item: any) => (
            <div className="col-xxxl-2 col-xl-3 col-lg-6 col-12">
              <div className="card component-card_2">
                <img
                  src="assets/assets/img/no-image.jpg"
                  className="card-img-top"
                  alt="widget-card-2"
                />
                <div className="card-body">
                  <h5 className="card-title">{item.libelle.fr}</h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
