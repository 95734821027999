import React, { useEffect, useState } from "react";
import Numpad from "../NumPad/NumPad";
 
const CashPaymentMethod = ({
  total,
  onAmountChange,
  enteredAmountCash,
  remainingAmount,
}: any) => {
  const [givenAmountCash, setGivenAmountCash] = useState<string | null>(null);

  // const containsOnlyNumbers = (str: string) => !/[^0-9.]/.test(str) && /^\d+(\.\d+)?$/.test(str);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Allow the input if it's a valid number or just a dot.
    if (/^\d*\.?\d*$/.test(value)) {
      setGivenAmountCash(value); // Store the raw input value
      if (value !== "") {
        // Only parse and send the value if it's a valid number
        const parsedValue =value;
        onAmountChange(!isNaN(Number(parsedValue)) ?parsedValue : null);
      } else {
        onAmountChange(null); // Handle empty input
      }
    }
  };

  useEffect(() => {
    setGivenAmountCash(null);
  }, [total]);

  return (
    <div>
      <div className="navbar-item flex-row search-ul navbar-dropdown">
        <div className="nav-item align-self-center search-animated">
          <form
            className="form-inline search-full form-inline search"
            role="search"
          >
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control ml-lg-auto"
                placeholder="Espèces"
                // defaultValue={remainingAmount}
                value={
                  enteredAmountCash
                    ? enteredAmountCash
                    : givenAmountCash !== null
                    ? givenAmountCash
                    : total?.toFixed(3)
                }
                onChange={handleAmountChange}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CashPaymentMethod;
