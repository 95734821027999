import React, { useEffect, useState } from "react";
import {
  ajouterDureeToDate,
  formatDateAndTime,
  formatdate,
} from "../../_App/Helpers/helpers";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { selectOrder } from "../../_App/Redux/Slices/Orders/OrderSlice";
import { useNavigate } from "react-router-dom";
import {
  ListReservations,
  SelectReservation,
  currentPage,
  findReservationsByOrganisationApi,
  totalPages,
} from "../../_App/Redux/Slices/Reservation/ReservationSlice";
import { useTranslation } from "react-i18next";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { parametrageCaisse } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import {
  ReservationToPanier,
  setClientRemise,
  setNetPrice,
  setNetPriceArticle,
} from "../../_App/Redux/Slices/Caisse/PanierSlice";
import { findPaymentsByOrderApi } from "../../_App/Redux/Slices/payments/PaymentSlice";
import HeaderReservationList from "./HeaderReservationList";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";

export default function Reservationlist({ setshowModal, showModal }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organisation_id = useAppSelector(SelectedOrganisationId);
  const listReservation = useAppSelector(ListReservations);
  const parametrage = useAppSelector(parametrageCaisse);
  const [listfiltrer, setlistfiltrer] = useState(listReservation);
  const current_page = useAppSelector(currentPage);
  const totalpages = useAppSelector(totalPages);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const [loading, setloading] = useState(false);

  const todayDate = new Date();

  useEffect(() => {
    if (organisation_id) {
      setloading(true);
      dispatch(
        findReservationsByOrganisationApi({
          organisation_id: organisation_id,
          index: index,
        })
      );
    }
  }, [index]);

  useEffect(() => {
    if (listReservation) {
      setloading(false);
    }
  }, [listReservation]);
  const dispatch = useAppDispatch();
  const handleSelectOrder = async (item: any) => {
    const echeanceDate = new Date(item.date_echeance);

    if (todayDate <= echeanceDate) {
      if (parametrage && parametrage.barcodeOnly) {
        await dispatch(findPaymentsByOrderApi(item?.order._id));
        await dispatch(
          ReservationToPanier({
            avance: item.avance?.total,
            order_id: item.order._id,
            totalNet: item.achat_totalNet,
            items: item.order.items,
            client: item.infoclient.client,
          })
        );
        await dispatch(setClientRemise(item.infoclient.client.remise));
        await dispatch(setNetPriceArticle());

        setshowModal(false);
      }
    } else {
      ToastWarning("vous avez dépassé la date  d'échéance");
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      className="modal-2"
      dialogClassName="modal-60w"
      backdropClassName="modal-backdrop-2"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {t("list reservations")}{" "}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setshowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="row layout-spacing">
            <HeaderReservationList
              t={t}
              listreservation={listReservation}
              setlistfiltrer={setlistfiltrer}
            />
            {loading ? (
              <div className="text-center" style={{ marginTop: "300px" }}>
                <Spinner
                  animation="grow"
                  style={{ width: "100px", height: "100px" }}
                />
                <br />
                <strong>LOADING</strong>
              </div>
            ) : (
              <div>
                <div className="row mt-3">
                  <div className="col-2">{t("N°")}</div>
                  <div className="col-2">{t("Date de creation")}</div>
                  <div className="col-2">{t("Date d'echeance")}</div>
                  <div className="col-2">{t("Client")}</div>
                  <div className="col-2">{t("Num Tel")}</div>
                  <div className="col-2">{t("Total")}</div>
                </div>
                {listfiltrer && listfiltrer.length
                  ? listfiltrer.map((item: any, index: number) => (
                      <div className="row" key={item._id}>
                        <Accordion>
                          <Accordion.Item
                            eventKey={`accordion-${index}`}
                            className="row"
                          >
                            <Accordion.Header>
                              <div
                                className="lien-bleu-souligne col-2"
                                style={{ fontSize: "15px" }}
                                onClick={() => handleSelectOrder(item)}
                              >
                                {item.order?.order_number}
                              </div>

                              <div
                                className="col-2"
                                style={{ fontSize: "15px" }}
                              >
                                {formatdate(item.date)}
                              </div>
                              <div
                                className="col-2"
                                style={{
                                  fontSize: "15px ",
                                  color:
                                    todayDate > new Date(item.date_echeance)
                                      ? "red"
                                      : "",
                                }}
                              >
                                {formatdate(item.date_echeance)}
                              </div>
                              <div
                                className="col-2"
                                style={{ fontSize: "15px" }}
                              >
                                {item.infoclient.nom}
                              </div>
                              <div className="col-2">
                                {item.infoclient.mobile}
                              </div>
                              <div className="col-2">
                                {item.achat_totalNet?.toFixed(3)}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                          <table className="table style-2 table-hover">
                                            <thead>
                                              <tr>
                                                <th>{t("Code article")}</th>
                                                <th>{t("Libelle article")}</th>
                                                <th>{t("Quantite")}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item.items.map(
                                                (element: any) => (
                                                  <tr
                                                    key={
                                                      element.article
                                                        .code_article
                                                    }
                                                  >
                                                    <td
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {
                                                        element.article
                                                          .code_article
                                                      }
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {element.article.titre.fr}
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {element.quantity}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))
                  : null}
              </div>
            )}

            <div className="row mt-5 layout-spacing">
              <PaginationLayout
                index={index}
                nbrpage={current_page}
                totalpages={totalpages}
                setindex={setindex}
              />
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
