import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";

export default function BonSortie({
  ShowModal,
  setShowModal,
  t,
  reception,
}: any) {
  const componentRef = useRef<any>(); // Reference for the content to print

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-90w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Body >
          {reception !== null &&
           <div  className='mt-5' ref={componentRef}>

           <div className="row mb-3 ">
             <strong className="col-3">
               {t("Code Reception")} : {reception?.code_reception}
             </strong>
             <strong className="col-3">
               {t("Organisation")} : {reception?.organisation_id?.nom}
             </strong>
             <strong className="col-3">
               {t("Depot")} : {reception?.depot_id?.libelle}
             </strong>
             <strong className="col-3">
               {t("Utilisateur")} : {reception?.updatedBy?.nom + ' ' + reception?.updatedBy?.prenom}
             </strong>
           </div>
 
           {reception !== null &&
             Object.keys(reception.bonSorties).map((key) => (
               <React.Fragment key={key}>
                 <div className="row">
                   <div className="col-4">
                     <strong>
                       {t("Article parent")}:
                       {
                         reception.bonSorties[key][0]?.ligne_reception_id
                           ?.code_article
                       }
                     </strong>
                   </div>
 
                   <div className="col-4">
                     <strong>
                       {t("Famille")}:
                       {
                         reception.bonSorties[key][0]?.ligne_reception_id
                           ?.sous_categorie?.libelle?.fr
                       }
                     </strong>
                   </div>
                 </div>
                 <table className="table table-bordered">
                   <thead className="table-primary">
                     <tr>
                       <th>{t("Code Article")}</th>
                       <th>{t("Article")}</th>
                       <th>{t("Quantité")}</th>
                     </tr>
                   </thead>
                   <tbody>
                     {reception.bonSorties[key].map((i: any, index: number) => (
                       <tr key={index}>
                         <td>{i.article?.code_article}</td>
                         <td>{i.article?.titre?.fr}</td>
                         <td>{i.Quantite}</td>
                       </tr>
                     ))}
                   </tbody>
                 </table>
               </React.Fragment>
             ))}
             </div>}
       
        </Modal.Body>

        <Modal.Footer>
          <ReactToPrint
            trigger={() => <button className="btn btn-primary">Print</button>}
            content={() => componentRef.current} // Print content reference
          />
        </Modal.Footer>
      </Modal>

   
    </div>
  );
}
