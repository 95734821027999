import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { ArticleType } from "../../../_App/Types/Entites/ArticleType";
import ArticleItem from "./ArticleItem";
import {
  SelectedOrganisations,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import Codebarreartice from "./codebarre";
import {
  activeArticleApi,
  desactiveArticleApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import Swal from "sweetalert2";
import ArticleFournisseur from "./ArticleFournisseur";
import {
  ListDepots,
  findDepotsBySocieteApi,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import StockTable from "../../Stock/StockTable";
import { Spinner } from "react-bootstrap";
import TicketArticle from "../../TicketArticle/TicketArticle";

export default function ArticleList({
 
  t,
  list_Article,
  setShowModalArticle,
  setarticleitem,
  societe,
  showModalcodeabarre,
  setshowModalcodeabarre
}: any) {
  const dispatch = useAppDispatch();
  const [showModalStock, setshowModalStock] = useState(false);
  const [articleselected, setarticleselected] = useState();
  const listOrgaisations = useAppSelector(SelectedOrganisations);
  const [showDetailArticle, setshowDetailArticle] = useState(false);
  const [article, setarticle] = useState<ArticleType>();
  const [list_codeabarre, setlist_codeabarre] = useState<any[]>([]);
  const listdepots = useAppSelector(ListDepots);
  const [listArticle, setlistArticle] = useState<ArticleType[]>();

  useEffect(() => {
    if (list_Article && list_Article.length !== 0) {
     
    
      setlistArticle(list_Article);
    }
  }, [list_Article]);
  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
    }
  }, [societe]);
  useEffect(() => {
    if (list_Article && !showModalcodeabarre) {
      const list = list_Article.map((element: any) => {
        
        if(element.type !=="parent"){
          return {
            marque: element.souscategorie?.libelle?.abr,
            ref: element.articleparent?.code_article,
            taille: element.caracteristiques[1]?.value,
            couleur: element.caracteristiques[0]?.value?.libelle,
            prix: element.prix_vente_ttc,
            coderecption: element?.code_reception,
            code_a_barre: element.code_a_barre[0],
            solde:element.solde,
            titre:element.titre.fr,
            qte:1

          };
        }
         
        
      });
        
      setlist_codeabarre(list.filter((element:any )=> element !== undefined).map((i:any)=>{return {...i,qte:1}}));
    }
  }, [list_Article, showModalcodeabarre]);
  const updateFunction = async (item: any) => {
    setarticleitem(item);
    setShowModalArticle(true);
  };
  const verifcationMsg = (action: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: `${t("vous voulez")} ${t(action)}${" article"}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  const desactiverFunction = async (id: string) => {
    if (await verifcationMsg("desactiver ")) {
      const response = await dispatch(desactiveArticleApi(id));
       
      if (response.payload.success) {
        const findindex= listArticle?.findIndex(item=>item._id ===id )
        if(listArticle && findindex && findindex !== -1 ){
          const copie = listArticle.map(article => ({ ...article }));
          copie[findindex] = {
            ...copie[findindex],
            actif: false
          };
         setlistArticle(copie);
        }
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };
  const activerFunction = async (id: string) => {
    if (await verifcationMsg("activer")) {
      const response = await dispatch(activeArticleApi(id));

      if (response.payload.success) {
        const findindex= listArticle?.findIndex(item=>item._id ===id )
        if(listArticle && findindex && findindex !== -1 ){
          const copie = listArticle.map(article => ({ ...article }));
          copie[findindex] = {
            ...copie[findindex],
            actif: true
          };
         setlistArticle(copie);
        }
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };

  const showonecodeabarre = (item: any) => {
    setlist_codeabarre([
      {
        marque: item.souscategorie?.libelle?.fr,
        ref: item.articleparent?.code_article,
        taille: item.caracteristiques[1]?.value,
        couleur: item.caracteristiques[0]?.value,
        prix: item.prix_vente_ttc,
        coderecption: item?.code_reception,
        code_a_barre: item?.code_a_barre[0],
        solde:item?.solde,
        qte:1
      },
    ]);
    setshowModalcodeabarre(true);
  };

  return (
    <div>
      <div className="row mt-3">
       
        {listArticle && listArticle.length !== 0 && (
          <>
            <div className="row mt-5">
              {listArticle.map((item: any) => (
                <div key={item._id} className="col-md-2 ">
                  <ArticleItem
                    t={t}
                    setarticle={setarticle}
                    setshowDetailArticle={setshowDetailArticle}
                    article={item}
                    updateFunction={updateFunction}
                    desactiverFunction={desactiverFunction}
                    activerFunction={activerFunction}
                    showonecodeabarre={showonecodeabarre}
                    listdepots={listdepots}
                    setshowModalStock={setshowModalStock}
                    setarticleselected={setarticleselected}
                  />
                </div>
              ))}
            </div>
          
          </>
        ) 
          }
      </div>

      {/*<Codebarreartice
        t={t}
        ListArticle={list_codeabarre}
        ShowModal={showModalcodeabarre}
        setShowModal={setshowModalcodeabarre}
        societeId={societe}
      />*/}
      <TicketArticle
        t={t}
        ShowModal={showModalcodeabarre}
        setShowModal={setshowModalcodeabarre}
        tickets={list_codeabarre}
        stateQte={true}

      />
      <ArticleFournisseur
        t={t}
        showModal={showDetailArticle}
        setshowModal={setshowDetailArticle}
        idarticle={article?._id}
        refproduct={article?.produit_ref}
        dispatch={dispatch}
        
      />
      <StockTable
        t={t}
        showModal={showModalStock}
        setshowModal={setshowModalStock}
        article_id={articleselected}
        listdepots={listdepots}
      />
    </div>
  );
}
/* */
