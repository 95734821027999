import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { InventaireType } from "../../_App/Types/Entites/Inventaire/InventaireType";
import { InventaireSchema } from "../../_App/Schemas/InventaireSchema";
import { Resolver, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisations,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { DepotsType } from "../../_App/Types/Entites/DepotsType";
import {
  ListDepotByOrganisation,
  ListDepots,
  findDepotsBySocieteApi,
} from "../../_App/Redux/Slices/depot/depotSlice";
import {
  CalculStockInventaireApi,
  CalculStockInventaireArticleApi,
  Liststockinventaire,
  createInventaireApi,
  importInventaireExcelApi,
  updateInventaireDetailsApi,
} from "../../_App/Redux/Slices/inventaire/inventaireSlice";
import InventaireDetail from "./InventaireDetail";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { formadate } from "../../_App/Helpers/helpers";
import Swal from "sweetalert2";

export default function InventaireForm({
  Inventaire,
  list_details_inventaire,
  ShowModal,
  setShowModal,
}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formOptions = {
    resolver: yupResolver(
      InventaireSchema
    ) as unknown as Resolver<InventaireType>,
  };
  const {
    register,
    handleSubmit,
    reset,
    formState,
    getValues,
    setValue,
    watch,
  } = useForm<InventaireType>(formOptions);
  const { errors } = formState;
  const [selectedorganisation, setselectedorganisation] = useState("");
  const [search_article, setsearch_article] = useState(true);
  const [importExcel, setimportExcel] = useState(false);
  const [file, setfile] = useState<File | undefined>();
  const [listdepotsfilter, setlistdepots] = useState<DepotsType[]>([]);
  const [list_details, setlist_details] = useState<any[]>([]);
  const [selecteddepots, setselecteddepots] = useState("");

  const listdOrganisations = useAppSelector(SelectedOrganisations);
  const listDepots = useAppSelector(ListDepots);
  const societe = useAppSelector(societeId);
  const listDepotByOrganisation = useAppSelector(ListDepotByOrganisation);
  const user = useAppSelector(selectUserId);
  const list_inventaire_details = useAppSelector(Liststockinventaire);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [date_inventaire, setdate_inventaire] = useState("");

  useEffect(() => {
    if (Inventaire && list_details_inventaire) {
      reset({
        depots_id: Inventaire.depots_id._id,
        date_fin: Inventaire?.date_fin,
        date_debut: Inventaire?.date_debut,
      });

      setlist_details(list_details_inventaire);
    }
  }, [Inventaire, list_details_inventaire]);

  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
    }
  }, [dispatch, societe]);
  useEffect(() => {
    setlist_details([...list_details, ...list_inventaire_details]);
  }, [list_inventaire_details]);
  useEffect(() => {
    if (!selectedorganisation) {
      setlistdepots(listDepots);
    } else {
      const list = listDepotByOrganisation[selectedorganisation];
      if (list) {
        const sortedList = [...list].sort((a, b) => {
          if (a.defaut === b.defaut) {
            return 0;
          } else if (a.defaut === true) {
            return -1;
          } else {
            return 1;
          }
        });
        setValue("depots_id", sortedList[0]?._id || "");

        setlistdepots(sortedList);
        setselecteddepots(sortedList[0]?._id || "");
      } else {
        setlistdepots([]);
      }
    }
  }, [selectedorganisation]);

  const getlist = async () => {
    const depot =
      selecteddepots !== "" ? selecteddepots : listdepotsfilter[0]._id;

    if (true && searchTerm !== "") {
      const data = { article: encodeURIComponent(searchTerm), depots: depot };
      const response = await dispatch(CalculStockInventaireArticleApi(data));
      if (!response.payload.success) {
        ToastWarning(response.payload.message);
      }
    } else {
      const dateD = getValues("date_debut");
      const dateF = getValues("date_fin");

      const data = {
        date_inventaire: date_inventaire,
        dateD: dateD,
        dateF: dateF,
        depots: depot,
      };
      const response = await dispatch(CalculStockInventaireApi(data));
    }
  };
  const onSubmit = async (data: any) => {
    const resverification = await verifcationMsg();
    if (resverification) {
      if (importExcel) {
        ImportExcelFunction();
      } else {
        saveInventaire(data);
      }
    }
  };

  const verifcationMsg = () => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: `${t("vous voulez")} ${
        Inventaire ? t(`modifier`) : t(`enregistrer`)
      }${t("ce inventaire")} `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const saveInventaire = async (data: any) => {
    const test_list = list_details.filter((item) => item.quantite);

    if (Inventaire) {
      const response = await dispatch(updateInventaireDetailsApi(test_list));
      if (response?.payload.success) {
        ToastSuccess(response?.payload.message);
        window.location.reload();
        setShowModal(false);
      } else {
        ToastWarning(response?.payload.message);
      }
    } else {
      data.depots_id =
        selecteddepots !== "" ? selecteddepots : listdepotsfilter[0]?._id;
      const newlist_details = test_list.map((element) => ({
        ...element,
        article_id: element.article_id?._id,
      }));
      const data_inventaire = {
        code_inventaire: "",
        depots_id: data.depots_id,
        societe_id: societe || null,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
        organisation_id:
          selectedorganisation !== "" ? selectedorganisation : null,
        type: "inventaire_stock",
        cloture: false,
        annuler: false,
        confirmer: false,
        user: user,
        details: newlist_details,
      };
      /// create
      const response = await dispatch(createInventaireApi(data_inventaire));
      if (response?.payload.success) {
        ToastSuccess(response?.payload.message);
        setlist_details([]);

        window.location.reload();
      } else {
        ToastWarning(response?.payload.message);
      }
    }
  };

  const ImportExcelFunction = async () => {
    if (file) {
      const data = {
        file: file,
        organisation_id: selectedorganisation,
        depots_id: selecteddepots,
        societe_id: societe,
        user: user,
      };
      const response = await dispatch(importInventaireExcelApi(data));
      if (response?.payload.success) {
        if (response.payload.datareturn.length !== 0) {
          await alertArticleNotFound(response.payload.datareturn);
        } else {
          ToastSuccess(response?.payload.message);

          window.location.reload();
        }
      } else {
        ToastWarning(response?.payload.message);
      }
    } else {
      ToastWarning("choisir un fichier excel ");
    }
  };

  const alertArticleNotFound = async (list: any) => {
    let tableContent = `
      <table class='table'>
        <thead>
          <tr>
            <th>INDEX</th>
            <th>Code Article</th>
            <th>Code à barre</th>
            <th>Stock</th>
          </tr>
        </thead>
        <tbody>
          ${list
            .map(
              (item: any) => `
            <tr>
              <td>${item.index}</td>
              <td>${item.code_article}</td>
              <td>${item.code_a_barre}</td>
              <td>${item.stock}</td>
            </tr>
          `
            )
            .join("")}
        </tbody>
      </table>
    `;

    Swal.fire({
      icon: "error",
      title: "Oops...Articles introuvables !",
      html: tableContent,
      willClose: () => {
        // Reload the page when the SweetAlert is closed
        window.location.reload();
      },
    });
  };

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-70w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">
            {" "}
            <span>{t("Inventaire")}</span>
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {Inventaire ? (
              <div>
                <div className="row mb-2">
                  <div className="col-4">
                    <strong>{t("Code inventaire")}</strong>{" "}
                    <span>{Inventaire?.code_inventaire}</span>
                  </div>
                  <div className="col-4">
                    <strong>{t("Organisation")}</strong>{" "}
                    <span>
                      {listdOrganisations?.find(
                        (element: any) =>
                          element._id === Inventaire?.organisation_id
                      )?.nom || Inventaire?.organisation_id}
                    </span>
                  </div>
                  <div className="col-3">
                    <strong>{t("Depots")} </strong>{" "}
                    <span>{Inventaire?.depots_id.libelle}</span>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-4">
                    <strong>{t("user")} </strong>{" "}
                    <span>
                      {Inventaire?.user.nom}
                      {""}
                      {Inventaire?.user?.prenom}
                    </span>
                  </div>
                  <div className="col-4">
                    <strong>{t("date debut")}</strong>{" "}
                    <span>{formadate(Inventaire?.date_debut)}</span>
                  </div>
                  <div className="col-4">
                    <strong>{t("date fin")} </strong>{" "}
                    <span>
                      {Inventaire?.date_fin
                        ? formadate(Inventaire?.date_fin)
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container mt-4 ">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Import Excel" //chercher par code a barre ou code article
                  checked={importExcel}
                  onClick={() => setimportExcel(!importExcel)}
                />
                <div className="row mb-3 ">
                  <div className="col-2">
                    <div className="form-group ">
                      <div>
                        <label htmlFor="organisation_id ">
                          {t("Organistation")}
                        </label>
                      </div>
                      <select
                        className={`form-control mb-2 ${
                          errors.organisation_id ? "is-invalid" : ""
                        }`}
                        {...register("organisation_id")}
                        onChange={(e) =>
                          setselectedorganisation(e.target.value)
                        }
                      >
                        <option value="">Select organisation </option>
                        {listdOrganisations?.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.nom}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group ">
                      <label htmlFor="depot_id ">
                        <span style={{ color: "red" }}>*</span>
                        {t("Depots")}
                      </label>
                      <select
                        className={`form-control mb-2 ${
                          errors.depots_id ? "is-invalid" : ""
                        }`}
                        {...register("depots_id")}
                        onChange={(e) => setselecteddepots(e.target.value)}
                      >
                        <option value="">Select Depots </option>

                        {listdepotsfilter.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.libelle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {true ? (
                    !importExcel ? (
                      <div className="col-md-3   ">
                        <div className="form-group">
                          <label htmlFor="article">{t("Article ")}</label>
                          <input
                            type="text"
                            className="form-control "
                            value={searchTerm}
                            autoFocus
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className=" col-3   mt-4">
                        <input
                          type="file"
                          className="form-control  "
                          onChange={(e) => setfile(e.target.files?.[0])}
                        />
                      </div>
                    )
                  ) : (
                    <>
                      <div className="col-md-2   ">
                        <div className="form-group">
                          <label htmlFor="date_debut">{t("date debut")}</label>
                          <input
                            className={`form-control ${
                              errors.date_debut ? "is-invalid" : ""
                            }`}
                            type="date"
                            id="date_debut"
                            {...register("date_debut")}
                          />
                        </div>
                      </div>
                      <div className="col-md-2   ">
                        <div className="form-group">
                          <label htmlFor="date_fin">{t("date date_fin")}</label>
                          <input
                            className={`form-control ${
                              errors.date_fin ? "is-invalid" : ""
                            }`}
                            type="date"
                            id="date_fin"
                            {...register("date_fin")}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <label htmlFor="depot_id ">
                            <span style={{ color: "red" }}></span>
                            {t("dernier inventaire")}
                          </label>
                          <input
                            className={`form-control ${
                              errors.date_fin ? "is-invalid" : ""
                            }`}
                            type="date"
                            id="date_fin"
                            onChange={(e) => setdate_inventaire(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {!importExcel ? (
                    <div className="col-2   mt-4">
                      <div className="form-group">
                        <a className="btn btn-primary-app" onClick={getlist}>
                          {t("cherhcer")}
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            {!importExcel && (
              <div className="row">
                <InventaireDetail
                  t={t}
                  list_details={list_details}
                  setlist_details={setlist_details}
                />
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t("Annuler")}
            </button>
            <button type="submit" className="btn btn-primary-app">
              {t("Enregistrer")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
