import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  findFourinsseurBySocieteApi,
  ListFournisseurs,
} from "../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import {
  findSousCategorieByLibelleApi,
  findSousCategorieBySocieteApi,
  ListSousCategories,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import SousCategorieSelecter from "../../Shared/SousCategorie/SousCategorieSelecter";
import Select from "react-select";
import { findArticleByBarCode } from "../../_App/Redux/Slices/article/articleSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function FiltreRapport({
  t,
  filtre,
  setFiltre,
  handleSearch,
}: any) {
  const societe = useAppSelector(societeId);
  const dispatch = useAppDispatch();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [article, setarticle] = useState("");

  const [listsouscategorie, setlistsouscategorie] = useState<any[]>([]);
  const listfournisseur = useAppSelector(ListFournisseurs);
  const FournisseurOptions = listfournisseur?.map((option) => ({
    value: option._id,
    label: option.libelle,
  }));

  useEffect(() => {
    if (societe) {
      dispatch(findFourinsseurBySocieteApi(societe));
      dispatch(findSousCategorieBySocieteApi({ societe: societe, index: 1 }));
    }
  }, [dispatch, societe]);
  const handleSelectChangeFournisseur = (selectedOption: any) => {
    const fournisseur = selectedOption.map((item: any) => item.value);
    setFiltre({ ...filtre, listfournisseur: fournisseur });
  };
  useEffect(() => {
    setFiltre({
      ...filtre,
      selectedSousCategorie: listsouscategorie.map((item) => item._id),
    });
  }, [listsouscategorie]);
  const handleChange = async (e: any) => {
    setarticle(e.target.value);
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        const resp = await dispatch(findArticleByBarCode(value));
        if (resp.payload !== null) {
          setarticle(resp.payload?.titre?.fr);
          setFiltre({ ...filtre, article: resp.payload._id });
        } else {
          setarticle("");
          setFiltre({ ...filtre, article: null });
          ToastWarning("l'article n'existe pas");
        }
      }else{
        setarticle("");
          setFiltre({ ...filtre, article: null });
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className=" row  bg-white" style={{ padding: "20px" }}>
      <div className="col-2">
        <label>{t("Date Debut")}</label>
        <input
          type="date"
          value={filtre.dateDebut}
          onChange={(e) => setFiltre({ ...filtre, datedebut: e.target.value })}
          className="form-control "
        />
      </div>
      <div className="col-2">
        <label>{t("Date Fin")}</label>
        <input
          type="date"
          value={filtre.dateFin}
          onChange={(e) => setFiltre({ ...filtre, datefin: e.target.value })}
          className="form-control "
        />
      </div>
      <div className="col-2">
        <SousCategorieSelecter
          listsouscategorie={listsouscategorie}
          setlistsouscategorie={setlistsouscategorie}
        />
      </div>
      <div className="col-2">
        <div className="form-group">
          <label htmlFor="carte">
            {" "}
            <span style={{ color: "red" }}></span>
            {t("Fournisseur")}
          </label>
          <Select
            isMulti
            options={FournisseurOptions}
            isSearchable={true}
            onChange={handleSelectChangeFournisseur}
            value={filtre.listfournisseur?.map((item: any) =>
              FournisseurOptions?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
      </div>
      <div className="col-2">
        <label>article</label>
        <input
          type="text"
          value={article}
          className="form-control "
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="col-2 mt-3 fs-25 " onClick={handleSearch}>
        {" "}
        <i className="fas fa-search"></i>
      </div>
    </div>
  );
}
