import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ArticleStockGlobale,
  findFreresStocksByArticleIdApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { Modal, Spinner } from "react-bootstrap";
import {
  findDepotsBySocieteApi,
  ListDepotByOrganisation,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import { societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../../_App/Redux/Slices/organisations/organisationSlice";
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";

const StockGlobale = ({
  article,
  setSelectedArticles,
  ShowModal,
  setShowModal,
}: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const listDepots = useAppSelector(ListDepotByOrganisation);
  const societe = useAppSelector(societeId);
  const listOrganisations = useAppSelector(ListOrganisations);

  const stockGlobale = useAppSelector(ArticleStockGlobale);
  const [spinner, setspinner] = useState(false);

  useEffect(() => {
    if (article?._id) dispatch(findFreresStocksByArticleIdApi(article._id));
  }, [dispatch, article]);

  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
      dispatch(allOrganisationsApi(societe));
    }
  }, [dispatch]);

  useEffect(() => {
    if (stockGlobale) {
      let nouveauTableau = stockGlobale
        ? stockGlobale.map((element: any) =>
            element ? element["article_id"] : null
          )
        : [];
      function removeDuplicates(objects: any[]) {
        let seen = new Set();
        return objects.filter((obj) => {
          if (seen.has(obj?._id)) {
            return false;
          }
          seen.add(obj?._id);
          return true;
        });
      }
    }
  }, [stockGlobale]);

  useEffect(() => {
    if (stockGlobale) {
      setspinner(false);
      console.log("stockGlobale");
    }
  }, [stockGlobale]);

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => {
          setSelectedArticles(null);
          setShowModal(false);
        }}
        className="modal-1"
        dialogClassName="modal-90w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header>
          <Modal.Title>
            <span
              className="modal-title"
              id="addCategorieLabel"
              onClick={() => console.log(article)}
            >
              {" "}
              Stock globale de {article?.titre.fr}
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="list">
              <div className="row layout-spacing">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <th>{t("Organisation")}</th>
                      <th></th>
                      <th
                        style={{
                          borderRight: "1px solid #eff1f1",
                        }}
                      ></th>

                      {listOrganisations &&
                        listOrganisations?.map((item, index) => (
                          <>
                            <th
                              className="text-center "
                              style={{ fontSize: "15px" }}
                              colSpan={listDepots[item._id]?.length}
                            >
                              {
                                listOrganisations?.find(
                                  (org) => org._id === item._id
                                )?.nom
                              }
                            </th>

                            <th
                              style={{
                                borderRight: "1px solid #eff1f1",
                              }}
                            ></th>
                          </>
                        ))}
                      <th style={{ fontSize: "15px" }}>{t("societe")}</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <strong>{t("Article")}</strong>
                        </td>

                        <td
                          style={{
                            borderRight: "1px solid #eff1f1",
                          }}
                        >
                          <strong>{t("Prix")}</strong>
                        </td>

                        {listOrganisations?.map((item, index) => (
                          <>
                            {listDepots[item._id] ? (
                              listDepots[item._id]?.map((dep: any) => (
                                <td key={dep._id}>{dep.libelle}</td>
                              ))
                            ) : (
                              <td></td>
                            )}
                            <td
                              style={{
                                borderRight: "1px solid #eff1f1",
                              }}
                            ></td>
                          </>
                        ))}
                      </tr>
                      {spinner ? (
                        <tr>
                          {" "}
                          <Spinner animation="border" />
                        </tr>
                      ) : (
                        stockGlobale !== null &&
                        stockGlobale.map((itemdata: any, dataIndex: any) => (
                          <tr key={dataIndex}>
                            <td>
                              {itemdata?.article?.image &&
                              itemdata?.article?.image !== null &&
                              itemdata?.article?.image?.length !== 0 ? (
                                <>
                                  <Zoom>
                                    <img
                                      alt="image"
                                      src={
                                        process.env.REACT_APP_API_PUBLIC_URL +
                                        "article/" +
                                        itemdata?.article.image
                                      }
                                      width="30"
                                      height="30"
                                    />
                                  </Zoom>
                                </>
                              ) : (
                                <>
                                  <img
                                    src="assets/assets/img/no-image.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    style={{ width: "50px", height: "30px" }}
                                  />
                                </>
                              )}
                            </td>
                            <td>
                              {article?._id === itemdata?.article?._id ? (
                                <p style={{ width: "150px" }}>
                                  <mark>{itemdata?.article?.titre?.fr}</mark>
                                </p>
                              ) : (
                                <p style={{ width: "150px" }}>
                                  {itemdata?.article?.titre?.fr}
                                </p>
                              )}
                            </td>
                            <td
                              style={{
                                borderRight: "1px solid #eff1f1",
                              }}
                            >
                              {itemdata?.article?.prix_vente_ttc}
                            </td>
                            {listOrganisations?.map((itemdepo, index) => (
                              <React.Fragment key={index}>
                                {listDepots[itemdepo._id] ? (
                                  listDepots[itemdepo._id]?.map(
                                    (dep: any, index: any) => (
                                      <React.Fragment key={index}>
                                      <td>
                                        
                                          <>
                                            {(() => {
                                              const stockData = itemdata?.stock[itemdepo._id]?.find(
                                                (e: any) => e.depots_id._id === dep._id
                                              );
                                              const stockValue = stockData ? stockData.stock : "0";
                                    
                                              return (
                                                <>
                                                {article?._id === itemdata?.article?._id ? (  <mark>{stockValue}</mark>):
                                                  <p>{stockValue}</p>}
                                                </>
                                              );
                                            })()}
                                          </>
                                       
                                      </td>
                                    </React.Fragment>
                                    
                                    )
                                  )
                                ) : (
                                  <td></td>
                                )}
                                <td
                                  style={{
                                    borderRight: "1px solid #eff1f1",
                                  }}
                                ></td>
                              </React.Fragment>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StockGlobale;
