import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { AddArticleDefectueuxOnlineApi } from "../../_App/Redux/Slices/articledefectueux/articledefectueuxSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { updateOrder } from "../../_App/Redux/Slices/Orders/OrderSlice";

export default function ArticleDefectueuxPopUP({
  ShowModal,
  setShowModal,
  t,
  article,
  order,
}: any) {
  const dispatch = useAppDispatch();
  const [distribue, setDistribue] = useState<Boolean>(false);
  const listOrganisations = useAppSelector(ListOrganisations);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe_id = useAppSelector(societeId);
  const user_id = useAppSelector(selectUserId);
  const [data, setData] = useState({
    article_id: article?._id,
    note: "",
    image: null as File | null,
    organisation: null as String | null,
    organisationActuel: currentOrganisation,
    societe_id: societe_id,
    Quantite: 1,
    user_id: user_id,
    retour: order && order.status === "En livraison" ? true : false,
    order: order ? order._id : undefined,
  });
  useEffect(() => {
    if (ShowModal === false) {
      setData({
        article_id: article?._id,
        note: "",
        image: null as File | null,
        organisation: null as String | null,
        organisationActuel: currentOrganisation,
        societe_id: societe_id,
        Quantite: 1,
        user_id: user_id,
        retour: order && order.status === "En livraison" ? true : false,
        order: order ? order._id : undefined,
      });
      setDistribue(false);
    }
  }, [ShowModal]);
  useEffect(() => {
    if (article) {
      setData({ ...data, article_id: article._id });
    }
  }, [article]);
  useEffect(() => {
    if (societe_id) dispatch(allOrganisationsApi(societe_id));
  }, [societe_id]);
  useEffect(() => {
    if (!distribue) {
      setData({ ...data, organisation: null });
    }
  }, [distribue]);
  const save = async () => {
    if (distribue && data.organisation === null) {
      ToastWarning("choisi une organisation");
    } else {
     const resp = await dispatch(AddArticleDefectueuxOnlineApi(data));
    //  console.log("resprespresp", resp);

      if (resp?.payload.success) {
        if(order && order._id){
          const copie = {...order}
           const indexItem =copie?.items.findIndex((e:any)=>e.article._id=== article._id)
          if(indexItem !== -1){
            const newQuantite =   copie?.items[indexItem].quantity-data.Quantite
            const copieList = [...copie?.items]

            const copieitem ={...copieList[indexItem]}
            copieitem.qteretour=newQuantite
            copieList[indexItem] =copieitem
            copie.items=copieList
            await dispatch(updateOrder({dataOrder:copie}))
          

          }
        }
        ToastSuccess("Article mis comme défectueux");
        setShowModal(false);
      } else {
        ToastWarning(resp.payload.message);
      }
    }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-30w"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {t("Article Defectueux")} {article?.titre?.fr}
          {}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="user-email  text-center ">
            <label>{t("Note")}</label>

            <textarea
              className="form-control"
              value={data.note}
              onChange={(e) => setData({ ...data, note: e.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="user-email  text-center ">
            <label>{t("Quantités défectueuses")}</label>

            <input
              type="number"
              className="form-control"
              value={data.Quantite}
              onChange={(e) =>
                setData({ ...data, Quantite: parseInt(e.target.value) })
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="user-email  text-center ">
            <label>{t("Image")}</label>
            <input
              type="file"
              className="form-control"
              accept="image/*" // Optional: restrict to image files only
              onChange={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                if (file) {
                  setData({ ...data, image: file });
                } // Pass the file object instead of value
              }}
            />
          </div>
        </div>
        <div className="row user-email  text-center mt-3">
          <Form.Check
            type="switch"
            id="custom-switch"
            label={t("Distribué vers magasin")}
            onChange={() => {
              setDistribue(!distribue); // Toggle the distribue state on change
            }}
            value={distribue.toString()}
          />
          {distribue && (
            <select
              className="form-control"
              onChange={(e) => {
                setData({ ...data, organisation: e.target.value });
              }}
            >
              <option>{t("choisi un magasin")}</option>
              {listOrganisations.map(
                (item, index) =>
                  item._id !== currentOrganisation && (
                    <option key={index} value={item._id}>
                      {item.nom}
                    </option>
                  )
              )}
            </select>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={save}>
          {t("Enregister")}
        </button>
        <button className="btn btn-primary" onClick={() => setShowModal(false)}>
          {t("Annuler")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
