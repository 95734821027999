import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios, { AxiosResponse } from "axios";
// import _map from "lodash/map";
import _ from "lodash";
import { ArticleType } from "../../../Types/Entites/ArticleType";
import StockType from "../../../Types/Entites/StockType";
import TransfertArticleType from "../../../Types/Entites/TransfertArticleType";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";

/**
 * create Article  function
 * @private
 */
export const createArticleApi = createAsyncThunk(
  "article/createArticleApi",
  async (data: ArticleType, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const {
        titre,
        description,
        marque,
        carte,
        categorie,
        caracteristiques,
        prix_vente_ttc,
        prix_vente_ht,
        organisation_id,
        souscategorie,
        image,
        code_a_barre,
        produit_ref,
        type,
        societe_id,
        minimalStock,
        code_article,
        remise
      } = data;
      caracteristiques?.forEach((element: any) => {
        delete element.color;
        delete element.libelle;
      });

      var body = new FormData();

      body.append("titre", JSON.stringify(titre));
      body.append("description", JSON.stringify(description));
      body.append("caracteristiques", JSON.stringify(caracteristiques));
      if(minimalStock ) body.append("minimalStock",minimalStock?.toString())
        if(remise ) body.append("remise",remise?.toString())

      const organisation =
        organisation_id !== null ? organisation_id.toString() : "null";

      const produitref = produit_ref !== null ? produit_ref.toString() : "null";
      body.append("organisation_id", organisation.toString());
      body.append("type", type.toString());

      body.append("societe_id", societe_id.toString());
      body.append("code_a_barre", JSON.stringify(code_a_barre));
      body.append("produit_ref", produitref.toString());
      body.append("marque", marque.toString());
      body.append("carte", carte.toString());
      body.append("categorie", categorie.toString());
      body.append("souscategorie", souscategorie.toString());
      body.append("prix_vente_ht", prix_vente_ht.toString());
      body.append("code_article", code_article.toString());

      body.append("prix_vente_ttc", prix_vente_ttc.toString());

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }
      const response = await axios.post(
        "article/createArticleApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create Article  function
 * @private
 */
export const createArticleReceptionApi = createAsyncThunk(
  "article/createArticleReceptionApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "article/createArticleReceptionApi",
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const transfertArticle = createAsyncThunk(
  "article/transfertArticle",
  async (data: TransfertArticleType, { rejectWithValue }) => {
    try {
      const response = await axios.post("article/transfertArticle", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * update a article.
 * @public
 */

export const updateArticleApi = createAsyncThunk(
  "article/updateArticleApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const {
        _id,
        titre,
        description,
        marque,
        carte,
        categorie,
        caracteristiques,
        prix_vente_ttc,
        prix_vente_ht,
        organisation_id,
        souscategorie,
        image,
        code_a_barre,
        produit_ref,
        societe_id,
        changeall,
        tva,
        minimalStock,
        remise

      } = data;
      const produitref = produit_ref !== null ? produit_ref.toString() : "null";

      var body = new FormData();
      body.append("_id", _id.toString());
      body.append("changeall", changeall.toString());

      body.append("titre", JSON.stringify(titre));
      body.append("tva",tva);
      body.append("minimalStock",minimalStock);
      body.append("remise",remise);

      body.append("description", JSON.stringify(description));
      body.append("caracteristiques", JSON.stringify(caracteristiques));
      const organisation =
        organisation_id !== null ? organisation_id.toString() : "null";
      const categorieid = categorie !== null ? categorie.toString() : "null";
      const cartid = carte !== null ? carte.toString() : "null";
      const marqueid = marque !== null ? marque.toString() : "null";
      const souscategorieid =
      (souscategorie&& souscategorie !== null) ? souscategorie.toString() : "null";

      body.append("organisation_id", organisation.toString());
      body.append("societe_id", societe_id.toString());
      body.append("code_a_barre", JSON.stringify(code_a_barre));
      body.append("produit_ref", produitref);
      body.append("marque", marqueid);
      body.append("carte", cartid);
      body.append("categorie", categorieid);
      body.append("souscategorie", souscategorieid);
      body.append("prix_vente_ttc", prix_vente_ttc.toString());
      body.append("prix_vente_ht", prix_vente_ht.toString());

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }

      const response = await axios.put(
        "article/updateArticleApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * create Article  function
 * @private
 */
export const createListArticleApi = createAsyncThunk(
  "article/createListArticleApi",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.post("article/createListArticleApi", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get file
 * @public
 */
export const findFileApi = createAsyncThunk(
  "article/findFileApi",
  async (data: null): Promise<AxiosResponse> => {
    const response = await axios.get(`article/findFileApi`, {
      responseType: "blob",
    });
    return response;
  }
);

/**
 * Get list of article.
 * @public
 */
export const findArticleApi = createAsyncThunk(
  "article/findArticleApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`article/findArticleApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findArticlebyidApi = createAsyncThunk(
  "article/findArticlebyidApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`article/findArticlebyidApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findArticlebyParentCode = createAsyncThunk(
  "article/findArticlebyParentCode",
  async (parent: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `article/findArticlebyParentCode/${parent}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findArticleStockDepotApi = createAsyncThunk(
  "stock/findStockByArticleAndDepots",
  async ({ article_id, depot_id }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stock/findStockByArticleAndDepots/${article_id}/${depot_id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findArticleStockApi = createAsyncThunk(
  "stock/findStockByArticle",
  async (article_id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stock/findStockByArticle/${article_id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findStockByDepot = createAsyncThunk(
  "stock/findStockByDepot",
  async (depot_id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`stock/findStockByDepot/${depot_id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findReptureDeStock = createAsyncThunk(
  "stock/findReptureStockByDepot",
  async (data :any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `stock/findReptureStockByDepot`,data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updateArticleStockApi = createAsyncThunk(
  "stock/updateStockApi",
  async (params: any, { rejectWithValue }) => {
    try {
      const { id, depotId, soldQuantity ,type,organisation,defaut_id} = params;
      const response = await axios.put("stock/updateStockApi", {
        id,
        depotId,
        soldQuantity,
        type,
        organisation,
        defaut_id
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,

      });
    }
  }
);
export const findAllArticleStockApi = createAsyncThunk(
  "stock/findStockByOrganisation",
  async (organisation_id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stock/findStockByOrganisation/${organisation_id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * desactiver  article .
 * @private
 */
export const desactiveArticleApi = createAsyncThunk(
  "article/desactiveArticleApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`article/desactiveArticleApi/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * activer  article .
 * @private
 */
export const activeArticleApi = createAsyncThunk(
  "article/activeArticleApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`article/activeArticleApi/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findArticleByBarCode = createAsyncThunk(
  "article/findArticleByBarCode",
  async (barcode: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `article/findArticleByBarCode/${encodeURIComponent(barcode)}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


export const findArticleStockByBarCodeApi = createAsyncThunk(
  "article/findArticleStockByBarCodeApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    const{depots,barcode}=data
    try {
      const response = await axios.get(
        `article/findArticleStockByBarCodeApi/${depots}/${encodeURIComponent(barcode)}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of article.
 * @public
 */
export const findArticlebysocieteApi = createAsyncThunk(
  "article/findArticlebysocieteApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const { id, index } = data;
      const response = await axios.get(
        `article/findArticlebysocieteApi/${id}/${index}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of article.
 * @public
 */
export const findArticlebytitreApi = createAsyncThunk(
  "article/findArticlebytitreApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const { keyword, index ,type} = data;
      const response = await axios.get(
        `article/findArticlebytitreApi/${encodeURIComponent(keyword)}/${index}/${type}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of stock by article id.
 * @public
 */
export const findStockByArticleIdApi = createAsyncThunk(
  "stock/findStockByArticleIdApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`stock/findStockByArticleIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of stock by search word
 * @public
 */
export const findStockByArticle_codeApi = createAsyncThunk(
  "stock/findStockByArticle_codeApi",
  async (search: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`stock/findStockByArticle_codeApi/${search}`);
      if(response.data.data === null){
        ToastWarning(response.data.message)
      }

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of stock freres by article id.
 * @public
 */
export const findFreresStocksByArticleIdApi = createAsyncThunk(
  "stock/findFreresStocksByArticleIdApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stock/findFreresStocksByArticleIdApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of stock by article id.
 * @public
 */
export const findStockBySocieteApi = createAsyncThunk(
  "stock/findStockBySocieteApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stock/findStockBySocieteApi/${data.societe}/${data.index}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of stock by keyword  .
 * @public
 */
export const findStockByarticleApi = createAsyncThunk(
  "stock/findStockByarticleApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`stock/findStockByarticleApi/${encodeURIComponent(data.article)}/${data.index}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



/**
 * Get article   .
 * @public
 */
export const findArticleByCodearticle = createAsyncThunk(
  "stock/findArticleByCodearticle",
  async (code: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`stock/findArticleByCodearticle/${code}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of stock by filtre .
 * @public
 */
export const stockfilterApi = createAsyncThunk(
  "stock/stockfilterApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`stock/stockfilterApi`,data);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



/**
 * Get list of articles by filtre .
 * @public
 */
export const findArticlesFiltresApi = createAsyncThunk(
  "stock/findArticlesFiltresApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`article/findArticlesFiltresApi`,data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of mouvement stock by article id and depot .
 * @public
 */
export const findArticlestockMouvementApi = createAsyncThunk(
  "stock/findArticlestockMouvementApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const { idArticle, idDepots } = data;
      const response = await axios.get(
        `stock/findArticlestockMouvementApi/${idDepots}/${idArticle}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of article.
 * @public
 */
export const findArticle_stockApi = createAsyncThunk(
  "article/findArticle_stockApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`article/findArticle_stockApi`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



interface ArticleState {
  listArticle: ArticleType[];
  listArticleByOrganisation: { [organisation: string]: ArticleType[] };
  listArticlesBySousCategorie: { [sousCategorie: string]: ArticleType[] };
  articleStock: StockType | {};
  stocks: {
    [article_id: string]: StockType[];
  };
  stocksbyOrganisation: {
    [organisation_id: string]: any[];
  };
  list_stock: any[];
  listStockMouvement: any[];
  articleStockGlobale: StockType[];
  totalPages:any
  currentpage:any,
  reptures: any[];
}
const initialState: ArticleState = {
  listArticle: [],
  listArticleByOrganisation: {},
  listArticlesBySousCategorie: {},
  articleStock: {},
  stocks: {},
  list_stock: [],
  stocksbyOrganisation: {},
  listStockMouvement: [],
  articleStockGlobale: [],
  totalPages:null,
  currentpage:null,

  reptures: [],
};

export const ArticleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createArticleApi.fulfilled, (state, action) => {
      state.listArticle.splice(0, 0, action.payload.data);
    });

    builder.addCase(findArticleApi.fulfilled, (state, action) => {
      const groupedBySousCategorie = _.groupBy(action.payload, "souscategorie");
      state.listArticle = action.payload;
      state.listArticlesBySousCategorie = groupedBySousCategorie;
    });

    builder.addCase(findArticlebysocieteApi.fulfilled, (state, action) => {
      //   const duplicatedPayload = Array(10).fill(action.payload).flat();
      const groupedBySousCategorie = _.groupBy(action.payload.data, "souscategorie");

      const groupedByOrganisation = _.groupBy(
        action.payload,
        "organisation_id"
      );

      state.listArticle = action.payload.data;
      state.listArticleByOrganisation = groupedByOrganisation;
      state.listArticlesBySousCategorie = groupedBySousCategorie;
      state.totalPages=action.payload.totalPages
      state.currentpage=action.payload.currentPage
    });
    builder.addCase(findArticlebytitreApi.fulfilled, (state, action) => {
      //   const duplicatedPayload = Array(10).fill(action.payload).flat();
      const groupedBySousCategorie = _.groupBy(action.payload.data, "souscategorie");

      const groupedByOrganisation = _.groupBy(
        action.payload.data,
        "organisation_id"
      );

      state.listArticle = action.payload.data;
      state.listArticleByOrganisation = groupedByOrganisation;
      state.listArticlesBySousCategorie = groupedBySousCategorie;
      state.totalPages=action.payload.totalPages
      state.currentpage=action.payload.currentPage
    });

    builder.addCase(findArticlesFiltresApi.fulfilled, (state, action) => {
      //   const duplicatedPayload = Array(10).fill(action.payload).flat();
      const groupedBySousCategorie = _.groupBy(action.payload.data, "souscategorie");

      const groupedByOrganisation = _.groupBy(
        action.payload.data,
        "organisation_id"
      );

      state.listArticle = action.payload.data;
      state.listArticleByOrganisation = groupedByOrganisation;
      state.listArticlesBySousCategorie = groupedBySousCategorie;
      state.totalPages=action.payload.totalPages
      state.currentpage=action.payload.currentPage
    });

    builder.addCase(updateArticleApi.fulfilled, (state, action) => {
      const updated_article = action.payload.data;
      var index = _.findIndex(state.listArticle, {
        _id: updated_article._id,
      });
      if (index !== -1) {

        state.listArticle[index] = updated_article;
    }
      // Replace item at index using native splice
    });
    builder.addCase(desactiveArticleApi.fulfilled, (state, action) => {
      const updatedArticle = action.payload.data;
      const index = state.listArticle.findIndex(article => article._id === updatedArticle._id);
      if (index !== -1) {

          state.listArticle[index] = updatedArticle;
      }
  });
  
    builder.addCase(activeArticleApi.fulfilled, (state, action) => {
      const updated_article = action.payload.data;
      var index = _.findIndex(state.listArticle, {
        _id: updated_article._id,
      });
      if (index !== -1) {

        state.listArticle[index] = updated_article;
    }
    });
    builder.addCase(findStockByDepot.fulfilled, (state, action) => {
      const groupedByArticle = _.groupBy(action.payload, "article_id");
      return {
        ...state,
        stocks: groupedByArticle,
      };
    });

    builder.addCase(findReptureDeStock.fulfilled, (state, action) => {
      if(!action.payload.exportdata){
        return {
          ...state,
          reptures: action.payload.data,
          totalPages:action.payload.totalPages,
          currentpage:action.payload.currentPage
        };
      }
      
    });
    builder.addCase(updateArticleStockApi.fulfilled, (state, action) => {
      const article_stock = action.payload.data;
    /*  const index = _.findIndex(state.stocks[article_stock.article_id], {
        _id: article_stock._id,
      });*/
      // state.stocks[article_stock.article_id].splice(index, 1, article_stock);
    });
    builder.addCase(findStockByArticleIdApi.fulfilled, (state, action) => {
      const groupedByOranisation = _.groupBy(action.payload, "organisation_id");
      state.stocksbyOrganisation = groupedByOranisation;
    });
    builder.addCase(findStockByArticle_codeApi.fulfilled, (state, action) => {
      const groupedByOranisation = _.groupBy(action.payload.data, "organisation_id");
      state.stocksbyOrganisation = groupedByOranisation;
     
    });

    builder.addCase(findStockBySocieteApi.fulfilled, (state, action) => {
      const groupedByOranisation = _.groupBy(action.payload.data, "organisation_id");
      state.stocksbyOrganisation = groupedByOranisation;
      state.list_stock = action.payload.data;
      state.totalPages=action.payload.totalPages
      state.currentpage=action.payload.currentPage
    });
    builder.addCase(findStockByarticleApi.fulfilled, (state, action) => {
      const groupedByOranisation = _.groupBy(action.payload.data, "organisation_id");
      state.stocksbyOrganisation = groupedByOranisation;
      state.list_stock = action.payload.data;
      state.totalPages=action.payload.totalPages
      state.currentpage=action.payload.currentPage
    });
    builder.addCase(stockfilterApi.fulfilled, (state, action) => {
      const groupedByOranisation = _.groupBy(action.payload.data, "organisation_id");
      state.stocksbyOrganisation = groupedByOranisation;
      state.list_stock = action.payload.data;
      state.totalPages=action.payload.totalPages
      state.currentpage=action.payload.currentPage
    });
    builder.addCase(findArticlestockMouvementApi.fulfilled, (state, action) => {
      state.listStockMouvement = action.payload;
    });
    builder.addCase(
      findFreresStocksByArticleIdApi.fulfilled,
      (state, action) => {
        state.articleStockGlobale = action.payload;
      }
    );
   
  },
});

export const ListArticles = (state: RootState) => state.articles.listArticle;
export const ListArticleByOrganisation = (state: RootState) =>
  state.articles.listArticleByOrganisation;
export const ListArticleBySousCategorie = (state: RootState) =>
  state.articles.listArticlesBySousCategorie;
export const Stocks = (state: RootState) => state.articles.stocks;
export const Liststock = (state: RootState) => state.articles.list_stock;

export const StocksbyOrganisation = (state: RootState) =>
  state.articles.stocksbyOrganisation;
export const ListStockMouvement = (state: RootState) =>
  state.articles.listStockMouvement;
export const ArticleStockGlobale = (state: RootState) =>
  state.articles.articleStockGlobale;
export const currentpage = (state: RootState) => state.articles.currentpage;
export const totalPages = (state: RootState) => state.articles.totalPages;

export const ReptureStockList = (state: RootState) => state.articles.reptures;

export default ArticleSlice.reducer;
