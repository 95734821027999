import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch } from "../../../_App/Redux/hooks";
import { setQuantity } from "../../../_App/Redux/Slices/Caisse/PanierSlice";

const CalculatorModal = ({ ShowModal, setShowModal }: any) => {
  const [input, setInput] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const containsOnlyNumbers = (str: string) => !/\D/.test(str);

    if (result && containsOnlyNumbers(result)) {
      dispatch(setQuantity(Number(result)));
      setShowModal(false);
      setInput("");
      setResult("");
    }
    if (input && containsOnlyNumbers(input)) {
      dispatch(setQuantity(Number(input)));
      setShowModal(false);
      setInput("");
      setResult("");
    } else {
      const modifiedString = input.replace(/×/g, "*").replace(/÷/g, "/");
      if (containsOnlyNumbers(eval(modifiedString)?.toString())) {
        dispatch(setQuantity(Number(eval(modifiedString)?.toString())));
        setShowModal(false);
        setInput("");
        setResult("");
      }
    }
  };

  const handleButtonClick = (value: string) => {
    const modifiedString = input.replace(/×/g, "*").replace(/÷/g, "/");

    if (value === "=") {
      try {
        setResult(eval(modifiedString)?.toString());
      } catch (error) {
        setResult("Error");
      }
    } else if (value === "C") {
      setInput("");
      setResult("");
    } else {
      setInput((prevInput) => prevInput + value);
    }
  };

  useEffect(() => {
    // console.log(result);
  }, [result]);
  return (
    <div className="App">
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="ticket-modal"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="calculator-container">
          <div className="calculator">
            <div className="calculator__display">
              <div className="calculator-input">{input}</div>
              <div className="result">{result}</div>
            </div>
            <div className="calculator__keys">
              <button
                onClick={() => {
                  if (result) {
                    setInput(result);
                    setResult("");
                  }
                  handleButtonClick("+");
                }}
                className="key--operator number-button"
                data-action="add"
              >
                <i className="fas fa-plus"></i>
              </button>
              <button
                onClick={() => {
                  if (result) {
                    setInput(result);
                    setResult("");
                  }
                  handleButtonClick("-");
                }}
                className="key--operator number-button"
                data-action="subtract"
              >
                <i className="fas fa-minus"></i>
              </button>
              <button
                onClick={() => {
                  if (result) {
                    setInput(result);
                    setResult("");
                  }
                  handleButtonClick("×");
                }}
                className="key--operator number-button"
                data-action="multiply"
              >
                <i className="fas fa-times"></i>
              </button>
              <button
                onClick={() => {
                  if (result) {
                    setInput(result);
                    setResult("");
                  }
                  handleButtonClick("÷");
                }}
                className="key--operator number-button"
                data-action="divide"
              >
                <i className="fas fa-divide"></i>
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("7");
                }}
                className="number-button"
              >
                7
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("8");
                }}
                className="number-button"
              >
                8
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("9");
                }}
                className="number-button"
              >
                9
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("4");
                }}
                className="number-button"
              >
                4
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("5");
                }}
                className="number-button"
              >
                5
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("6");
                }}
                className="number-button"
              >
                6
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("1");
                }}
                className="number-button"
              >
                1
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("2");
                }}
                className="number-button"
              >
                2
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("3");
                }}
                className="number-button"
              >
                3
              </button>
              <button
                onClick={() => {
                  if (result) handleButtonClick("C");
                  handleButtonClick("0");
                }}
                className="number-button"
              >
                0
              </button>

              <button
                onClick={() => handleButtonClick(".")}
                data-action="decimal"
                className="number-button"
              >
                .
              </button>
              <button
                onClick={() => handleButtonClick("C")}
                data-action="clear"
                className="number-button"
              >
                <i className="fas fa-backspace"></i>
              </button>

              <button
                onClick={() => {
                  handleButtonClick("=");
                }}
                className="key--equal"
                data-action="calculate"
              >
                <i className="fas fa-equals"></i>
              </button>
              <button
                onClick={() => {
                  handleSubmit();
                }}
                className="key--submit"
                data-action="calculate"
              >
                <i className="fas fa-share"></i>
              </button>
            </div>{" "}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CalculatorModal;
