import React, { useState, useEffect } from "react";
import ClientForm from "../../Components/Client/ClientForm";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListClient,
  findClientBySocieteApi,
  desactiveClientApi,
  activeClientApi,
} from "../../_App/Redux/Slices/client/clientSlice";
import ClientTable from "../../Components/Client/ClientTable";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { useTranslation } from "react-i18next";
import Navbar from "../../_Layouts/Navbar/Navbar";
import Swal from "sweetalert2";
const ClientPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const [showModelClient, setshowModelClients] = useState(false);
  const [clientitem, setclientitem] = useState(null);

  const client = useAppSelector(ListClient);
  useEffect(() => {
    if (societe) {
      dispatch(findClientBySocieteApi(societe));
    }
  }, [dispatch]);

  const updateFunction = (item: any) => {
    setclientitem(item);
    setshowModelClients(true);
  };
  const verifcationMsg = (msg: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t('Cancel!')

    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  const desactiveFunction = async (id: string) => {
    if(await verifcationMsg((t('vous voulez desactiver le client')))){
      const response = await dispatch(desactiveClientApi(id));
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
    
  };

  const activeFunction = async (id: string) => {
    if(await verifcationMsg((t('vous voulez activer le client')))){
      const response = await dispatch(activeClientApi(id));
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
    
  };

  return (
    <div>
      <Navbar />

      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className="btn btn-primary-app"
          onClick={() => setshowModelClients(true)}
        >
          {t("Ajouter client")}
        </button>
      </div>
      <ClientForm
        t={t}
        verifcationMsg={verifcationMsg}
        ShowModal={showModelClient}
        setShowModal={setshowModelClients}
        client={clientitem}
      />
      {client && client.length !== 0 ? (
        <ClientTable
          t={t}
          Listclient={client}
          setclientitem={setclientitem}
          desactiveFunction={desactiveFunction}
          activeFunction={activeFunction}
          updateFunction={updateFunction}
        />
      ) : (
        <p>aucun client</p>
      )}
    </div>
  );
};

export default ClientPage;
