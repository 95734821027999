import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  selectUser,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  Journal,
  getJournalCaisseByIdApi,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import {
  ListJournalMouvementsCaisse,
  findMouvementsCaisseByJournalApi,
} from "../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import {
  ListJournalOrders,
  findOrdersByJournalApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import { formadate, formatFloat, generateUniqueCode } from "../../_App/Helpers/helpers";
import {
  ArticleData,
  OrderType,
} from "../../_App/Types/Entites/Orders/OrderType";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { PaymentType } from "../../_App/Types/Entites/PaymentType";
import {
  FactureItemType,
  FactureType,
} from "../../_App/Types/Entites/FactureType";
import {
  fetchFactures,
  getFactureByJournal,
  saveFactureApi,
  selectFactures,
} from "../../_App/Redux/Slices/facture/FactureSlice";
import Swal from "sweetalert2";

const FactureSansEspeces = () => {
  let { id } = useParams<{ id?: string }>();
  const [produits, setProduits] = useState<ArticleData[]>();
  var totalHt = 0;
  var TotalNetHT = 0;
  var montantTVA = 0;
  var totalTTC = 0;
  var NetToPay = 0;
  const timbre = 1;
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe_id = useAppSelector(societeId);

  const journal = useAppSelector(Journal);
  const journalOrders = useAppSelector(ListJournalOrders);
  const user = useAppSelector(selectUser);
  const factures = useAppSelector(selectFactures);

  const grouppingProducts = () => {
    let array: ArticleData[] = [];

    // Filter out orders that have only cash payments
    const filteredOrders = journalOrders.filter((order: any) => {
      // Check if there's at least one non-cash payment method in the order's payments
      return order.payments.some(
        (payment: PaymentType) => payment.payment_method !== "Espéces"
      );
    });

    // Now, map through the filtered orders to group products
    filteredOrders.forEach((order: any) => {
      if (order.status === "Payée") {
        order.items.forEach((item: ArticleData) => {
          const index = array.findIndex(
            (product) => product.article._id === item.article._id
          );
          if (index >= 0) {
            // Make sure ArticleData properties are not read-only
            array[index].quantity += item.quantity;
            array[index].prix_total += item.prix_total;
          } else {
            array.push({ ...item });
          }
        });
      }
    });

    return array;
  };
  const [savedFacture, setSavedFacture] = useState<FactureType | undefined>(
    undefined
  );
  useEffect(() => {
    const fetchData = async () => {
      if (currentOrganisation && id) {
        dispatch(findMouvementsCaisseByJournalApi(id));
        dispatch(getJournalCaisseByIdApi(id));
        dispatch(findOrdersByJournalApi(id));
        dispatch(fetchFactures(null));
        const response = await dispatch(getFactureByJournal(id));
        console.log(response);
        if (response?.payload?.success) {
          setSavedFacture(response.payload.data[0]);
        }
      }
    };

    fetchData();
  }, [dispatch]);

  let date = new Date();

  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [isPrinting, setIsPrinting] = useState(false);

  const handleCheckboxChange = (itemId: number) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  function eliminateItemsFromArray1() {
    let itemsCopy = JSON.parse(JSON.stringify(grouppingProducts()));
    if (checkedItems.length)
      return itemsCopy.filter((item: any) =>
        checkedItems.includes(item.article._id)
      );
    else return itemsCopy;
  }

  const saveFacture = async (
    facture: FactureType,
    items: FactureItemType[]
  ) => {
    try {
      const response = await dispatch(saveFactureApi({ facture, items }));
      if (response.payload.success) {
        // Check if the response indicates success
        // Show success alert
        Swal.fire({
          title: "Succès",
          text: "La facture a été enregistrée avec succès.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        // Handle failure case
        Swal.fire({
          title: "Erreur",
          text: "Une erreur est survenue lors de l'enregistrement de la facture.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error saving facture:", error);
      Swal.fire({
        title: "Erreur",
        text: "Une erreur est survenue lors de l'enregistrement de la facture.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="row invoice layout-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="doc-container">
            <div className="row">
              <div className="col-xl-12">
                <div className="invoice-container">
                  {savedFacture ? (
                    <div className="invoice-inbox" ref={componentRef}>
                      <div id="ct" className="">
                        <div className="invoice-00001">
                          <div className="content-section">
                            <div className="inv--head-section inv--detail-section">
                              <div className="row">
                                <div className="col-sm-6 col-12 mr-auto">
                                  <div className="d-flex">
                                    {/* <img
                                    className="company-logo"
                                    src="/assets/assets/img/logo.png"
                                    alt="company"
                                  /> */}
                                    <h3 className="in-heading align-self-center">
                                      {savedFacture.societe?.nom}
                                    </h3>
                                  </div>
                                </div>

                                <div className="col-sm-6 text-sm-right">
                                  <p className="inv-list-number">
                                    <span className="inv-title">
                                      Facture :{" "}
                                    </span>{" "}
                                    <span className="inv-number">
                                      {savedFacture.code}
                                    </span>
                                  </p>
                                  <p className="inv-list-number">
                                    <span className="inv-title">
                                      Journal :{" "}
                                    </span>{" "}
                                    <span className="inv-number">
                                      {" "}
                                      {savedFacture.journal?.code_journal}{" "}
                                    </span>
                                  </p>
                                </div>

                                <div className="col-sm-6 align-self-center mt-3">
                                  <p className="inv-street-addr"></p>

                                  <p className="inv-email-address">
                                    {savedFacture?.societe?.tel}
                                  </p>
                                </div>
                                <div className="col-sm-6 align-self-center mt-3 text-sm-right">
                                  <p className="inv-created-date">
                                    <span className="inv-title">
                                      Date Facture :{" "}
                                    </span>{" "}
                                    <span className="inv-date">
                                      {" "}
                                      {formadate(savedFacture.date)}{" "}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="inv--product-table-section">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead className="">
                                    <tr>
                                      <th scope="col" style={{ width: "5%" }}>
                                        N°
                                      </th>
                                      <th scope="col" style={{ width: "10%" }}>
                                        Ref
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "20%" }}
                                      >
                                        Article
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Prix HT
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "5%" }}
                                      >
                                        Qte
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        TVA
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Remise
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Total HT
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Total Net HT
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {savedFacture.items.map(
                                      (
                                        item: FactureItemType,
                                        index: number
                                      ) => {
                                        const calculatedRemise =
                                          (item.item[0].article.prix_vente_ht *
                                            item.item[0].quantity *
                                            item.item[0].article.remise) /
                                          100;
                                        totalHt +=
                                          item.item[0].article.prix_vente_ht *
                                          item.item[0].quantity;
                                        TotalNetHT +=
                                          item.item[0].article.prix_vente_ht *
                                            item.item[0].quantity -
                                          calculatedRemise;
                                        montantTVA +=
                                          ((item.item[0].article.prix_vente_ht *
                                            item.item[0].quantity -
                                            calculatedRemise) *
                                            item.item[0].article.tva) /
                                          100;

                                        const codeArticle =
                                          item.item[0].article.code_article;

                                        return (
                                          <tr key={index}>
                                            <td> {index + 1} </td>
                                            <td>{codeArticle}</td>
                                            <td className="text-right">
                                              {item.item[0].article.titre.fr}
                                            </td>
                                            <td className="text-right">
                                              {item.item[0].article.prix_vente_ht.toFixed(
                                                3
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {item.item[0].quantity}
                                            </td>

                                            <td className="text-right">
                                              {item.item[0].article.tva}
                                            </td>
                                            <td className="text-right">
                                              {" "}
                                              {item.item[0].article.remise}{" "}
                                            </td>
                                            <td className="text-right">
                                              {(
                                                item.item[0].article
                                                  .prix_vente_ht *
                                                item.item[0].quantity
                                              ).toFixed(3)}
                                            </td>
                                            <td className="text-right">
                                              {(
                                                item.item[0].article
                                                  .prix_vente_ht *
                                                  item.item[0].quantity -
                                                calculatedRemise
                                              ).toFixed(3)}{" "}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="inv--total-amounts">
                              <div className="row mt-4">
                                <div className="col-sm-5 col-12 order-sm-0 order-1"></div>
                                <div className="col-sm-7 col-12 order-sm-1 order-0">
                                  <div className="text-sm-right">
                                    <div className="row">
                                      <div className="col-sm-8 col-7">
                                        <p className="">Total HT : </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {totalHt.toFixed(3)}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Total Net HT </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {TotalNetHT.toFixed(3)}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Montant TVA </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {montantTVA.toFixed(3)}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Total TTC </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {(TotalNetHT + montantTVA).toFixed(
                                            3
                                          )}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Timbre</p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className=""> {formatFloat(timbre)} </p>
                                      </div>
                                      <div className="col-sm-8 col-7 grand-total-title">
                                        <h4 className="">Total a payer</h4>
                                      </div>
                                      <div className="col-sm-4 col-5 grand-total-amount">
                                        <h4 className="">
                                          {(
                                            TotalNetHT +
                                            montantTVA +
                                            timbre
                                          ).toFixed(3)}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="invoice-inbox" ref={componentRef}>
                      <div id="ct" className="">
                        <div className="invoice-00001">
                          <div className="content-section">
                            <div className="inv--head-section inv--detail-section">
                              <div className="row">
                                <div className="col-sm-6 col-12 mr-auto">
                                  <div className="d-flex">
                                    {/* <img
                                    className="company-logo"
                                    src="/assets/assets/img/logo.png"
                                    alt="company"
                                  /> */}
                                    <h3 className="in-heading align-self-center">
                                      {journal?.organisation_id?.nom}
                                    </h3>
                                  </div>
                                </div>

                                <div className="col-sm-6 text-sm-right">
                                  <p className="inv-list-number">
                                    <span className="inv-title">
                                      Facture :{" "}
                                    </span>{" "}
                                    <span className="inv-number">#0001</span>
                                  </p>
                                  <p className="inv-list-number">
                                    <span className="inv-title">
                                      Journal :{" "}
                                    </span>{" "}
                                    <span className="inv-number">
                                      {" "}
                                      {journal?.code_journal}{" "}
                                    </span>
                                  </p>
                                </div>

                                <div className="col-sm-6 align-self-center mt-3">
                                  <p className="inv-street-addr">
                                    XYZ Delta Street
                                  </p>
                                  <p className="inv-email-address">
                                    {user?.email}
                                  </p>
                                  <p className="inv-email-address">
                                    {journal?.organisation_id?.numero}
                                  </p>
                                </div>
                                <div className="col-sm-6 align-self-center mt-3 text-sm-right">
                                  <p className="inv-created-date">
                                    <span className="inv-title">
                                      Date Facture :{" "}
                                    </span>{" "}
                                    <span className="inv-date">
                                      {" "}
                                      {formadate(date)}{" "}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="inv--product-table-section">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead className="">
                                    <tr>
                                      <th scope="col" style={{ width: "5%" }}>
                                        N°
                                      </th>
                                      <th scope="col" style={{ width: "10%" }}>
                                        Ref
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "20%" }}
                                      >
                                        Article
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Prix HT
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "5%" }}
                                      >
                                        Qte
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        TVA
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Remise
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Total HT
                                      </th>
                                      <th
                                        className="text-right"
                                        scope="col"
                                        style={{ width: "10%" }}
                                      >
                                        Total Net HT
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!isPrinting
                                      ? grouppingProducts() &&
                                        grouppingProducts().map(
                                          (item: any, index: number) => {
                                            const calculatedRemise =
                                              (item.article.prix_vente_ht *
                                                item.quantity *
                                                item.article.remise) /
                                              100;
                                            totalHt +=
                                              item.article.prix_vente_ht *
                                              item.quantity;
                                            TotalNetHT +=
                                              item.article.prix_vente_ht *
                                                item.quantity -
                                              calculatedRemise;
                                            montantTVA +=
                                              ((item.article.prix_vente_ht *
                                                item.quantity -
                                                calculatedRemise) *
                                                item.article.tva) /
                                              100;

                                            const codeArticle =
                                              item.article.code_article;

                                            return (
                                              <tr key={index}>
                                                <td> {index + 1} </td>
                                                <td>{codeArticle}</td>
                                                <td className="text-right">
                                                  {!isPrinting ? (
                                                    <input
                                                      type="checkbox"
                                                      onChange={() =>
                                                        handleCheckboxChange(
                                                          item.article._id
                                                        )
                                                      }
                                                      checked={checkedItems.includes(
                                                        item.article._id
                                                      )}
                                                    />
                                                  ) : null}{" "}
                                                  {item.article.titre.fr}
                                                </td>
                                                <td className="text-right">
                                                  {item.article.prix_vente_ht.toFixed(
                                                    3
                                                  )}
                                                </td>
                                                <td className="text-right">
                                                  {item.quantity}
                                                </td>

                                                <td className="text-right">
                                                  {item.article.tva}
                                                </td>
                                                <td className="text-right">
                                                  {" "}
                                                  {item.article.remise}{" "}
                                                </td>
                                                <td className="text-right">
                                                  {(
                                                    item.article.prix_vente_ht *
                                                    item.quantity
                                                  ).toFixed(3)}
                                                </td>
                                                <td className="text-right">
                                                  {(
                                                    item.article.prix_vente_ht *
                                                      item.quantity -
                                                    calculatedRemise
                                                  ).toFixed(3)}{" "}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : eliminateItemsFromArray1() &&
                                        eliminateItemsFromArray1().map(
                                          (item: any, index: number) => {
                                            const calculatedRemise =
                                              (item.article.prix_vente_ht *
                                                item.quantity *
                                                item.article.remise) /
                                              100;
                                            totalHt +=
                                              item.article.prix_vente_ht *
                                              item.quantity;
                                            TotalNetHT +=
                                              item.article.prix_vente_ht *
                                                item.quantity -
                                              calculatedRemise;
                                            montantTVA +=
                                              ((item.article.prix_vente_ht *
                                                item.quantity -
                                                calculatedRemise) *
                                                item.article.tva) /
                                              100;
                                            const codeArticle =
                                              item.article.code_article;

                                            return (
                                              <tr key={index}>
                                                <td> {index + 1} </td>
                                                <td>{codeArticle}</td>
                                                <td className="text-right">
                                                  {!isPrinting ? (
                                                    <input
                                                      type="checkbox"
                                                      onChange={() =>
                                                        handleCheckboxChange(
                                                          item.article._id
                                                        )
                                                      }
                                                      checked={checkedItems.includes(
                                                        item.article._id
                                                      )}
                                                    />
                                                  ) : null}{" "}
                                                  {item.article.titre.fr}
                                                </td>
                                                <td className="text-right">
                                                  {item.article.prix_vente_ht.toFixed(
                                                    3
                                                  )}
                                                </td>
                                                <td className="text-right">
                                                  {item.quantity}
                                                </td>

                                                <td className="text-right">
                                                  {item.article.tva}
                                                </td>
                                                <td className="text-right">
                                                  {item.article.remise}
                                                </td>
                                                <td className="text-right">
                                                  {(
                                                    item.article.prix_vente_ht *
                                                    item.quantity
                                                  ).toFixed(3)}
                                                </td>
                                                <td className="text-right">
                                                  {/* Prix HT = Prix TTC ÷ (1 + Taux de TVA) */}{" "}
                                                  {(
                                                    item.article.prix_vente_ht *
                                                      item.quantity -
                                                    calculatedRemise
                                                  ).toFixed(3)}{" "}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="inv--total-amounts">
                              <div className="row mt-4">
                                <div className="col-sm-5 col-12 order-sm-0 order-1"></div>
                                <div className="col-sm-7 col-12 order-sm-1 order-0">
                                  <div className="text-sm-right">
                                    <div className="row">
                                      <div className="col-sm-8 col-7">
                                        <p className="">Total HT : </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {totalHt.toFixed(3)}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Total Net HT </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {TotalNetHT.toFixed(3)}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Montant TVA </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {montantTVA.toFixed(3)}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Total TTC </p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className="">
                                          {" "}
                                          {(TotalNetHT + montantTVA).toFixed(
                                            3
                                          )}{" "}
                                        </p>
                                      </div>
                                      <div className="col-sm-8 col-7">
                                        <p className="">Timbre</p>
                                      </div>
                                      <div className="col-sm-4 col-5">
                                        <p className=""> {formatFloat(timbre)} </p>
                                      </div>
                                      <div className="col-sm-8 col-7 grand-total-title">
                                        <h4 className="">Total a payer</h4>
                                      </div>
                                      <div className="col-sm-4 col-5 grand-total-amount">
                                        <h4 className="">
                                          {(
                                            TotalNetHT +
                                            montantTVA +
                                            timbre
                                          ).toFixed(3)}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!savedFacture && (
                    <div className="row" style={{ justifyContent: "center" }}>
                      <button
                        className="btn btn-success mb-2"
                        style={{
                          width: "80%",
                          background: "#009688",
                        }}
                        onClick={(e) => {
                          setIsPrinting(!isPrinting);
                        }}
                      >
                        {isPrinting ? "Editer" : "Confirmer"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {savedFacture ? (
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-primary" style={{ width: "100%" }}>
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      ) : (
        isPrinting && (
          <button
            className="btn btn-primary mb-2"
            style={{ width: "100%" }}
            onClick={() => {
              if (societe_id && id && user)
                saveFacture(
                  {
                    societe_id: societe_id,
                    date: new Date(),
                    journal_id: id,
                    code: generateUniqueCode(factures),
                    user_id: user._id,
                  },
                  eliminateItemsFromArray1()
                );
            }}
          >
            Enregistrer
          </button>
        )
      )}
    </>
  );
};

export default FactureSansEspeces;
