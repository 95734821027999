

import { object, string, date } from 'yup';

export const ClientSchema = object({
  nom: string().required('nom obligatoire !'),
  prenom: string().required('prenom obligatoire'),
  date_naissance: string().required('date de naissance obligatoire'),
  email: string().required('email obligatoire').email('Format d\'email non valide'),
  mobile: string().required('mobile obligatoire'),
});
