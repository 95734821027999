import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const AddArticleDefectueuxApi = createAsyncThunk(
  "articledefectueux/AddArticleDefectueuxApi",
  async (
    { datalistAricle, organisation_id, societe_id ,order}: any,
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      }; 

      const promises = datalistAricle.map(async(data: any) => {
        const body = new FormData();
        body.append("organisation_id", organisation_id.toString());
        body.append("societe_id", societe_id.toString());
        body.append("article_id", data.article._id.toString());
        body.append("Quantite", data.quantite.toString());
        body.append("note", data.note!== null ?data.note.toString():'');
        body.append("order", order.toString());

        if (data.image ) {
          body.append("image", data.image);
        }

        const defresp = await axios.post("articledefectueux/AddArticleDefectueuxApi", body, config);
        return defresp.data.data
        
      });

      // Wait for all requests to complete
      const responses = await Promise.all(promises);
      return responses
      // Return the result data from all API responses
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response
          ? error.response.data.message
          : "An error occurred",
      });
    }
  }
);


export const AddArticleDefectueuxOnlineApi = createAsyncThunk(
  "articledefectueux/AddArticleDefectueuxOnlineApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
        
      };
      
        const body = new FormData();
        body.append("organisation", data.organisation=== null ? 'null':data.organisation.toString());
        body.append("organisationActuel", data.organisationActuel.toString());
        body.append("user_id", data.user_id.toString());
        body.append("retour", data.retour.toString());

        body.append("societe_id", data.societe_id.toString());
        body.append("article_id", data.article_id? data.article_id.toString():'null');
        body.append("Quantite", data.Quantite.toString());
        body.append("note", data.note !== null ?data.note.toString():'');
        body.append("order", data.order && data.order!==null ?data.order.toString():'null');
      
        if (data.image ) {
          body.append("image", data.image);
        }

        const response=  await axios.post("articledefectueux/AddArticleDefectueuxOnlineApi", body, config);
        return response.data

      // Wait for all requests to complete

      // Return the result data from all API responses
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response
          ? error.response.data.message
          : "An error occurred",
      });
    }
  }
);

/**
 * Get list of ArticleDefectueuxApi.
 * @private
 */
export const findArticleDefectueuxApi = createAsyncThunk(
  "articledefectueux/findArticleDefectueuxApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`articledefectueux/findArticleDefectueuxApi`,data);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findArticleDefectueuxBarCode = createAsyncThunk(
  "articledefectueux/findArticleDefectueuxBarCode",
  async (barcode: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `articledefectueux/findArticleDefectueuxBarCode/${encodeURIComponent(barcode)}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateArticleDefectueux = createAsyncThunk(
  "articledefectueux/updateArticleDefectueuxApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        `articledefectueux/updateArticleDefectueuxApi`,data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const TransferArticleDefectueuxApi = createAsyncThunk(
  "articledefectueux/TransferArticleDefectueuxApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `articledefectueux/TransferArticleDefectueuxApi`,data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface ProductColorState {
  listArticledefectueux: any[];
  
}
const initialState: ProductColorState = {
  listArticledefectueux: [],
  
}; 

export const ArticleDefectueuxSlice = createSlice({
  name: "articledefectueuxSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findArticleDefectueuxApi.fulfilled, (state, action) => {
      state.listArticledefectueux=action.payload
    });
   

  },
});

export const ListArticledefectueux = (state: RootState) => state.articledefectueux.listArticledefectueux;

export default ArticleDefectueuxSlice.reducer;
