import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListOrdersByClients,
  findOrdersByOrganisation,
  findOrdersFiltreApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import { Modal } from "react-bootstrap";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  formatDateAndTime,
  formatTime,
  formatdate,
} from "../../_App/Helpers/helpers";
import { OrderType } from "../../_App/Types/Entites/Orders/OrderType";
import {
  findClientHistoriqueFidelite,
  historiqueFidelite,
} from "../../_App/Redux/Slices/client/clientSlice";

const FicheClient = ({ client, ShowModalClient, setShowModalClient }: any) => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector(ListOrdersByClients);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const [selectedTicket, setSelectedTicket] = useState<OrderType>();
  const totalSum: number = orders[client._id]
    ? orders[client._id].reduce((sum, order) => sum + order.totalNet, 0)
    : 0;

 
  useEffect(() => {
    
      dispatch(findClientHistoriqueFidelite(client._id));
    
  }, [client]);
  useEffect(()=>{
    if (ShowModalClient &&currentOrganisation) {
      const data: any = {
        organisation: currentOrganisation,
      };
      
      dispatch(findOrdersFiltreApi(data));
    }
  },[ShowModalClient])
  const historiqueFideliteClient = useAppSelector(historiqueFidelite);
  return (
    <Modal
      show={ShowModalClient}
      onHide={() => setShowModalClient(false)}
      aria-labelledby="app_parametrages"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="app_parametrages" className="custom-modal-title">
          <div className="titleWithButton">
            <div
              className="c-primary"
              onClick={() => console.log(historiqueFideliteClient)}
            >
              Fiche Client {client.label}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px 12px" }}>
        <div>
          <div className="row">
            <div id="timelineBasic" className="col-lg-12 layout-spacing">
              <div
                className="statbox widget box box-shadow"
                style={{ border: "none", boxShadow: "none" }}
              >
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h3
                      className="mb-3"
                      onClick={() => console.log(Object.values(orders)[0][0])}
                    >
                      {" "}
                      Détails personnels
                    </h3>
                  </div>
                </div>
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Nom :</b> {" " + client?.nom}
                      </h4>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Prenom :</b>
                        {" " + client?.prenom}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Date naissance :</b>
                        {" " + client?.date_naissance?.toString().split("T")[0]}
                      </h4>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        <b> Tél :</b>
                        {" " + client?.mobile}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h3 className="mt-3"> Fidélité</h3>
                  </div>
                </div>
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Points :</b>
                        {" " + client?.points}
                      </h4>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Equivalence :</b>
                        {" " + client?.equivalentMoney}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Niveau fidélité :</b>
                        {" " + client?.niveauFidelite?.libelle}
                      </h4>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4>
                        {" "}
                        <b>Date debut fidélité :</b>
                        {" " + client?.dateFidelite?.toString().split("T")[0]}
                      </h4>
                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                      <h4> </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div id="timelineBasic" className="col-lg-12 layout-spacing">
              <div
                className="statbox widget box box-shadow"
                style={{ border: "none", boxShadow: "none" }}
              >
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                      <h3
                      //   onClick={() => console.log(client.label.props.children[0])}
                      >
                        {" "}
                        Historique achats - {totalSum.toFixed(3) + " TND"} total
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className="widget-content widget-content-area pb-1 row"
                  style={{ border: "none" }}
                >
                  <div className="mt-container mx-auto col-6">
                    <div className="timeline-line">
                      {orders &&
                        orders[client._id] &&
                        orders[client._id].map((item, index) => (
                          <div
                            className="item-timeline"
                            onClick={() => setSelectedTicket(item)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            <p className="t-time">
                              {" "}
                              {formatDateAndTime(item.date)}
                            </p>
                            <div className="t-dot t-dot-primary"></div>
                            <div className="t-text">
                              <p>{"Commande N° " + item.order_number}</p>
                              <p className="t-meta-time">
                                {item?.totalNet?.toFixed(3)}{" "}
                              </p>
                            </div>
                          </div>
                        ))}

                      {/* <div className="item-timeline">
                        <p className="t-time">12:45</p>
                        <div className="t-dot t-dot-success"></div>
                        <div className="t-text">
                          <p>Backup Files EOD</p>
                          <p className="t-meta-time">2 hrs ago</p>
                        </div>
                      </div>

                      <div className="item-timeline">
                        <p className="t-time">14:00</p>
                        <div className="t-dot t-dot-warning"></div>
                        <div className="t-text">
                          <p>Send Mail to HR and Admin</p>
                          <p className="t-meta-time">4 hrs ago</p>
                        </div>
                      </div>

                      <div className="item-timeline">
                        <p className="t-time">16:00</p>
                        <div className="t-dot t-dot-info"></div>
                        <div className="t-text">
                          <p>Conference call with Marketing Manager.</p>
                          <p className="t-meta-time">6 hrs ago</p>
                        </div>
                      </div>

                      <div className="item-timeline">
                        <p className="t-time">17:00</p>
                        <div className="t-dot t-dot-danger"></div>
                        <div className="t-text">
                          <p>
                            Collected documents from{" "}
                            <a href="javascript:void(0);">Sara</a>
                          </p>
                          <p className="t-meta-time">9 hrs ago</p>
                        </div>
                      </div>

                      <div className="item-timeline">
                        <p className="t-time">16:00</p>
                        <div className="t-dot t-dot-dark"></div>
                        <div className="t-text">
                          <p>Server rebooted successfully</p>
                          <p className="t-meta-time">8 hrs ago</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="mt-container mx-auto col-6">
                    {selectedTicket ? (
                      <>
                        <div
                          className="container-ticket"
                          id="print-content"
                          style={{ width: "100%" }}
                        >
                          <div className="receipt_header">
                            <h1>
                              Ticket de caisse <span>Clediss</span>
                            </h1>
                            <h2>
                              Address: Lorem Ipsum, 1234-5{" "}
                              <span>Tel: +1 012 345 67 89</span>
                              <span>
                                {" "}
                                {"Ticket n°" + selectedTicket.order_number}{" "}
                              </span>
                            </h2>
                          </div>

                          <div className="row">
                            <div className="col-2" style={{ fontSize: "12px" }}>
                              <p className="inv-street-addr mb-0">Numro</p>
                              <p className="inv-email-address">
                                {selectedTicket?.order_number}
                              </p>
                            </div>
                            <div className="col-2" style={{ fontSize: "12px" }}>
                              <p className="inv-street-addr mb-0">Etab</p>
                              <p className="inv-email-address">101</p>
                            </div>
                            <div className="col-2" style={{ fontSize: "12px" }}>
                              <p className="inv-street-addr mb-0">Etab</p>
                              <p className="inv-email-address">101</p>
                            </div>
                            <div className="col-2" style={{ fontSize: "12px" }}>
                              <p className="inv-street-addr mb-0">Caisse</p>
                              <p className="inv-email-address">2</p>
                            </div>
                          </div>

                          <div className="receipt_body">
                            <div className="date_time_con">
                              <div className="date">
                                {" "}
                                {formatDateAndTime(selectedTicket?.date)}{" "}
                              </div>
                              {/* <div className="time">
                              {" "}
                              {formatTime({
                                date: selectedTicket?.date.toISOString(),
                              })}
                            </div> */}
                            </div>

                            <div className="ticket-items">
                              <table style={{ whiteSpace: "pre-line" }}>
                                <thead>
                                  <tr>
                                    <th>QTE</th>
                                    <th>Désignation</th>
                                    <th>Total</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {selectedTicket.items.map((item: any) => (
                                    <tr key={item.article._id}>
                                      <td>{item.quantity}</td>
                                      <td>
                                        {item.article.titre.fr}
                                        {item.article.caracteristiques &&
                                        item.article.caracteristiques.length
                                          ? item.article.caracteristiques.map(
                                              (caracteristique: any) =>
                                                caracteristique.value
                                            )
                                          : null}
                                      </td>
                                      <td> {item.prix_total.toFixed(3)}</td>
                                    </tr>
                                  ))}
                                </tbody>

                                <tfoot>
                                  <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td> {selectedTicket.total.toFixed(3)} </td>
                                  </tr>

                                  {/* <>
                                  <tr>
                                    <td
                                      onClick={() =>
                                        console.log(paymentDetails)
                                      }
                                    >
                                      Réglement(s)
                                    </td>
                                  </tr>
                                  {Object.entries(paymentDetails).map(
                                    ([key, value]) => (
                                      <tr key={key}>
                                        <td> {key} </td>
                                        <td></td>
                                        <td> {value[0].amount.toFixed(3)} </td>
                                      </tr>
                                    )
                                  )}

                                  {Object.entries(paymentDetails).map(
                                    ([key, value]) =>
                                      value[0].rendu ? (
                                        <tr key={key}>
                                          <td> Rendu </td>
                                          <td></td>
                                          <td> {value[0].rendu.toFixed(3)} </td>
                                        </tr>
                                      ) : null
                                  )}
                                </> */}
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                        <ul className="pretty-list">
                          {historiqueFideliteClient &&
                            historiqueFideliteClient[selectedTicket._id] &&
                            historiqueFideliteClient[selectedTicket._id].map(
                              (item, index) => (
                                <li key={index}>
                                  <span className="icon">+</span>
                                  {item.points +
                                    " points - " +
                                    item.program_fidelite.libelle}
                                </li>
                              )
                            )}
                        </ul>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FicheClient;
