import React, { useEffect, useState } from "react";

export default function DepotsTable({
  t,
  listDepots,
  listOrganisations,
  deletedepots,
  setdepot,
  setShowModelDepots,
}: any) {
  const [list_DepotsFiltrer, setlist_DepotsFiltrer] = useState([]);
  const [list_Depots, setlist_Depots] = useState([]);

  useEffect(() => {
    const updatedDepots = listDepots.map((depot: any) => {
      if (depot.organisation_id) {
        const organisation = listOrganisations.find(
          (org: any) => org._id === depot.organisation_id
        );
        if (organisation) {
          return {
            ...depot,
            organisation_nom: organisation.nom,
          };
        }
      }
      return depot;
    });

    setlist_Depots(updatedDepots);
    setlist_DepotsFiltrer(updatedDepots);
  }, [listDepots, listOrganisations]);

  const handelsearch = (keyword: string) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const listFiltrer = list_Depots.filter(
      (item: any) =>
        item.libelle.toLowerCase().includes(lowercaseKeyword) ||
        item.organisation_nom?.toLowerCase().includes(lowercaseKeyword)
    );

    setlist_DepotsFiltrer(listFiltrer);
    if (keyword === "") {
      setlist_DepotsFiltrer(list_Depots);
    }
  };
  return (
    <>
      <div className="col-md-3">
        <input
          type="text"
          className="wd-300 form-control product-search br-30 "
          id="input-search"
          placeholder={t("chercher par mots cle")}
          onChange={(e) => handelsearch(e.target.value)}
        />
      </div>
      <table className="table ">
        <thead>
          <tr>
            <th>{t("libelle")}</th>
            <th>{t("defaut")}</th>
            <th>{t("Organisation")}</th>
            <th className="text-center">{t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {list_DepotsFiltrer.map((item: any) => (
            <tr key={item._id}>
              <td> {item.libelle}</td>
              <td>
                {" "}
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    id="custom-switch"
                    className="form-check-input"
                    checked={item.defaut}
                  />
                </div>
              </td>

              <td>{item.organisation_nom}</td>

              <td className="text-center">
                {!item.article_defectueux && !item.defaut && (
                  <span
                    style={{
                      cursor: "pointer",
                      fontSize: "1.5em",
                      marginRight: "10px",
                    }}
                    onClick={() => deletedepots(item._id)}
                  >
                    {" "}
                    <i
                      className="fas fa-trash-alt"
                      style={{ color: "#FF0000" }}
                    ></i>
                  </span>
                )}

                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5em",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setdepot(item);
                    setShowModelDepots(true);
                  }}
                >
                  <i className="fas fa-edit" style={{ color: "#06958d" }}></i>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
