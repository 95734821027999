import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  ConditionAchatType,
  ConditionClientType,
  ConditionProduitType,
  ProgramFideliteType,
} from "../../_App/Types/Entites/RemiseType";
import { createFideliteProgramApi, updateFideliteApi } from "../../_App/Redux/Slices/client/clientSlice";
import ConditionForms from "../Fidelite&RemiseConditionsForms/ConditionForms";
import {
  createConditionAchat,
  createConditionClient,
  createConditionProduit,
} from "../../_App/Redux/Slices/remise/remiseSlice";
import { formatdate } from "../../_App/Helpers/helpers";
export default function ProgramFideliteForm({
  ShowModal,
  setShowModal,
  programFidelite,
  verifcationMsg,
}: any) {
  const today = new Date();
  const NextMonth = new Date(today);
  NextMonth.setMonth(today.getMonth() + 1); 

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentOrg = useAppSelector(SelectedOrganisationId);

  const [conditionClient, setConditionClient] =
    useState<ConditionClientType | null>(null);
  const [conditionAchat, setConditionAchat] =
    useState<ConditionAchatType | null>(null);
  const [conditionProduit, setConditionProduit] =
    useState<ConditionProduitType | null>(null);

  // init form hooks

  const { register, handleSubmit, reset, formState } =
    useForm<ProgramFideliteType>();
  const { errors } = formState;

  useEffect(() => {
    if (programFidelite) {
      reset({
        _id: programFidelite._id,
        actif: programFidelite.actif,
        date_debut:formatdate(programFidelite.date_debut) ,
        date_fin: formatdate(programFidelite.date_fin),
        libelle: programFidelite.libelle,
        points: programFidelite.points,
        equivalentMoney: programFidelite.equivalentMoney,
        organisation_id: programFidelite.organisation_id,
        conditions:programFidelite.conditions,

      });
    } else {
      reset({
        libelle: "",
        actif: true,
        date_debut: formatdate(today),
        date_fin: formatdate(NextMonth),
        points: 0,
        organisation_id: "",
        conditions: "",
        equivalentMoney: 0,
      });
    }
  }, [programFidelite]);
  const onSubmit = async (data: any) => {  
    let response;

    data.organisation_id = currentOrg;
    if (data._id) {
      if (await verifcationMsg(t("vous voulez modifier le programme fidelité")))
      {
        const obj = {
          fidelite:data ,
          conditionProduit:{...conditionProduit,fidelite_id:data._id},
          conditionClient:{...conditionClient,fidelite_id:data._id},
          conditionAchat:{...conditionAchat,fidelite_id:data._id},
  
        }
        response = await dispatch(updateFideliteApi(obj));
      } 
    } else {
      if (
        await verifcationMsg(t("vous voulez ajouter un programme fidelité"))
      ) {

        const response = await dispatch(createFideliteProgramApi(data));
        if (response.payload.success) {
          setShowModal(false);
          if (conditionAchat) {
            conditionAchat.fidelite_id = response.payload.data._id;
            dispatch(createConditionAchat(conditionAchat));
          }
          if (conditionClient) {
            conditionClient.fidelite_id = response.payload.data._id;
            dispatch(createConditionClient(conditionClient));
          }
          if (conditionProduit) {
            conditionProduit.fidelite_id = response.payload.data._id;
            dispatch(createConditionProduit(conditionProduit));
          }
        }
      }
    }
    if (response) {
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          libelle: "",
          actif: true,
          date_debut: new Date(),
          date_fin: new Date(),
          points: 0,
          organisation_id: "",
        });
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-1"
      dialogClassName="modal-50w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {" "}
          {t("Ajouter un programme fidelité")}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="fidelite mb-5">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Libelle")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="libelle"
                    {...register("libelle")}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Points")}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="points"
                    {...register("points")}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Valeur en argent")}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="valeur"
                    {...register("equivalentMoney")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Date début")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    style={{
                      background: "#f1f2f3",
                      border: "none",
                      width: "100%",
                      height: "auto",
                    }}
                    id="datepicker"
                    {...register("date_debut")}
                  />{" "}
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Date fin")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    style={{
                      background: "#f1f2f3",
                      border: "none",
                      width: "100%",
                      height: "auto",
                    }}
                    id="datepicker"
                    {...register("date_fin")}
                  />{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    Et <span style={{ color: "red" }}></span>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="et"
                    {...register("conditions")}
                    value={"et"}
                  />
                  <label htmlFor="ou">
                    Ou <span style={{ color: "red" }}></span>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="ou"
                    value={"ou"}
                    {...register("conditions")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="conditions">
            <ConditionForms
              type={"fidelite"}
              setConditionAchat={setConditionAchat}
              setConditionClient={setConditionClient}
              setConditionProduit={setConditionProduit}
              programFidelite={programFidelite}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            className="btn btn-primary-app"
            onClick={(e) => setShowModal(false)}
          >
            {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
