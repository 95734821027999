import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import store, { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";

interface UpdateOrderParams {
  orderId?: string;
  status: string | null;
  paidProducts?: any;
  vendeur: String | null;
}


/**
 * list  of rapports
 * @public
 */
export const findRapportAchatVenteApi = createAsyncThunk(
  "rapport/findRapportAchatVenteApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `rapport/findRapportAchatVenteApi`,data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


interface rapportState {
    listrapport: any[];
   
  }
  
  const initialState: rapportState = {
    listrapport: [],
   
  };
  
  export const rapportSlice = createSlice({
    name: "rapports",
    initialState,
    reducers: {
    
    },
    extraReducers: (builder) => {
      builder.addCase(findRapportAchatVenteApi.fulfilled, (state, action) => {
        console.log("action.payload;",action.payload)
        state.listrapport=action.payload;
      });

    },
  });
  
 
  
  export const Listrapport = (state: RootState) => state.rapport.listrapport;
 
  export default rapportSlice.reducer;
  
;
  