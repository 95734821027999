import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios, { AxiosResponse } from "axios";
// import _map from "lodash/map";
import _ from "lodash";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";
import { stat } from "fs";
;


/**
 * Get list of Mouvement of article.
 * @public
 */
export const mouvementArticleApi = createAsyncThunk(
  "mouvement/mouvementArticleApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`mouvement/mouvementArticleApi/`,data);
      if(response.data.data !== null){
        return response.data;

      }else{
        ToastWarning( response.data.message)

        throw Error;
     
      }
      
      
    } catch (error: any) {
      
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of Mouvement of article.
 * @public
 */
export const mouvementVenteArticleApi = createAsyncThunk(
  "mouvement/mouvementVenteArticleApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`mouvement/mouvementVenteArticleApi/`,data);
      if(response.data.data !== null){
        return response.data;
        

      }else{
        ToastWarning( response.data.message)
        throw Error;
     
      }
      
      
    } catch (error: any) {
      
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of Mouvement of article.
 * @public
 */
export const findActionApi = createAsyncThunk(
  "mouvement/findActionApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`mouvement/findActionApi/${id}`);
      if(response.data.data !== null){
        return response.data;

      }else{
        ToastWarning( response.data.message)

        throw Error;
     
      }
      
      
    } catch (error: any) {
      
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface MouvementState {
 
  listMouvement:any[];
  listMouvementCaisse:any[];

  totalPages:any
  currentpage:any
  totaux:any
  count:any

}
const initialState: MouvementState = {
  listMouvementCaisse:[],
  listMouvement:[],
  totalPages:null,
  currentpage:null,
  totaux:null,
  count:null

};

export const MouvementSlice = createSlice({
  name: "mouvement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    
   
    builder.addCase(  mouvementArticleApi.fulfilled,   (state, action) => {
        state.listMouvement = action.payload.data.data;
        state.totalPages=action.payload.data.totalPages
        state.currentpage=action.payload.data.currentPage
      }
    );
    builder.addCase(  mouvementVenteArticleApi.fulfilled,   (state, action) => {
      if(!action.payload.data.exportdata){
        state.listMouvementCaisse = action.payload.data.list;
        state.totalPages=action.payload.data.totalPages
        state.currentpage=action.payload.data.currentPage
        state.totaux= action.payload.data.totaux;
        state.count =action.payload.data.count;
      }
     
    }
  );
  },
});

export const currentpage = (state: RootState) => state.mouvement.currentpage;
export const totalPages = (state: RootState) => state.mouvement.totalPages;
export const ListMouvement = (state: RootState) => state.mouvement.listMouvement;
export const ListMouvementCaisse = (state: RootState) => state.mouvement.listMouvementCaisse;
export const Totaux = (state: RootState) => state.mouvement.totaux;
export const Count = (state: RootState) => state.mouvement.count;

export default MouvementSlice.reducer;
