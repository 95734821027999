import React from 'react'
import { useAppSelector } from '../../_App/Redux/hooks'
import { Listrapport } from '../../_App/Redux/Slices/rapport/rapportSlice'

export default function RapportVenteAchat() {
  const listrapport = useAppSelector(Listrapport)
  return (
    <div>
        <table className="table">
  <thead>
    <tr>
      <th>Article</th>
      <th>Famille</th>
      <th> Quantité achetée</th>
      <th>Prix Achat unitaire(last achat)</th>
      <th>Prix Achat total</th>
      <th>Quantité Vendue</th>
      <th>Prix Vente Unitaire</th>
      <th>Prix Vente total</th>
      <th>Quantité restante</th>
      <th>Rapport Achat vente</th>

    </tr>
  </thead>
  <tbody>
    {listrapport&&listrapport.map((item:any , index:number) =>(
      <tr key={index}>
        <td >{item?.article?.titre?.fr}</td>
        <td>{item?.article?.souscategorie?.libelle?.fr}</td>
        <td>{item?.qteAchat}</td>
        <td>{item?.prixAchat}</td>
        <td>{((item?.AchatTT)||0).toFixed(2)}</td>
        <td>{item?.qteVente}</td>
        <td>{(((item?.prixVente)/item?.qteVente )||0).toFixed(2)}</td>
        <td>{item?.prixVente}</td>
        <td>{item.qteAchat -item.qteVente}</td>
        <td>{(((item?.AchatTT)||0)/(item?.prixVente)||0).toFixed(2)}</td>

      </tr>
    ))}
   
  </tbody>
</table>
      
    </div>
  )
}
