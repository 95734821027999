import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListJournaux,
  getAllJournalCaisses,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import SearchableDropdown from "../../_Layouts/RightBar/RightBar/cart/clientsTest";
import Select, { MultiValue } from "react-select";
import { OrganisationType } from "../../_App/Types/Entites/OrganisationType";
import { Interface } from "readline";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import {
  findUsersApi,
  ListUsers,
} from "../../_App/Redux/Slices/users/userSlice";
import { formatdate } from "../../_App/Helpers/helpers";
import { Spinner } from "react-bootstrap";
import AllStatistique from "../../Components/JournalCaisse/AllStatistique";

const JournauxCaisse = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  interface FiltreInterface {
    listOrganisation: OrganisationType[];
    dateDebut: any;
    dateFin: any;
    listcaissiers: UserDataType[];
    etat: string;
  }
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const listJournaux = useAppSelector(ListJournaux);
  const listOrganisation = useAppSelector(ListOrganisations);
  const societeid = useAppSelector(societeId);
  const listusers = useAppSelector(ListUsers);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [stateShow, setStateShow] = useState(false);
  const [show, setShow] = useState("");
  const [loader, setloader] = useState(false);
  const [Filtre, setFiltre] = useState<FiltreInterface>({
    listOrganisation: [],
    dateDebut: formatdate(lastMonth),
    dateFin: formatdate(tomorrow),
    listcaissiers: [],
    etat: "Tout",
  });

  const [showStatistiqueModal, setShowStatistiqueModal] =
    useState<boolean>(false);
  const [selectedJournalId, setSelectedJournalId] = useState<string | null>(
    null
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listJournaux.slice(indexOfFirstItem, indexOfLastItem);

  const OrganisationOptions = listOrganisation?.map((option) => ({
    value: option._id,
    label: option.nom,
  }));
  const UsersOptions = listusers?.map((option) => ({
    value: option._id,
    label: option.nom + " " + option.prenom,
  }));

  useEffect(() => {
    setloader(true);
    dispatch(getAllJournalCaisses(Filtre));
  }, []);
  useEffect(() => {
    if (societeid) {
      dispatch(allOrganisationsApi(societeid));
    }
  }, [dispatch, societeid]);

  useEffect(() => {
    const data = { societe: societeid, organisation: Filtre.listOrganisation };
    dispatch(findUsersApi(data));
  }, [Filtre.listOrganisation]);

  useEffect(() => {
    setloader(false);
  }, [listJournaux]);
  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };

  const handleSelectChangeOrganisation = (selectedOrganisations: any) => {
    const copie = { ...Filtre };
    copie.listOrganisation = selectedOrganisations.map(
      (item: any) => item.value
    );
    setFiltre(copie);
  };
  const handleSelectChangeUsers = (selectedUsers: any) => {
    const copie = { ...Filtre };
    copie.listcaissiers = selectedUsers.map((item: any) => item.value);
    setFiltre(copie);
  };
  const GetJournaux = () => {
    setloader(true);
    dispatch(getAllJournalCaisses(Filtre));
  };
  const handleStatistiqueButtonClick = (journalId: any) => {
    setSelectedJournalId(journalId);
    setShowStatistiqueModal(true);
  };

  const getCodeJournalById = (journalId: string): string | null => {
    const foundJournal = listJournaux.find(
      (journal) => journal._id === journalId
    );
    return foundJournal ? foundJournal.code_journal || null : null;
  };

  return (
    <div className="row layout-spacing">
      <div className="col-lg-12">
        <div className="statbox widget box box-shadow">
          <div className="navbar-item flex-row search-ul navbar-dropdown">
            <div className="nav-item dropdown language-dropdown more-dropdown">
              {" "}
              <label>Date Debut</label>
              <input
                type="date"
                className="form-control row"
                onChange={(e) => {
                  setFiltre((prev) => ({
                    ...prev,
                    dateDebut: e.target.value,
                  }));
                }}
                value={Filtre.dateDebut}
              />
            </div>
            <div className="nav-item dropdown language-dropdown more-dropdown">
              <label>Date Fin</label>

              <input
                type="date"
                className="form-control row"
                onChange={(e) => {
                  setFiltre((prev) => ({
                    ...prev,
                    dateFin: e.target.value,
                  }));
                }}
                value={Filtre.dateFin}
              />
            </div>
            {/*******************************Organisation  ***********************/}
            <div className="nav-item dropdown language-dropdown more-dropdown">
              <label>Organisations</label>
              <Select
                isMulti
                options={OrganisationOptions}
                isSearchable={true}
                onChange={(newSelectedOptions) =>
                  handleSelectChangeOrganisation(newSelectedOptions)
                }
              />
            </div>
            {/*******************************Caissier  ***********************/}

            <div className="nav-item dropdown language-dropdown more-dropdown">
              <label>Caissier</label>
              <Select
                isMulti
                options={UsersOptions}
                isSearchable={true}
                onChange={(newSelectedOptions) =>
                  handleSelectChangeUsers(newSelectedOptions)
                }
              />
            </div>

            <div
              className="nav-item dropdown language-dropdown more-dropdown"
              onClick={open}
              style={{
                display: "flex",
                justifyContent: "right",
                float: "right",
              }}
            >
              <div
                className={"dropdown custom-dropdown-icon " + show}
                style={{ cursor: "pointer" }}
              >
                <a
                  className="dropdown-toggle btn"
                  href="#"
                  role="button"
                  id="customDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={stateShow}
                >
                  <span> {Filtre.etat}</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </a>
                <div
                  className={"dropdown-menu dropdown-menu-right " + show}
                  aria-labelledby="customDropdown"
                >
                  <div
                    className="dropdown-item"
                    data-img-value="flag-de3"
                    data-value="Tout"
                    onClick={() => {
                      setFiltre((prev) => ({
                        ...prev,
                        etat: "Tout",
                      }));
                    }}
                  >
                    {t("Tout")}
                  </div>

                  <div
                    className="dropdown-item"
                    data-img-value="flag-ca2"
                    data-value="Cloturé"
                    onClick={() => {
                      setFiltre((prev) => ({
                        ...prev,
                        etat: "Cloturé",
                      }));
                    }}
                  >
                    {t("Cloturé")}
                  </div>
                  <div
                    className="dropdown-item"
                    data-img-value="flag-ca2"
                    data-value="Non cloturé"
                    onClick={() => {
                      setFiltre((prev) => ({
                        ...prev,
                        etat: "Non cloturé",
                      }));
                    }}
                  >
                    {t("Non cloturé")}
                  </div>
                  <div
                    className="dropdown-item"
                    data-img-value="flag-ca2"
                    data-value="Validé"
                    onClick={() => {
                      setFiltre((prev) => ({
                        ...prev,
                        etat: "Validé",
                      }));
                    }}
                  >
                    {t("Validé")}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="nav-item dropdown language-dropdown more-dropdown"
              style={{ fontSize: "30px" }}
              onClick={GetJournaux}
            >
              <i className="fas fa-search"></i>
            </div>
          </div>
          <div
            className="widget-content widget-content-area"
            style={{ overflowX: "auto" }}
          >
            {loader ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <table
                id="style-2"
                className="table style-2 table-hover"
                onClick={(e) => console.log(listJournaux)}
              >
                <thead>
                  <tr>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      Code Journal
                    </th>
                    <th style={{ fontSize: "15px" }}>Date</th>
                    <th style={{ fontSize: "15px" }}>Utilisateur</th>
                    <th style={{ fontSize: "15px" }}>Fond initial</th>
                    <th style={{ fontSize: "15px" }}>Fond final</th>
                    <th style={{ fontSize: "15px" }}>Cloturé</th>
                    <th style={{ fontSize: "15px" }}>Validé</th>
                    <th style={{ fontSize: "15px" }}>Validateur</th>

                    <th style={{ fontSize: "15px" }}>Ecart</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems &&
                    currentItems.map((item) => (
                      <tr
                        key={item._id}
                      
                      >
                        <td
                          className="checkbox-column lien-bleu-souligne "
                          style={{ fontSize: "15px" }}
                          onClick={(e) =>
                            (window.location.href =
                              "/mouvementcaisse/" + item._id)
                          }
                        >
                          {item.code_journal}
                        </td>
                        <td style={{ fontSize: "15px" }}>{item.date}</td>
                        <td style={{ fontSize: "15px" }}>
                          {item.user_id?.nom}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {item.font_initial}
                        </td>
                        <td style={{ fontSize: "15px" }}>{item.font_final}</td>
                        <td style={{ fontSize: "15px" }}>
                          {item.cloture ? "oui" : "non"}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          <div
                            className={
                              item.valide
                                ? "badge btn-success"
                                : "badge btn-danger"
                            }
                            style={{ width: "100%", fontSize: "14px" }}
                          >
                            {" "}
                            {item.valide ? "Validé" : "Non validé"}
                          </div>
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {item.Validator?.nom}
                        </td>
                        <td style={{ fontSize: "15px" }}>{item.ecart}</td>
                        <td>
                          <button
                            className="btn btn-primary-app"
                            onClick={() =>
                              handleStatistiqueButtonClick(item._id)
                            }
                          >
                            {t("voir statistique")}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
          <ul className="pagination" style={{ justifyContent: "center" }}>
            {Array.from(
              {
                length: Math.ceil(listJournaux.length / itemsPerPage),
              },
              (_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                  style={{ backgroundColor: "transparent" }}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
        <AllStatistique
          onClose={() => {
            setShowStatistiqueModal(false);
            setSelectedJournalId(null);
          }}
          journalId={selectedJournalId ?? ""}
          showModal={showStatistiqueModal}
          getCodeJournalById={getCodeJournalById}
        />
      </div>
    </div>
  );
};

export default JournauxCaisse;
