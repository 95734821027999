import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Listreceptions_articles,
  coloturerReceptionbysocieteApi,
  confirmerReceptionbysocieteApi,
  findArticlereceptionApi,
  findReceptionbysocieteApi,
} from "../../_App/Redux/Slices/receptionArticles/receptionArticlesSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ReceptionArticlesType } from "../../_App/Types/Entites/ReceptionArticles/ReceptionArticlesType";
import TicketArticle from "../TicketArticle/TicketArticle";
import BonSortie from "./BonSortie";

export default function ReceptionTable({
  t,
  societeId,
  setshowReception,
  setreception,
  reception,
  setshowModelTicket,
  showModelTicket,
  setlistTicket,
  listTicket,
}: any) {
  const dispatch = useAppDispatch();
  const listReception = useAppSelector(Listreceptions_articles);
  const [showModalBonSortie, setshowModalBonSortie] = useState(false);
  const [dataBonSortie, setdataBonSortie] = useState(null);

  useEffect(() => {
    if (societeId) {
      dispatch(findReceptionbysocieteApi(societeId));
    }
  }, [dispatch, societeId]);

  const confirmer = async (id: string) => {
    const data = { reception_id: id };
    const response = await dispatch(confirmerReceptionbysocieteApi(data));
    if (response?.payload.success) {
      ToastSuccess(response?.payload.message);
      window.location.reload();
    } else {
      ToastWarning(response?.payload.message);
    }
  };

  const findArticleReception = async (reception: ReceptionArticlesType) => {
    const response = await dispatch(findArticlereceptionApi(reception._id));

    const listticket = response.payload.map((element: any) => {
      return {
        marque: element.souscategorie?.libelle?.fr,
        ref: element.articleparent?.code_article,
        taille: element.caracteristiques[0]?.value,
        couleur: element.caracteristiques[1]?.value?.libelle,
        prix: element?.prix_vente_ttc,
        coderecption: reception?.code_reception,
        code_a_barre: element?.code_a_barre[0],
        qte: element?.qte,
        solde: element?.solde,
      };
    });

    setlistTicket(listticket);
    setshowModelTicket(true);
  };
  return (
    <div className="col-lg-12  mt-5   bg-white">
      <div className="inv--product-table-section ">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>{t("Action")}</th>
                <th>{t("etat")}</th>
                <th> {t("Code Reception")}</th>

                <th>{t("Organisation")}</th>
                <th>{t("Depots")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listReception.length !== 0 &&
                listReception.map((item: any, index: number) => (
                  <tr key={item._id}>
                    {!item.confirmer ? (
                      <td
                        className="c-primary pointer"
                        onClick={() => confirmer(item._id)}
                      >
                        confirmer
                      </td>
                    ) : !item.cloturer ? (
                      <td className="c-primary pointer">cloturer</td>
                    ) : (
                      <td
                        className="c-primary pointer"
                        onClick={() => findArticleReception(item)}
                      >
                        Imprimer tickets
                      </td>
                    )}

                    {!item.cloturer ? (
                      !item.confirmer ? (
                        <td>en cours</td>
                      ) : (
                        <td className="color-red">confirmé</td>
                      )
                    ) : (
                      <td className="color-red">cloturé</td>
                    )}
                    <td
                      className="lien-bleu-souligne"
                      onClick={() => {
                        setreception(item);
                        setshowReception(true);
                      }}
                    >
                      {item?.code_reception}
                    </td>

                    <td>{item?.organisation_id?.nom}</td>
                    <td>{item?.depot_id?.libelle}</td>
                    { item.bonSorties &&  item.bonSorties !== null &&Object.keys(item.bonSorties)&&Object.keys(item.bonSorties).length!== 0&& (
                      <td
                        className="lien-bleu-souligne"
                        onClick={() => {

                          setdataBonSortie(item);
                          setshowModalBonSortie(true);
                        }}
                      >
                        Bon Sortie
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <BonSortie
        ShowModal={showModalBonSortie}
        setShowModal={setshowModalBonSortie}
        reception={dataBonSortie}
        t={t}
      />
    </div>
  );
}
