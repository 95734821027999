import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import ClientType, {
  HistoriqueFideliteType,
  NiveauFideliteType,
  ZoneType,
} from "../../../Types/Entites/ClientType";

import _ from "lodash";
import { ProgramFideliteType } from "../../../Types/Entites/RemiseType";
/**
 * create clients function
 * @private
 */
export const createClientsApi = createAsyncThunk(
  "client/createClientsApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("client/createClientsApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * create clients function
 * @private
 */
export const createFideliteProgramApi = createAsyncThunk(
  "remises/saveFideliteProg",
  async (data: ProgramFideliteType, { rejectWithValue }) => {
    try {
      const response = await axios.post("remises/saveFideliteProg", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const updateFideliteApi = createAsyncThunk(
  "remises/updateFideliteApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("remises/updateFideliteApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of clients.
 * @public
 */
export const findClientBySocieteApi = createAsyncThunk(
  "client/findClientBySocieteApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`client/findClientBySocieteApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of clients.
 * @public
 */
export const findNiveauxFidelite = createAsyncThunk(
  "client/findNiveauxFidelite",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`client/findNiveauxFidelite/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of clients.
 * @public
 */
export const findZonesByOrganisation = createAsyncThunk(
  "client/findZones",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`client/findZones/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of clients.
 * @public
 */
export const findFidelitePrograms = createAsyncThunk(
  "remises/findProgFidelite",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`remises/findProgFidelite/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * update tables function
 * @private
 */
export const updateClientApi = createAsyncThunk(
  "client/updateClientApi",
  async (data: Partial<ClientType>, { rejectWithValue }) => {
    try {
      const response = await axios.put("client/updateClientApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * desactive client function
 * @private
 */
export const desactiveClientApi = createAsyncThunk(
  "client/desactiveClientApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.put(`client/desactiveClientApi/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * active client function
 * @private
 */
export const activeClientApi = createAsyncThunk(
  "client/activeClientApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.put(`client/activeClientApi/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * active client function
 * @private
 */
export const findClientByBarcode = createAsyncThunk(
  "client/findClientByBarcode",
  async (barcode: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`client/findClientByBarcode/${barcode}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * active client function
 * @private
 */
export const findClientById = createAsyncThunk(
  "client/findClientById",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`client/findClientById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

// *************************************** FIDELITE *************************************************

export const createProgramFidelite = createAsyncThunk(
  "fidelite/saveFideliteProg",
  async (data: ClientType, { rejectWithValue }) => {
    try {
      const response = await axios.post("fidelite/saveFideliteProg", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const createHistoriqueFidelite = createAsyncThunk(
  "client/saveHistory",
  async (data: HistoriqueFideliteType, { rejectWithValue }) => {
    try {
      const response = await axios.post("client/saveHistory", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * active client function
 * @private
 */
export const findClientHistoriqueFidelite = createAsyncThunk(
  "client/findClientHistorique",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`client/findClientHistorique/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
interface ClientState {
  listClients: ClientType[];
  ListClientsGroupedByBarcode: { [barcode: string]: ClientType[] };
  listFidelitePrograms: ProgramFideliteType[];
  ListNiveauxFidelite: NiveauFideliteType[];
  listZones: ZoneType[];
  historiqueFideliteByOrder: { [order: string]: HistoriqueFideliteType[] };
}

const initialState: ClientState = {
  listClients: [],
  ListClientsGroupedByBarcode: {},
  listFidelitePrograms: [],
  ListNiveauxFidelite: [],
  listZones: [],
  historiqueFideliteByOrder: {},
};

export const ClientSlice = createSlice({
  name: "clientSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createClientsApi.fulfilled, (state, action) => {
      state.listClients.splice(0, 0, action.payload.data);
    });

    builder.addCase(findClientBySocieteApi.fulfilled, (state, action) => {
      const groupedByBarcode = _.groupBy(action.payload, "barCode");

      return {
        ...state,
        listClients: action.payload,
        ListClientsGroupedByBarcode: groupedByBarcode,
      };
    });
    builder.addCase(findClientHistoriqueFidelite.fulfilled, (state, action) => {
      const groupedByOrder = _.groupBy(action.payload, "order_id");

      return {
        ...state,
        historiqueFideliteByOrder: groupedByOrder,
      };
    });

    builder.addCase(findNiveauxFidelite.fulfilled, (state, action) => {
      return {
        ...state,
        ListNiveauxFidelite: action.payload,
      };
    });
    builder.addCase(findZonesByOrganisation.fulfilled, (state, action) => {
      return {
        ...state,
        listZones: action.payload,
      };
    });
    builder.addCase(findFidelitePrograms.fulfilled, (state, action) => {
      return {
        ...state,
        listFidelitePrograms: action.payload,
      };
    });

    builder.addCase(updateFideliteApi.fulfilled, (state, action) => {
      const findindex = state.listFidelitePrograms.findIndex(item=>item._id === action.payload.data.updatefidelite._id)
      if(findindex!== -1){
        
        state.listFidelitePrograms[findindex]=action.payload.data.updatefidelite
      }
    });

    builder.addCase(updateClientApi.fulfilled, (state, action) => {
      const updatedClient = action.payload.data;
      const index = state.listClients.findIndex(
        (client) => client._id === updatedClient._id
      );

      if (index !== -1) {
        state.listClients[index] = updatedClient;

      }
    });

    builder.addCase(desactiveClientApi.fulfilled, (state, action) => {
      const updatedClient = action.payload.data;
      const index = state.listClients.findIndex(
        (client) => client._id === updatedClient._id
      );
      if (index !== -1) {
        state.listClients[index] = updatedClient;
      }
    });
    builder.addCase(activeClientApi.fulfilled, (state, action) => {
      const updatedClient = action.payload.data;
      const index = state.listClients.findIndex(
        (client) => client._id === updatedClient._id
      );

      if (index !== -1) {
        state.listClients[index] = updatedClient;
      }
    });
  },
});

export const ListClient = (state: RootState) => state.clients.listClients;
export const ListZones = (state: RootState) => state.clients.listZones;

export const ListNiveauxFidelite = (state: RootState) =>
  state.clients.ListNiveauxFidelite;

export const ListProgramFidelite = (state: RootState) =>
  state.clients.listFidelitePrograms;

export const ListClientBuBarcode = (state: RootState) =>
  state.clients.ListClientsGroupedByBarcode;

export const historiqueFidelite = (state: RootState) =>
  state.clients.historiqueFideliteByOrder;

export default ClientSlice.reducer;
