import React, { useEffect, useState, useTransition } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";
import {
  findBonByBarcode,
  ListBonsReduction,
  saveBonReductionApi,
} from "../../../_App/Redux/Slices/reduction/reductionSlice";
import { setReduction } from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { formadate } from "../../../_App/Helpers/helpers";
import BonReductionType from "../../../_App/Types/Entites/ReductionType";
import BonDeReductionTicket from "../TicketDeCaisse/BonDeReduction";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";

const ReductionModal = ({ ShowModal, setShowModal }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const parametrage = useAppSelector(parametrageCaisse);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [ShowModalTicket, setShowModalTicket] = useState(false);

  const [code, setCode] = useState<any>("");
  const [idbon, setIdBon] = useState();

  const bonsReduction = useAppSelector(ListBonsReduction);

  const [amount, setAmount] = useState<string>();

  const handleFindBon = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const resp = await dispatch(findBonByBarcode(code));
    const bonsReduction = resp.payload;
    const today = new Date();

    if (bonsReduction && bonsReduction) {
      const expireDate = new Date(bonsReduction.dateEcheance);

      const expireDateLocal = new Date(
        expireDate.getTime() + expireDate.getTimezoneOffset() * 60000
      );
      if (expireDateLocal < today) {
        ToastWarning("Ce bon de réduction est expiré !");
      } else {
        if (!bonsReduction.valide) {
          ToastWarning("Ce bon est déjà utilisé!");
          setShowModal(false);
        } else {
          dispatch(
            setReduction({
              amount: Number(bonsReduction.amount.toFixed(3)),
              bon: bonsReduction,
            })
          );
          setShowModal(false);
        }
      }
    } else {
      ToastWarning("Bon de réduction introuvable !");
    }
  };

  function generateCode(): string {
    const timestamp = Date.now();
    const uniqueId = timestamp % 1000000;
    return uniqueId.toString().padStart(6, "0");
  }

  const saveBonReduction = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const today = new Date();

    // Create a new date that is 2 weeks later
    const twoWeeksLater = new Date(today);
    twoWeeksLater.setDate(today.getDate() + Number(parametrage.duree_bon));
    let data: BonReductionType = {
      date: today,
      amount: Number(amount),
      dateEcheance: twoWeeksLater,
      depot_id: currentDepot._id,
      code: generateCode(),
      type: "Bon de réduction",
    };
    const res = await dispatch(saveBonReductionApi(data));
    if (res.payload.success) {
      setIdBon(res.payload.data._id);
      setShowModalTicket(true);
    }
  };

  return (
    <>
      <BonDeReductionTicket
        ShowModal={ShowModalTicket}
        setShowModal={setShowModalTicket}
        setShowModalForm={setShowModal}
        Bon_id={idbon}
      />

      <Modal show={ShowModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <span
              className="modal-title"
              id="addCategorieLabel"
              onClick={() => console.log()}
            >
              {" "}
              Bon de réduction
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form onSubmit={saveBonReduction}>
          <Modal.Body>
            <nav id="payment-block">
              <ul className="menu-payments" style={{ padding: "0px 20px" }}>
                <li className="menu">
                  <a
                    href="#uiKit"
                    data-active="false"
                    className="menu-toggle"
                    onClick={() => setSelectedType("don")}
                    style={{
                      backgroundColor:
                        selectedType && selectedType === "don"
                          ? "lightblue" // Change to the desired background color
                          : "",
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-tags"></i>{" "}
                      </div>
                      <span>Donner un bon de réduction</span>
                    </div>
                  </a>
                </li>
                <li className="menu">
                  <a
                    href="#uiKit"
                    data-active="false"
                    className="menu-toggle"
                    onClick={() => setSelectedType("reçu")}
                    style={{
                      backgroundColor:
                        selectedType && selectedType === "reçu"
                          ? "lightblue" // Change to the desired background color
                          : "",
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-tags"></i>{" "}
                      </div>
                      <span>Bon de reduction</span>
                    </div>
                  </a>
                </li>
              </ul>
            </nav>

            <div className="row">
              {selectedType && selectedType === "don" ? (
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="libelle">{t("amount")}</label>
                    <input
                      className="form-control mb-2"
                      type="number"
                      id="montant"
                      onChange={(e) => setAmount(e.currentTarget.value)}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-10">
                    <div className="form-group">
                      <label htmlFor="search">
                        Trouver le bon de réduction
                      </label>
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder="Recherche bon reduction"
                        onChange={(e) => setCode(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn mt-2"
                      onClick={(e) => handleFindBon(e)}
                    >
                      {" "}
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => {
                setShowModal(false);
              }}
              className="btn btn-primary-app"
            >
              Annuler
            </button>
            {selectedType && selectedType === "don" ? (
              <button type="submit" className="btn btn-primary-app">
                Enregistrer
              </button>
            ) : null}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ReductionModal;
