import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ChequePaymentMethod = ({
  total,
  onAmountChange,
  enteredAmountCheque,
  tel,
  setTel,
  num,
  setNumCheque,
  echeance,
  setEcheance,
}: any) => {
  const [givenAmountCheque, setGivenAmountCheque] = useState(
    enteredAmountCheque || 0
  );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value;
    setGivenAmountCheque(isNaN(Number(amount)) ? 0 :amount);
    onAmountChange(isNaN(Number(amount)) ? 0 :Number(amount) ); // Update the parent state
  };

  useEffect(() => {
    setGivenAmountCheque(0);
  }, [total]);

  const { t } = useTranslation();

  return (
    <div className="navbar-item flex-row search-ul navbar-dropdown">
      <div className="nav-item align-self-center search-animated">
        <form className="form-inline search-full form-inline search" role="search">
          <div className="search-bar" style={{ marginBottom: "7px" }}>
            <input
              type="text"
              className="form-control search-form-control  ml-lg-auto"
              placeholder={t("Numéro de cheque")}
              value={num}
              onChange={(e) => setNumCheque(e.target.value)}
            />
          </div>
          <div className="search-bar" style={{ marginBottom: "7px" }}>
            <input
              type="date"
              className="form-control search-form-control  ml-lg-auto"
              placeholder="date d'echeance"
              value={echeance}
              onChange={(e) => setEcheance(e.target.value)}
            />
          </div>
          <div className="search-bar" style={{ marginBottom: "7px" }}>
            <input
              type="text"
              className="form-control search-form-control  ml-lg-auto"
              value={givenAmountCheque !== null ? givenAmountCheque : ""}
              placeholder={t("amount")}
              onChange={handleAmountChange}
            />
          </div>
          <div className="search-bar" style={{ marginBottom: "7px" }}>
            <input
              type="text"
              className="form-control search-form-control  ml-lg-auto"
              placeholder={t("Tél")}
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </div>
        </form>
      </div>
      <hr></hr>
    </div>
  );
};

export default ChequePaymentMethod;
