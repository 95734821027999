import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { User } from "../../../Types/Entites/User";
import axios from "axios";
import { UserDataType } from "../../../Types/Forms/UserDataType";
import _ from "lodash";

export const findVendeurs = createAsyncThunk(
  "user/findvendeurs",
  async (idOrganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`user/findvendeurs/${idOrganisation}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findvendeursBySocieteApi = createAsyncThunk(
  "user/findvendeursBySocieteApi",
  async (idsociete: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `user/findvendeursBySocieteApi/${idsociete}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface VendeurState {
  vendeurs: User[];
  vendeursByCode: { [code: string]: User[] };
}

const initialState: VendeurState = {
  vendeurs: [],
  vendeursByCode: {},
};

export const vendeurSlice = createSlice({
  name: "vendeurs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findVendeurs.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        vendeurs: action.payload,
        vendeursByCode: groupedByCode,
      };
    });
    builder.addCase(findvendeursBySocieteApi.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload, "code");
      return {
        ...state,
        vendeurs: action.payload,
        vendeursByCode: groupedByCode,
      };
    });
  },
});

export const Vendeurs = (state: RootState) => state.vendeurs.vendeurs;
export const VendeursByCode = (state: RootState) => state.vendeurs.vendeursByCode;

export default vendeurSlice.reducer;
