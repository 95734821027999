import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import TransfertArticleType from "../../_App/Types/Entites/TransfertArticle/TransfertArticleType";
import {
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  Listdemande_transfer_cree,
  confirmTransfertApi,
  getlesDemandeTransfertCreeApi,
  retourTransfertApi,
} from "../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import { Modal } from "react-bootstrap";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import BonTransfert from "./files/BonTransfert";
import Accordion from "react-bootstrap/Accordion";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
export default function ListTransfertCree({ ShowModal, setShowModal }: any) {
  const { t } = useTranslation();
  const selectedOrganisationId = useAppSelector(SelectedOrganisationId);
  const list_organisation = useAppSelector(SelectedOrganisations);
  const [etat, setetat] = useState("apprové");
  const [list, setlist] = useState<TransfertArticleType[]>([]);
  const user = useAppSelector(selectUserId);
  const societeid = useAppSelector(societeId);

  const listdemande_transfer_cree = useAppSelector(Listdemande_transfer_cree);
  const dispatch = useAppDispatch();
  const [showbon, setshowbon] = useState(false);
  const [dataBon, setDatataBon] = useState();

  useEffect(() => {
    if (selectedOrganisationId)
      dispatch(getlesDemandeTransfertCreeApi(selectedOrganisationId));
  }, [selectedOrganisationId]);

  useEffect(() => {
    if (etat === "apprové") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) =>
          !!item?.accepter_transfer?.etat &&
          !item?.approver_reception?.etat &&
          item?.approver_reception?.user_id === undefined
      );
      setlist(newlist);
    } else if (etat === "nonapprové") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) => !item.accepter_transfer.etat && item.accepter_transfer.user_id
      );
      setlist(newlist);
    } else if (etat === "encours") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) =>
          !item.accepter_transfer.etat &&
          item.accepter_transfer.user_id === undefined
      );

      setlist(newlist);
    } else if (etat === "confirmé") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) => !!item.approver_reception?.etat
      );

      setlist(newlist);
    } else {
      var newlist = listdemande_transfer_cree?.filter(
        (item) =>
          !item.approver_reception?.etat &&
          item.approver_reception.user_id !== undefined
      );

      setlist(newlist);
    }
  }, [listdemande_transfer_cree, etat]);

  const confirmer = async (idTransfert: string, index: number) => {
    const data = {
      _id: idTransfert,
      user: user,
      societe_id: societeid,
      note: list[index].note,
    };
    const response = await dispatch(confirmTransfertApi(data));
    if (response?.payload.status) {
      ToastSuccess("reception confirmé");
      setDatataBon(response.payload.data);
      setshowbon(true);
      if (selectedOrganisationId)
        dispatch(getlesDemandeTransfertCreeApi(selectedOrganisationId));
    }
  };
  const retour = async (idTransfert: string, index: number) => {
    const data = { _id: idTransfert, user: user, note: list[index].note };
    const response = await dispatch(retourTransfertApi(data));
    if (response?.payload.success) {
      ToastSuccess(response?.payload.message);
      setShowModal(false);
    }
  };
  const ajoutnote = async (index: number, value: string) => {
    const copie = list.map((item, i) =>
      i === index ? { ...item, note: value } : item
    );
    setlist(copie);
  };
  const closenote = async (index: number) => {
    const updatedList = list.map((item, i) =>
      i === index ? { ...item, note: undefined } : item
    );
    setlist(updatedList);
  };
  const voirbon = (item: any) => {
    setDatataBon(item);
    setshowbon(true);
  };
  const [expandedIndex, setExpandedIndex] = useState(0);

  const toggleBody = (index:any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  return (
    <div>
      <div className="row layout-spacing">
        <div className="col-lg-12    bg-white">
          <div className="inv--product-table-section ">
            <div className="row mt-5">
              <div className="col-6">
                <h3 className="c-primary">
                  {" "}
                  {t("Les demandes de Transfert")}{" "}
                </h3>
              </div>
              <div className="col-1 ">
                <span>Etat :</span>
              </div>
              <div className=" col-4 ">
                <select
                  className="form-control"
                  onChange={(e) => setetat(e.target.value)}
                >
                  {" "}
                  <option value="apprové">{t("approuvé")}</option>
                  <option value="confirmé"> {t("réception confirmé")}</option>
                  <option value="encours">{t("encours")}</option>
                  <option value="nonapprové">{t("non approuvé")}</option>
                </select>
              </div>
            </div>

            {list && list.length > 0 && (
              <div className="row">
                <div className="col-1">{t("Organisation  ")} </div>
                <div className="col-2">{t("Depots ")} </div>
                <div className="col-1">{t("Date ")} </div>
                <div className="col-2">{t("Agent de livraison ")} </div>
              </div>
            )}

            <Accordion defaultActiveKey="0">
              {list &&
                list.map((item: any, index: number) => (
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header className="row">
                      <div className="col-1">
                        {item.organisation_transfer.nom}{" "}
                      </div>
                      <div className="col-2">{item.depots_id?.libelle}</div>
                      {etat === "encours" && (
                        <div className="col-2">
                          {item?.demander?.date.split("T")[0]}
                        </div>
                      )}

                      {etat === "apprové" && (
                        <>
                          <div className="col-1">
                            {item?.accepter_transfer?.date?.split("T")[0]}
                          </div>
                          <div className="col-2">
                            {(item?.agentLivraison?.nom || '')+
                              " " +
                              (item?.agentLivraison?.prenom ||'')}
                          </div>
                          <div className="col-2">
                            {item.note !== undefined ? (
                              <>
                                <textarea
                                  value={item.note}
                                  onChange={(e) =>
                                    ajoutnote(index, e.target.value)
                                  }
                                />
                                <i
                                  onClick={() => closenote(index)}
                                  className="far fa-window-close"
                                ></i>
                              </>
                            ) : (
                              <span
                                className=" btn-primary-app  pointer"
                                onClick={() => ajoutnote(index, "")}
                              >
                                <i className="fas fa-plus"></i>
                                {t("Ajouter note ")}
                              </span>
                            )}
                          </div>

                          <div className="col-2">
                            <span
                              className=" btn-primary-app  pointer"
                              onClick={() => confirmer(item._id, index)}
                            >
                              <i className="fas fa-check-double "></i>
                              {t("confirmer reception")}
                            </span>
                          </div>
                          <div className="col-">
                            <span
                              className=" btn-primary-app  pointer"
                              onClick={() => retour(item._id, index)}
                            >
                              <i className="fas fa-undo"></i> {t("retour")}
                            </span>
                           
                          </div>
                          <div className="col-1">
                          <span
                              className=" btn-primary-app pointer"
                              onClick={() => {
                                setDatataBon(item);
                                setshowbon(true);
                              }}
                            >
                              <i className="fas fa-file-powerpoint"></i>{" "}
                              {t("B.R")}
                            </span>
                          </div>
                   
                        </>
                      )}
                      {etat === "confirmé" && (
                        <>
                          <div className="col-1">
                            {item?.approver_reception?.date?.split("T")[0] }
                          </div>
                          <div className="col-2">
                            { (item?.agentLivraison?.nom ||'') +
                              " " +
                             ( item?.agentLivraison?.prenom || '') }
                          </div>
                          <div className="col-3">
                            <span
                              className=" btn-primary-app pointer"
                              onClick={() => {
                                setDatataBon(item);
                                setshowbon(true);
                              }}
                            >
                              <i className="fas fa-file-powerpoint"></i>{" "}
                              {t("Bon de reception")}
                            </span>
                          </div>
                        </>
                      )}
                             <div className="col-1">
                      <button
                        onClick={() => toggleBody(index)}
                        className="btn btn-link c-primary"
                      >
                        {expandedIndex === index ? "▲" : "▼"}
                      </button>
                    </div>
                     
                    </Accordion.Header>
                    {expandedIndex === index && (

                    <Accordion.Body>
                      <div className="row mb-2 bg-primary-app ">
                        <div className="col-3"></div>
                        <div className="col-3">{t("Article")}</div>

                        {etat === "encours" && (
                          <div className="col-3">{t("Quantite demandé")}</div>
                        )}

                        {(etat === "apprové" || etat === "confirmé") && (
                          <>
                            <div className="col-3">{t("Quantite demandé")}</div>
                            <div className="col-3">{t("Quantite envoyé")}</div>
                          </>
                        )}

                        {etat === "nonapprové" && (
                          <>
                            <div className="col-3">{t("Quantite demandé")}</div>
                          </>
                        )}
                      </div>
                      {item.lignes.map((l: any) => (
                        <div className="row">
                          <div className="col-3">
                            {l.article_id?.image &&
                            l.article_id?.image !== null &&
                            l.article_id?.image?.length !== 0 ? (
                              <Zoom>
                                <img
                                  alt="image"
                                  src={
                                    process.env.REACT_APP_API_PUBLIC_URL +
                                    "article/" +
                                    l.article_id?.image
                                  }
                                  width="100"
                                />
                              </Zoom>
                            ) : (
                              <img
                                src="assets/assets/img/no-image.jpg"
                                className="card-img-top"
                                alt="..."
                                style={{ width: "100px" }}
                              />
                            )}
                          </div>
                          <div className="col-3">{l.article_id?.titre?.fr}</div>

                          {etat === "encours" && (
                            <div className="col-3">{l.Quantite}</div>
                          )}
                          {(etat === "apprové" || etat === "confirmé") && (
                            <>
                              <div className="col-3">{l.Quantite}</div>
                              <div className="col-3">{l.Quantiteenvoyee|| l.Quantite}</div>
                            </>
                          )}

                          {etat === "nonapprové" && (
                            <>
                              <div className="col-3">{l.Quantite}</div>
                            </>
                          )}
                        </div>
                      ))}
                    </Accordion.Body>)}
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
      {showbon && (
        <BonTransfert
          societeid={societeid}
          data={dataBon}
          type="reception"
          t={t}
          setshowbon={setshowbon}
        />
      )}
    </div>
  );
}
