import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import {
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";

import Navbar from "../../_Layouts/Navbar/Navbar";
import {
  currentpage,
  findReptureDeStock,
  ReptureStockList,
  totalPages,
} from "../../_App/Redux/Slices/article/articleSlice";
import ReptureStockTable from "../../Components/Caisses/Articles/ReptureStockTable";
import { findDepotsByOrganisationsApi } from "../../_App/Redux/Slices/depot/depotSlice";
import { allOrganisationsApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import FiltreReptureStock from "../../Components/Caisses/Articles/FiltreReptureStock";
import { useTranslation } from "react-i18next";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { exportExcel } from "../../_App/Helpers/helpers";

const ReptureStock = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const societe_id = useAppSelector(societeId);
  const OrganisationConnecte = useAppSelector(SelectedOrganisationId);
  const [index, setindex] = useState(1);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const reptureStockList = useAppSelector(ReptureStockList);
  const [OrganisationSelected, setOrganisationSelected] = useState([
    OrganisationConnecte,
  ]);
  const [spinner, setspinner] = useState(false);

  const [filtre, setfiltre] = useState({
    depotsSelected: [],
    selectedSousCategorie: [],
    min: "",
    article: null,
    index: 1,
    exportdata: false,
  });

  useEffect(() => {
    if (societe_id) {
      dispatch(allOrganisationsApi(societe_id));
    }
  }, [societe_id]);
  useEffect(() => {
    if (OrganisationSelected.length !== 0) {
      const data = { idOrganisations: OrganisationSelected };
      dispatch(findDepotsByOrganisationsApi(data));
    }
  }, [OrganisationSelected]);
  useEffect(() => {
    const copie = { ...filtre };
    copie.index = index;
    setfiltre(copie);
    setspinner(true);
    dispatch(findReptureDeStock(copie));
  }, [index]);

  useEffect(() => {
    setspinner(false);
  }, [reptureStockList]);
  const handlSearch = async () => {
    setspinner(true);
  
    await dispatch(findReptureDeStock(filtre));
  };
  const findExport = async () => {
    const copie = { ...filtre };
    copie.exportdata = true;
    const response = await dispatch(findReptureDeStock(copie));
    const list = response?.payload?.data?.map((item: any) => {
      return {
        organisation: item.organisation[0]?.nom,
        Depots: item.depots[0]?.libelle,
        stock: item.stock,
        famille: item.article?.souscategorie[0]?.libelle?.fr,
        article: item.article?.titre?.fr,
        code_article: item.article?.code_article,
        titre: item.article?.titre?.fr,
        StockMinimale: item.article?.minimalStock,
      };
    });
    if (response?.payload?.data) {
      exportExcel("rupturestock.xlsx", "rupturestock", list);
    }
  };

  return (
    <div>
      <Navbar titre={"Repture de stock"} />

      <div className="d-flex justify-content-end mb-3 mt-5"></div>
      <FiltreReptureStock
        spinner={spinner}
        dispatch={dispatch}
        t={t}
        setOrganisationSelected={setOrganisationSelected}
        OrganisationSelected={OrganisationSelected}
        filtre={filtre}
        setfiltre={setfiltre}
        handlSearch={handlSearch}
      />
      <button onClick={findExport}>excel</button>
      <ReptureStockTable reptures={reptureStockList} t={t} />
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
    </div>
  );
};

export default ReptureStock;
