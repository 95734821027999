import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppDispatch } from "../../../_App/Redux/hooks";
import { updateOrderApi } from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { NextOrderStatus } from "../../../_App/Helpers/helpers";

const OrderStatusPopUp = ({ show, onClose, selectedStatus, order }: any) => {
  const dispatch = useAppDispatch();
  const [newStatus, setNewStatus] = useState(selectedStatus || "");

  useEffect(() => {
    const newstate = NextOrderStatus(selectedStatus)
    setNewStatus(newstate);
  }, [selectedStatus]);

  const saveStatusChange = async () => {
  
    const res = await dispatch(
      updateOrderApi({
        orderId: order.id,
        status: newStatus,
        paidProducts: [],
        vendeur: null,
      })
    );
    if (res.payload.success) {
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Status de la commande </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="statusSelect">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            <option value="En livraison">En livraison</option>
            <option value="Retour">Retour</option>
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="primary" onClick={saveStatusChange}>
          Confirmer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderStatusPopUp;
