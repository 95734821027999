import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import TicketDeCaisse from "../../../Components/Caisses/TicketDeCaisse/TicketDeCaisseModal";
import {
  Bon,
  DefaultClient,
  Panier,
  Reduction,
  addClient,
  changetotal,
  findDefaultClientsBySociete,
  resetOperationType,
  resetPanier,
  resetReduction,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { selectedTable } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  ListOrders,
  PayerReservationApi,
  UpdateOrderdataApi,
  findSoucheOrderApi,
  getOrderByIdApi,
  order,
  resetSelectedOrder,
  saveOrderApi,
  selectOrder,
  selectedOrder,
  updateOrderApi,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  selectedSessionJournal,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { listPaymentOperations } from "../../../_App/Redux/Slices/payments/PaymentSlice";
import { ListMouvementsCaisse } from "../../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import { updateFondCourantApi } from "../../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { updateTableStatusApi } from "../../../_App/Redux/Slices/table/tableSlice";
import {
  UnpaidTicket,
  findTicketsByOrderApi,
  findTicketsByOrganisationApi,
  ordersTickets,
  saveTicketApi,
  updateUserLocally,
} from "../../../_App/Redux/Slices/tickets/TicketSlice";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { updateArticleStockApi } from "../../../_App/Redux/Slices/article/articleSlice";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";
import Select from "react-select";
import {
  Vendeurs,
  findVendeurs,
} from "../../../_App/Redux/Slices/users/vendeurSlice";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import CreditCardPaymentMethod from "../../../Components/Caisses/Payment keyboard/CreditCardPaymentMethod";
import {
  createHistoriqueFidelite,
  findClientByBarcode,
  findClientById,
  ListClient,
  ListProgramFidelite,
  findClientBySocieteApi,
  updateClientApi,
} from "../../../_App/Redux/Slices/client/clientSlice";
import BonDeReductionTicket from "../../../Components/Caisses/TicketDeCaisse/BonDeReduction";
import BonReductionType from "../../../_App/Types/Entites/ReductionType";
import {
  ListBonsReduction,
  saveBonReductionApi,
} from "../../../_App/Redux/Slices/reduction/reductionSlice";
import { ProgramFideliteType } from "../../../_App/Types/Entites/RemiseType";
import ClientType, {
  HistoriqueFideliteType,
} from "../../../_App/Types/Entites/ClientType";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import Reservationpopup from "./Reservationpopup";
import PointsFidleliteMethod from "../../../Components/Caisses/Payment keyboard/PointsFidleliteMethod";
import CashPaymentMethod from "../../../Components/Caisses/Payment/CashPaymentMethod";
import ChequePaymentMethod from "../../../Components/Caisses/Payment/ChequePaymentMethod";
import GiftCardPaymentMethod from "../../../Components/Caisses/Payment/GiftCardPaymentMethod";
import { ChequeType } from "../../../_App/Types/Entites/PaymentType";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import BonReductionMethod from "../../../Components/Caisses/Payment/BonReductionMethod";
import VirementPaymentMethod from "../../../Components/Caisses/Payment/VirementPaymentMethod";
import { findSoucheCommande } from "../../../_App/Helpers/helpers";

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#151516",
    color: "#888ea8",
    borderRadius: "4px",
    border: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#e0f0ef" : "white",
    color: "#888ea8",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#888ea8", // Change this to your desired color
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#888ea8", // Change this to your desired color
  }),

  // Style for the placeholder text
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#888ea8", // Change this to your desired color
  }),
};

interface PaymentRightBarProps {
  visible: boolean;
  setVisible: any;
  ArticlesToPay: any;
  miniOrder: any;
}
interface PaidProduct {
  itemIndex: number;
  paid_quantity: number;
}
const PaymentDrawer: React.FC<PaymentRightBarProps> = ({
  visible,
  setVisible,
  miniOrder,
}) => {
  const dispatch = useAppDispatch();
  //useAppSelector
  const vendeurs = useAppSelector(Vendeurs);
  const cart = useSelector(Panier);
  const reduction = useAppSelector(Reduction);
  const bon = useAppSelector(Bon);
  let tempReduction = reduction;
  const retrievedOrder = useAppSelector(order);
  const currentOrder = useAppSelector(selectedOrder);
  const openedOrder = retrievedOrder ? retrievedOrder : currentOrder;
  const selectedtable = useAppSelector(selectedTable);
  const orders = useAppSelector(ListOrders);
  const unpaidTicket = useAppSelector(UnpaidTicket);
  const mouvementsCaisse = useAppSelector(ListMouvementsCaisse);
  const journal = useAppSelector(selectJournalCaisse);
  const defaultClient = useAppSelector(DefaultClient);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const user = useAppSelector(selectUser);
  const bonsReduction = useAppSelector(ListBonsReduction);
  const societe_id = useAppSelector(societeId);
  const [renduTotal, setrenduTotal] = useState(0);
  //usestate
  //paiements
  const [enteredAmount, setEnteredAmount] = useState<number | 0>(0);

  // cheques
  const [cheques, setCheques] = useState<ChequeType[]>([
    {
      tel: "",
      num_cheque: "",
      echeance: new Date(),
      amount: 0,
      client_id: cart.client_id?.toString(),
      paid: false,
      payment_id: "",
      organisation_id: currentOrganisation,
    },
  ]);
  const [echeance, setEcheance] = useState<Date | null>();
  const [tel, setTel] = useState<string | null>();
  const [numCheque, setNumCheque] = useState<string | null>();
  const [enteredAmountCheque, setEnteredAmountCheque] = useState<number | 0>(0);
  const [enteredAmountCash, setEnteredAmountCash] = useState<number | 0>(0);
  const [BonReductionAmount, setBonReductionAmount] = useState(0); // Initialize with 0 or default value
  const [numVirement, setNumeVirement] = useState("");
  const [enteredAmountGiftCard, setEnteredAmountGiftCard] = useState<
    number | 0
  >(0);
  const [enteredAmountCreditCard, setEnteredAmountCreditCard] = useState<
    number | 0
  >(0);
  const [fideliteAmount, setFideliteAmount] = useState<number | 0>(0);
  const [activePayment, setActivePayment] = useState("cash");

  const [idbon, setIdBon] = useState();
  const [tempCash, setTempCash] = useState<number | 0>(0);
  const [tempCheque, setTempCheque] = useState<number | 0>(0);
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [showModalTicketBon, setShowModalTicketBon] = useState(false);
  const [ticketId, setTicketId] = useState<string | null>(null);
  const session = useAppSelector(selectedSessionJournal);
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const parametrage = useAppSelector(parametrageCaisse);
  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const listClient = useAppSelector(ListClient);
  const { t } = useTranslation();
  const listpayment = useAppSelector(listPaymentOperations);
  const openedOrderId = openedOrder?._id;
  const [totalpayee, settotalpayee] = useState(0);
  const [currentClient, setCurrentClient] = useState<ClientType>();

  const [showReservationpopup, setshowReservationpopup] = useState(false);
  const total = openedOrder
    ? miniOrder
      ? miniOrder.total
      : unpaidTicket && unpaidTicket.total > 0
      ? unpaidTicket.total
      : openedOrder.totalNet
    : cart.selectedOrders?.length !== 0
    ? cart.selectedOrders.reduce((sum, order) => sum + order.totalNet, 0)
    : cart.totalNet;

  useEffect(() => {
    const totalChequeAmount = cheques.reduce(
      (sum, cheque) => sum + (cheque.amount || 0),
      0
    );
    setEnteredAmountCheque(Number(totalChequeAmount));
    let totalEntredAmounts =
      Number(totalChequeAmount) +
      Number(enteredAmountCash) +
      Number(enteredAmountCreditCard) +
      Number(enteredAmountGiftCard) +
      Number(fideliteAmount) +
      Number(reduction);

    setEnteredAmount(Number(totalEntredAmounts));
  }, [cheques]);

  useEffect(() => {
    const amount = Number(reduction);
    let totalEntredAmounts =
      Number(enteredAmountCheque) +
      Number(enteredAmountCash) +
      Number(enteredAmountCreditCard) +
      Number(enteredAmountGiftCard) +
      Number(fideliteAmount) +
      amount;
    setBonReductionAmount(amount);

    setEnteredAmount(Number(totalEntredAmounts));
  }, [reduction]);

  const addCheque = () => {
    setCheques([
      ...cheques,
      {
        tel: "",
        num_cheque: "",
        echeance: new Date(),
        amount: 0,
        client_id: cart.client_id.toString(),
        paid: false,
        payment_id: "",
        organisation_id: currentOrganisation,
      },
    ]);
  };

  const updateCheque = (index: number, updatedCheque: ChequeType) => {
    const newCheques = [...cheques];
    newCheques[index] = updatedCheque;
    setCheques(newCheques);
  };
  const remainingAmount = Number((total - enteredAmount).toFixed(3));

  // Methode de paiement
  useEffect(() => {
    if (cart.selectedOrders?.length) {
      setActivePayment("virement");
    } else if (total > 0) {
      setActivePayment("cash");
    }
  }, [cart]);

  useEffect(() => {
    if (total < 0) {
      setActivePayment("bon");
    } else if (total === 0) {
      resetPanierfunction();
    } else {
      setActivePayment("cash");
    }
  }, [total]);

  // client

  useEffect(() => {
    const getClient = async () => {
      if (cart.client_id) {
        const loyalClient = await dispatch(
          findClientById(cart.client_id.toString())
        );
        if (loyalClient && loyalClient.payload)
          setCurrentClient(loyalClient.payload);
      }
    };
    getClient();
  }, [cart.client_id]);

  useEffect(() => {
    if (cart && cart.order_id) {
      let totalpaid;
      //alert("avance")
      if (cart && cart.avance) {
        totalpaid = cart.avance;
      } else {
        totalpaid = Number(
          listpayment.reduce((acc, val) => acc + val.amount, 0)
        );
      }
      settotalpayee(totalpaid);

      dispatch(changetotal({ total: cart.total - totalpaid }));

      dispatch(changetotal({ total: cart.totalNet - totalpaid }));
    }
  }, [cart.order_id]);

  // entring amounts
  const handleEnteredAmount = (amount: number | 0) => {
    setEnteredAmountCash(amount);
    setEnteredAmount(
      Number(amount) +
        Number(enteredAmountCheque) +
        Number(enteredAmountCreditCard) +
        Number(enteredAmountGiftCard) +
        Number(fideliteAmount) +
        Number(reduction)
    );
  };

  const handleEnteredAmountGiftCard = (amount: number | 0) => {
    setEnteredAmountGiftCard(amount);
    const totalEntredAmounts =
      Number(enteredAmountCash) +
      Number(enteredAmountCheque) +
      Number(enteredAmountCreditCard) +
      Number(amount) +
      Number(fideliteAmount) +
      Number(reduction);

    setEnteredAmount(totalEntredAmounts);
  };
  const handleEnteredAmountCreditCard = (amount: number | 0) => {
    setEnteredAmountCreditCard(amount);
    const totalEntredAmounts =
      Number(enteredAmountCash) +
      Number(enteredAmountCheque) +
      Number(enteredAmountGiftCard) +
      Number(amount) +
      Number(fideliteAmount) +
      Number(reduction);
    setEnteredAmount(Number(totalEntredAmounts));
  };

  // end entering amounts

  function generateMouvementNumber(): string {
    let uniqueId: string;
    const existingOrderNumbers = mouvementsCaisse.map(
      (item) => item.operation_code
    );
    do {
      uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
    } while (
      existingOrderNumbers.some((operationCode) => operationCode === uniqueId)
    );

    return uniqueId;
  }
  const tickets = useAppSelector(ordersTickets);

  const saveMouvementCaisse = async (
    montant: number,
    paymentMethod: string,
    order: any,
    sens: any
  ) => {
    return {
      operation_code: generateMouvementNumber(),
      caisse_code: "someCode",
      order_id: order,
      montant: Number(montant),
      date: new Date(),
      user_id: user?._id,
      journal_id: journal?._id,
      session_id: session?._id,
      payment_method: paymentMethod,

      organisation_id: currentOrganisation,
      commentaire: sens && sens === "d" ? "Remboursement" : null,
      type_mouvement_id:
        sens && sens === "d"
          ? "66068c482b2a9e20c257701e"
          : "65a5399107fa84b78ac5c65b",
    };
  };

  // payments methods

  const virement = async (order: any) => {
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "Virement",
      date: new Date(),
      amount: Number(order.totalNet.toFixed(3)),
      rendu: 0,
      code_caisse: "someCode",
      organisation_id: currentOrganisation ?? "defaultOrganizationId",
      transaction_Number: numVirement,
    };

    const respMvt = await saveMouvementCaisse(
      Number(order.totalNet.toFixed(3)),
      "Virement",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };
  const fidelitePayment = async (order: any) => {
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "Points fidelité",
      date: new Date(),
      amount: Number(fideliteAmount)
        ? Number(fideliteAmount)
        : total.toFixed(3),
      rendu: 0,
      code_caisse: "someCode",
      organisation_id: currentOrganisation ?? "defaultOrganizationId",
      transaction_Number: "",
    };

    const respMvt = await saveMouvementCaisse(
      Number(fideliteAmount)
        ? Number(fideliteAmount.toFixed(3))
        : total.toFixed(3),
      "Points fidelité",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };
  const bonPayment = async (order: any) => {
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "bon",
      date: new Date(),
      amount: Number(tempReduction),
      rendu: 0,
      code_caisse: "someCode",
      organisation_id: currentOrganisation ?? "defaultOrganizationId",
      transaction_Number: bon,
    };

    const respMvt = await saveMouvementCaisse(
      Number(tempReduction),
      "bon",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };

  const cashPayment = async (order: any) => {
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "Espéces",
      date: new Date(),
      amount: Number(enteredAmountCash)
        ? Number(enteredAmountCash)
        : Number(total.toFixed(3)),
      rendu:
        order.status !== "reservation"
          ? Number(enteredAmountCash)
            ? remainingAmount < 0
              ? remainingAmount * -1
              : 0
            : 0
          : 0,
      code_caisse: "someCode",
      organisation_id: currentOrganisation ?? "defaultOrganizationId",
      transaction_Number: "",
    };

    const respMvt = await saveMouvementCaisse(
      Number(enteredAmountCash)
        ? Number(enteredAmountCash)
        : Number(total.toFixed(3)),
      "Cash",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };
  const chequePayment = async (order: any, cheque: any) => {
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "Chéque",
      date: new Date(),
      amount: Number(cheque.amount),
      rendu: 0,
      code_caisse: "someCode",
      organisation_id: currentOrganisation,
      transaction_Number: cheque?.num_cheque,
      cheques: cheque,
    };
    const respMvt = await saveMouvementCaisse(
      Number(cheque.amount),
      "Chéque",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };
  const giftCardPayment = async (order: any) => {
    const today = new Date();
    const twoWeeksLater = new Date(today);
    twoWeeksLater.setDate(today.getDate() + 14);
    let dataBon: BonReductionType = {
      date: today,
      amount: Number(remainingAmount * -1),
      dateEcheance: twoWeeksLater,
      depot_id: currentDepot._id,
      code: generateCode(),
      type: "Contre bon",
    };
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "Tickets cadeaux",
      date: new Date(),
      rendu: 0,
      amount: Number(enteredAmountGiftCard),
      code_caisse: "someCode",
      organisation_id: currentOrganisation ?? "defaultOrganizationId",
      transaction_Number: "",
      bon: remainingAmount < 0 ? dataBon : null,
    };

    const respMvt = await saveMouvementCaisse(
      Number(enteredAmountGiftCard),
      "Tickets cadeaux",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };
  const creditCardPayment = async (order: any) => {
    const data = {
      operation_code: "4554",
      order_id: order._id,
      ticket_id: null,
      payment_method: "Carte bancaire",
      date: new Date(),
      amount: Number(enteredAmountCreditCard),
      rendu: 0,
      code_caisse: "someCode",
      organisation_id: currentOrganisation ?? "defaultOrganizationId",
      transaction_Number: "",
    };

    const respMvt = await saveMouvementCaisse(
      Number(enteredAmountCreditCard),
      "Carte bancaire",
      order._id,
      null
    );
    return { payement: data, Mouv: respMvt };
  };
  // end payments methods

  //payments
  const partialPayment = async (order: any) => {
    let response;
    let responseTicket;
    response = await dispatch(
      updateOrderApi({
        orderId: order._id,
        status: !miniOrder ? "Payée" : "Partiellement payée",
        paidProducts: [],
        vendeur:
          selectedOption && selectedOption.value
            ? selectedOption.value
            : user?._id,
      })
    );
    if (openedOrder && response.payload.success) {
      dispatch(selectOrder(response.payload.data)); //update selected order
      const resfonction = await fonctionpayment(order);
      const obj = {
        ticket: {
          main_order: openedOrder._id,
          ticket_order: miniOrder,
          type: "Partiel",
          process: total && total < 0 ? "Retour" : "Vente",
        },
        payment: resfonction.payment,
        Mvt: resfonction.lmvt,
      };

      responseTicket = await dispatch(saveTicketApi(obj));

      if (responseTicket.payload.success) {
        if (responseTicket.payload.data.bon) {
          setIdBon(responseTicket.payload.data.bon._id);
          setShowModalTicketBon(true);
        }

        const newTicketId = responseTicket.payload.data.ticket._id; // Assuming _id is the ID property
        setTicketId(newTicketId);
      } else {
        ToastWarning(response.payload.message);
      }
      dispatch(
        updateTableStatusApi({
          table_id: order.table_id,
          status: "Disponible",
        })
      );
    }
  };
  const totalPayment = async (order: any) => {
    let response;
    let responseTicket;
    let tempOrder = _.cloneDeep(order);
    let previousStatus = tempOrder.status;
    response = await dispatch(
      updateOrderApi({
        orderId: order._id,
        status: "Payée",
        paidProducts: [],
        vendeur: null,
      })
    );
    if (response.payload.success) {
      if (user?.role_id?.libelle === "onlineSale") {
        const respfonction = await fonctionpayment(order);
        console.log("respfonction", respfonction);
        const obj = {
          order: order,
          payment: respfonction.payment,
          Mvt: respfonction.lmvt == null ? [] : respfonction.lmvt,
        };
        responseTicket = await dispatch(saveTicketApi(obj));
      } else {
        const respfonction = await fonctionpayment(response.payload.data);

        tempOrder.status = "Payée";

        const obj = {
          ticket: {
            main_order: tempOrder._id,
            ticket_order:
              previousStatus === "Partiellement payée"
                ? unpaidTicket
                : tempOrder,
            type:
              previousStatus === "Nouvelle" ||
              previousStatus === "demande Valide"
                ? "Total"
                : "Partiel",
            process: total && total < 0 ? "Retour" : "Vente",
          },
          payment: respfonction.payment,
          Mvt: respfonction.lmvt,
        };
        responseTicket = await dispatch(saveTicketApi(obj));

        if (responseTicket.payload.success) {
          if (responseTicket.payload.data.bon) {
            setIdBon(responseTicket.payload.data.bon._id);
            setShowModalTicketBon(true);
          }

          const newTicketId = responseTicket.payload.data.ticket._id;
          setTicketId(newTicketId);
          resetPanierfunction();
          dispatch(resetReduction());
        } else {
          ToastWarning(response.payload.message);
        }
        ToastSuccess(response.payload.message);
      }
    } else {
      ToastWarning(response.payload.message);
    }
  };
  const fonctionpayment = async (order: any) => {
    let listP: any[] = [];
    let listM: any[] = [];
    if (
      !enteredAmountCash &&
      !enteredAmountCheque &&
      !enteredAmountCreditCard &&
      !enteredAmountGiftCard &&
      !numVirement
    ) {
      const resp = await cashPayment(order);
      listP = [...listP, resp.payement];
      listM = [...listM, resp.Mouv];
    } else {
      if (Number(enteredAmountCheque) > 0) {
        cheques.forEach(async (element) => {
          const resp = await chequePayment(order, element);
          listP = [...listP, resp.payement];
          listM = [...listM, resp.Mouv];
        });
      }
      if (Number(enteredAmountGiftCard) > 0) {
        const resp = await giftCardPayment(order);
        listP = [...listP, resp.payement];
        listM = [...listM, resp.Mouv];
      }
      if (Number(enteredAmountCash) > 0) {
        const resp = await cashPayment(order);
        listP = [...listP, resp.payement];
        listM = [...listM, resp.Mouv];
      }
      if (Number(enteredAmountCreditCard) > 0) {
        const resp = await creditCardPayment(order);
        listP = [...listP, resp.payement];
        listM = [...listM, resp.Mouv];
      }
      if (fideliteAmount > 0) {
        const resp = await fidelitePayment(order);
        listP = [...listP, resp.payement];
        listM = [...listM, resp.Mouv];
      }
      if (tempReduction) {
        const resp = await bonPayment(order);
        listP = [...listP, resp.payement];
        listM = [...listM, resp.Mouv];
      }
      if (numVirement) {
        const resp = await virement(order);
        listP = [...listP, resp?.payement];
        listM = [...listM, resp?.Mouv];
      }
    }
    if (journal) {
      await dispatch(
        updateFondCourantApi({
          id: journal._id,
          fond_courant: Number(
            Number(journal.fond_courant) + Number(order.total)
          )?.toFixed(3),
        })
      );
    }
    return { payment: listP, lmvt: listM };
  };

  const handlePayOrder = async (order: any) => {
    if (cart.selectedOrders?.length !== 0) {
      await totalPayment(order);
    } else {
      if (miniOrder) {
        unpaidTicket && miniOrder.total === unpaidTicket?.total
          ? await totalPayment(order)
          : partialPayment(order);
      } else {
        await totalPayment(order);
      }
      dispatch(resetSelectedOrder());
      // if (order.type !== "online")
      setShowModalTicket(true);
    }
  };
  const clientFidelite = async (order: OrderType) => {
    if (cart.programmesFidelite?.length) {
      for (const item of cart.programmesFidelite) {
        const data: HistoriqueFideliteType = {
          client_id: cart.client_id.toString(),
          date: new Date(),
          order_id: order._id,
          organisation_id: currentOrganisation ? currentOrganisation : "",
          points: item?.points,
          program_fidelite: item._id,
        };

        try {
          await dispatch(createHistoriqueFidelite(data));
          const client = await dispatch(
            findClientById(cart.client_id.toString())
          );

          if (client && client.payload) {
            await dispatch(
              updateClientApi({
                _id: cart.client_id.toString(),
                points: client.payload.points + item.points,
                equivalentMoney:
                  Number(client.payload.equivalentMoney) +
                  Number(item.equivalentMoney),
              })
            );
          } else {
            console.error("Client not found or payload missing", client);
          }
        } catch (error) {
          console.error("Error updating client fidelity", error);
        }
      }
    }
  };

  const reptureAlerts = async (orderItems: any) => {
    for (const value of orderItems) {
      console.log("value",value)
      const res = await dispatch(
        updateArticleStockApi({
          id: value.article._id,
          depotId: currentDepot._id,
          soldQuantity: value.quantity,
          type: value?.type ,
          organisation:currentOrganisation,
          defaut_id: value?.defaut_id

        })
      );
      console.log("res.payload", res.payload);
      if (
        res.payload.success &&
        parametrage &&
        res.payload?.data?.stock < value?.article?.minimalStock
      ) {
        toast.warning(
          `L'article ${res.payload.data.article_id?.titre?.fr} ${value.minimalStock} est presque en rupture de stock ! Stock actuel : ${res.payload.data.stock}`
        );
      }
    }
  };

  const RefundCash = async () => {
    saveMouvementCaisse(Math.abs(total), "Cash", null, "d");
  };

  function generateCode(): string {
    let uniqueId: string;
    const existingCodes = Object.values(bonsReduction).map(
      (item) => item[0]?.code
    );
    do {
      uniqueId = (Math.floor(Math.random() * 900000) + 100000).toString();
    } while (existingCodes.some((operationCode) => operationCode === uniqueId));

    return uniqueId;
  }
  const handleRefund = async (type: string) => {
    try {
      let order: any = { ...cart };
      delete order.calculatedQuantity;
      delete order.orderConfirmed;

      if (currentOrganisation) {
        order.organisation_id = currentOrganisation;
      }

      order.items = cart.items;
      order.date = new Date();
      order.table_id = selectedtable ? selectedtable._id : null;
      order.total = cart.total.toFixed(3);
      order.totalNet = cart.totalNet.toFixed(3);
      order.order_number = await findSoucheCommande(societe_id, dispatch);
      order.status = type;
      order.client_id = cart.client_id;
      order.clientRemise = cart.clientRemise;
      order.societe_id = societe_id;
      order.depots_id = currentDepot._id;
      order.user_id = user?._id;
      if (journal) order.journal_id = journal._id;
      order.vendeur =
        selectedOption && selectedOption.value
          ? selectedOption.value
          : user?._id;
      if (cart.refundedOrder) {
        order.items.forEach((value: any) => {
          dispatch(
            updateArticleStockApi({
              id: value.article._id,
              depotId: currentDepot._id,
              soldQuantity: value.quantity,
              type: value?.type,
              organisation: currentOrganisation,
              defaut_id: value?.defaut_id

            })
          );
        });
        let responsefonct;
        if (total && total > 0) {
          // payment + mouvement
          responsefonct = await fonctionpayment(order);
        } else {
          setActivePayment("bon");
        }
        order.order_id = cart?.refundedOrder._id;

        const obj = {
          ticket: {
            main_order: cart.refundedOrder._id,
            ticket_order: order,
            type: "Total",
            process: type,
          },
          payment: responsefonct?.payment,
          Mvt: responsefonct?.lmvt,
        };
        const ticketrsp = await dispatch(saveTicketApi(obj));

        if (ticketrsp.payload.success) {
          if (ticketrsp.payload.data.bon) {
            setIdBon(ticketrsp.payload.data.bon._id);
            setShowModalTicketBon(true);
          }
          const newTicketId = ticketrsp.payload.data.ticket._id; // Assuming _id is the ID property
          setTicketId(newTicketId);
          setShowModalTicket(true);
        }
      }
      if (activePayment === "bon") {
        const today = new Date();
        const twoWeeksLater = new Date(today);
        twoWeeksLater.setDate(today.getDate() + 14);
        let data: BonReductionType = {
          date: today,
          amount: Number(Math.abs(cart.totalNet)),
          dateEcheance: twoWeeksLater,
          depot_id: currentDepot._id,
          code: generateCode(),
          type: "Contre bon",
        };
        const res = await dispatch(saveBonReductionApi(data));
        if (res.payload.success) {
          setIdBon(res.payload.data._id);
          setShowModalTicketBon(true);
        }
      } else {
        await RefundCash();
      }

      resetPanierfunction();
      dispatch(resetReduction());
    } catch (error) {
      console.error("Error during refund process:", error);
    }
  };

  const paymentRightBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        paymentRightBarRef.current &&
        !paymentRightBarRef.current.contains(event.target as Node)
      ) {
        if (setVisible) setVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setVisible]);
  useEffect(() => {
    if (societe_id && cart.client_id === "") {
      dispatch(findClientBySocieteApi(societe_id));
      dispatch(findDefaultClientsBySociete(societe_id));
    }
    if (openedOrder) dispatch(findTicketsByOrderApi(openedOrder._id));
    if (currentOrganisation) dispatch(findVendeurs(currentOrganisation));
  }, [dispatch, openedOrder]);

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const options = vendeurs.map((item) => ({
    value: item._id,
    label: item.nom,
    data: item,
  }));

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const handleFideliteAmount = async () => {
    const client = await dispatch(findClientById(cart.client_id.toString()));
    if (client && client.payload) {
      setFideliteAmount(client.payload.equivalentMoney);
      setEnteredAmount(
        Number(enteredAmountCash) +
          Number(enteredAmountCheque) +
          Number(enteredAmountCreditCard) +
          Number(enteredAmountGiftCard) +
          Number(fideliteAmount)
      );

      if (client.payload.equivalentMoney >= total) setEnteredAmountCash(0);
    }
  };

  useEffect(() => {
    setEnteredAmount(0);
    setEnteredAmountCash(0);
    setEnteredAmountCheque(0);
    setEnteredAmountGiftCard(0);
    setEnteredAmountCreditCard(0);
    setFideliteAmount(0);
    setBonReductionAmount(0);

    setEcheance(null);
    setTel("");
    setNumCheque("");
    setCheques([
      {
        tel: "",
        num_cheque: "",
        echeance: new Date(),
        amount: 0,
        client_id: cart?.client_id?.toString(),
        paid: false,
        payment_id: "",
        organisation_id: currentOrganisation,
      },
    ]);
  }, [openedOrder, cart]);

  const handleConfirmOrder = async (data: any, status: string) => {
    let order = { ...data };
    delete order.calculatedQuantity;
    delete order.orderConfirmed;
    let response: any;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total.toFixed(3);
    order.totalNet = cart.totalNet.toFixed(3);
    if (cart.order_id === null)
      order.order_number = await findSoucheCommande(societe_id, dispatch);
    order.status = status;
    order.client_id = cart.client_id;
    order.clientRemise = cart.clientRemise;
    order.societe_id = societe_id;
    order.depots_id = currentDepot._id;
    order.type = "caisse";

    if (journal) order.journal_id = journal?._id;
    order.vendeur =
      selectedOption && selectedOption.value ? selectedOption.value : user?._id;
    if (user) order.user_id = user?._id;
    try {
      if (cart.order_id !== null) {
        order._id = cart.order_id;
        response = await dispatch(UpdateOrderdataApi(order));
      } else {
        response = await dispatch(saveOrderApi(order));
      }
      if (response.payload.success) {
        reptureAlerts(order.items);
        const rspayment = await fonctionpayment(response.payload.data);
        let resptickt;
        let data = response.payload.data;
        const obj = {
          ticket: {
            main_order: data._id,
            ticket_order: data,
            type: "Total",
            process: total && total < 0 ? "Retour" : "Vente",
          },
          payment: rspayment.payment,
          Mvt: rspayment.lmvt,
        };
        resptickt = await dispatch(saveTicketApi(obj));

        clientFidelite(data);
        resetPanierfunction();
        await dispatch(resetReduction());

        await dispatch(addClient(defaultClient._id));
        if (parametrage && !parametrage.barcodeOnly)
          await dispatch(getOrderByIdApi(response.payload.data._id));
        const newTicketId = resptickt.payload.data.ticket._id;
        if (resptickt.payload.data.bon) {
          setIdBon(resptickt.payload.data.bon._id);
          setShowModalTicketBon(true);
        }

        setTicketId(newTicketId);
        setShowModalTicket(true);
        ///  handlePayOrder(response.payload.data);
        setTempCash(Number(enteredAmountCash));
        setEnteredAmountCash(0);
        setEnteredAmountGiftCard(0);
        setEnteredAmountCreditCard(0);
        setTempCheque(Number(enteredAmountCheque));
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    }
  };

  const handlereserverOrder = async () => {
    if (cart.items?.length === 0) {
      ToastWarning(t("choisissez un article"));
    } else {
      setshowReservationpopup(true);
    }
  };
  const confirmreservation = async (data: any, inforeservation: any) => {
    setshowReservationpopup(false);
    let order = { ...data };
    delete order.calculatedQuantity;
    delete order.orderConfirmed;
    let response: any;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total;
    order.totalNet = cart.totalNet;
    order.type = "caisse";

    order.order_number = await findSoucheCommande(societe_id, dispatch);
    order.status = "reservation";
    order.client_id = cart.client_id;
    order.journal_id = journal?._id;
    order.depots_id = currentDepot._id ? currentDepot._id : null;
    order.vendeur =
      selectedOption && selectedOption.value ? selectedOption.value : user?._id;
    order.enteredAmountCash = Number(enteredAmountCash);
    order.enteredAmountCheque = Number(enteredAmountCheque);
    order.reservationdata = {
      avance: {
        total: Number(enteredAmountCash) + Number(enteredAmountCheque),
        cash: Number(enteredAmountCash),
        cheque: Number(enteredAmountCheque),
      },
      duree: inforeservation.duree,
      date_echeance: inforeservation.date_echeance,
      infoclient: {
        client: inforeservation.client,
        nom: inforeservation.nom,
        mobile: inforeservation.mobile,
      },
      achat_total: order.total,

      achat_totalNet: order.totalNet,
    };

    const avanceresevation = order.reservationdata.avance.total;

    order.user_id = user?._id;
    try {
      response = await dispatch(saveOrderApi(order));
      if (response.payload.success) {
        order.items.forEach((value: any) => {
          dispatch(
            updateArticleStockApi({
              id: value.article._id,
              depotId: currentDepot._id,
              soldQuantity: value.quantity,
              type: value?.type ,
              organisation:currentOrganisation,

              defaut_id: value?.defaut_id

            })
          );
        });
        let data = response.payload.data;
        const copiedata = { ...data };
        copiedata.total = avanceresevation;
        const respfonction = await fonctionpayment(data);
      
        const obj = {
          ticket: {
            main_order: data._id,
            ticket_order: copiedata,
            type: "Partiel",
            process: total && total < 0 ? "Retour" : "Vente",
          },
          payment: respfonction.payment,
          Mvt: respfonction.lmvt,
        };
        const ticketresp = await dispatch(saveTicketApi(obj));

        let newTicketId;
        if (ticketresp.payload.success) {
          if (ticketresp.payload.data.bon) {
            setIdBon(ticketresp.payload.data.bon._id);
            setShowModalTicketBon(true);
          }
          newTicketId = ticketresp.payload.data.ticket._id; // Assuming _id is the ID property
          setTicketId(newTicketId);
        }

        resetPanierfunction();
        dispatch(addClient(defaultClient._id));
        dispatch(getOrderByIdApi(response.payload.data._id));
        setTempCash(Number(enteredAmountCash));

        setEnteredAmountCash(0);
        setEnteredAmountGiftCard(0);
        setEnteredAmountCreditCard(0);
        setTempCheque(Number(enteredAmountCheque));
        await dispatch(resetReduction());

        setShowModalTicket(true);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const PayerReservation = async (datacart: any) => {
    const paiementdata = {
      giftcard: Number(enteredAmountGiftCard),
      cheque: Number(enteredAmountCheque),
      cash: Number(enteredAmountCash),
      remainingAmount: Number(remainingAmount * -1),
    };
    let order = { ...datacart };
    delete order.calculatedQuantity;
    delete order.orderConfirmed;
    let response: any;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total + cart.avance;
    order.totalNet = cart.totalNet + cart.avance;

    order.order_number = await findSoucheCommande(societe_id, dispatch);
    order.status = "reservation";
    order.client_id = cart.client_id;
    order.journal_id = journal?._id;
    order.depots_id = currentDepot._id ? currentDepot._id : null;
    order.vendeur =
      selectedOption && selectedOption.value ? selectedOption.value : user?._id;
    order.enteredAmountCash = Number(enteredAmountCash);
    order.enteredAmountCheque = Number(enteredAmountCheque);

    order.user_id = user?._id;
    const resp = await fonctionpayment(order);
    const data = {
      paiementdata: paiementdata,
      order: order,
      payement: resp?.payment,
      MVtCaisse: resp?.lmvt,
    };

    response = await dispatch(PayerReservationApi(data));
    if (response.payload.success) {
      order.items.forEach((value: any) => {
        dispatch(
          updateArticleStockApi({
            id: value.article._id,
            depotId: currentDepot._id,
            soldQuantity: value.quantity,
            type: value?.type ,
            organisation:currentOrganisation,

            defaut_id: value?.defaut_id

          })
        );
      });
      let data = response.payload.data;

      const newTicketId = data._id; // Assuming _id is the ID property

      setTicketId(newTicketId);

      resetPanierfunction();

      dispatch(addClient(defaultClient._id));
      dispatch(getOrderByIdApi(response.payload.data.main_order));
      setTempCash(Number(enteredAmountCash));
      setEnteredAmountCash(0);
      setEnteredAmountGiftCard(0);
      setEnteredAmountCreditCard(0);
      setTempCheque(Number(enteredAmountCheque));

      setShowModalTicket(true);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  const orderConfirmationAlert = () => {
    Swal.fire({
      title: "Voulez-vous confirmer ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (openedOrder) {
          if (
            cart.operationType === "Retour" ||
            cart.operationType === "Echange"
          ) {
            handleRefund(cart.operationType);
          } else {
            const copie = { ...openedOrder };
            copie.items = cart.items;
            handlePayOrder(copie);
          }
        } else if (cart.selectedOrders?.length !== 0) {
          cart.selectedOrders.map((item) => {
            handlePayOrder(item);
          });
        } else {
          if (
            cart.operationType === "Retour" ||
            cart.operationType === "Echange"
          ) {
            handleRefund(cart.operationType);
          } else {
            handleConfirmOrder(cart, "Payée");
          }
        }
      }
    });
  };

  const resetPanierfunction = () => {
    dispatch(resetPanier());
    dispatch(addClient(defaultClient._id));
    setEnteredAmount(0);
    setEnteredAmountCash(0);
    setCheques([
      {
        tel: "",
        num_cheque: "",
        echeance: new Date(),
        amount: 0,
        client_id: cart.client_id?.toString(),
        paid: false,
        payment_id: "",
        organisation_id: currentOrganisation,
      },
    ]);
    setEnteredAmountCheque(0);
    setEcheance(null);
    setTel("");
    setNumCheque("");
    setEnteredAmountGiftCard(0);
    setEnteredAmountCreditCard(0);
  };
  return (
    <div
      className={
        setVisible
          ? visible === true
            ? "col-right-content-container payment-rightbar displayed"
            : "col-right-content-container payment-rightbar"
          : "fixed-payment-rightbar"
      }
      ref={paymentRightBarRef}
    >
      {ticketId ? (
        <TicketDeCaisse
          ShowModal={showModalTicket}
          setShowModal={setShowModalTicket}
          givenAmount={tempCash + tempCheque}
          cash={tempCash}
          cheque={tempCheque}
          ticket_id={ticketId}
          order_id={openedOrderId}
        />
      ) : null}
      <BonDeReductionTicket
        ShowModal={showModalTicketBon}
        setShowModal={setShowModalTicketBon}
        Bon_id={idbon}
      />

      <div className="widget-message" style={{ padding: "10px" }}>
        <div className="widget-title mb-10">
          <h5>{total && total < 0 ? "Remboursement" : t("payment")}</h5>
          {setVisible ? (
            <button
              className="delete-button"
              onClick={(e) => {
                if (setVisible) setVisible(false);
              }}
              style={{ float: "right" }}
            >
              <i
                className="fas fa-times"
                style={{ width: "auto", display: "flex" }}
              ></i>
            </button>
          ) : null}
        </div>
      </div>
      <div style={{ margin: "2px 0" }}>
        <Select
          className="basic"
          options={options}
          isSearchable={true}
          onChange={handleSelectChange}
          value={selectedOption}
          styles={customStyles}
          placeholder={"Vendeur / vendeuse"}
        />
      </div>

      {cart.order_id && cart.avance && (
        <div className="widget-todo" style={{ width: "100%" }}>
          <div className="todo-content">
            <div className="todo-title">
              <h6>
                <span>{t("Avance")}</span>
              </h6>
            </div>
            <div className="todo-text">
              <p>{totalpayee?.toFixed(3) || ""}</p>
            </div>
          </div>
        </div>
      )}
      <div className="widget-todo">
        <div className="todo-content">
          <div className="todo-title">
            <h6>
              <span className="align-self-center">{t("total_to_pay")}</span>
            </h6>
          </div>
          <div className="todo-text">
            <p>{total?.toFixed(3)}</p>
          </div>
        </div>
      </div>

      <div className="activity-section">
        <div style={{ overflow: "auto", maxHeight: "524px" }}>
          {enteredAmountCash > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">{t("Espéces")}</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountCash}</p>
                </div>
              </div>
            </div>
          ) : null}
          {enteredAmountCheque > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">{t("Chéque")}</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountCheque.toFixed(3)}</p>
                </div>
              </div>
            </div>
          ) : null}
          {enteredAmountGiftCard > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">
                      {t("Tickets cadeaux")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountGiftCard}</p>
                </div>
              </div>
            </div>
          ) : null}
          {enteredAmountCreditCard > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">
                      {t("Credit Card")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountCreditCard}</p>
                </div>
              </div>
            </div>
          ) : null}
          {fideliteAmount > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">
                      {t("Points fidelité")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{fideliteAmount}</p>
                </div>
              </div>
            </div>
          ) : null}

          {reduction > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">{t("Contre bon")}</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{reduction}</p>
                </div>
              </div>
            </div>
          ) : null}
          {openedOrder || cart
            ? Number(enteredAmount + reduction) !== 0 &&
              Number(enteredAmount + reduction) !== Number(total) && (
                <div className="widget-todo" style={{ width: "100%" }}>
                  <div className="todo-content">
                    <div className="todo-title">
                      <h6>
                        <span className="align-self-center">
                          {Number(enteredAmountCash) +
                            Number(enteredAmountCheque) +
                            Number(enteredAmountCreditCard) +
                            Number(enteredAmountGiftCard) +
                            Number(fideliteAmount) +
                            reduction <
                          total
                            ? t("Remaining to Pay")
                            : t("Remaining to Return")}
                        </span>
                      </h6>
                    </div>
                    <div className="todo-text">
                      <p>
                        {remainingAmount !== null
                          ? remainingAmount?.toFixed(3)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              )
            : null}

          <nav id="payment-block">
            <ul className="menu-payments" style={{ flexWrap: "wrap" }}>
              {user && user.role_id?.libelle === "onlineSale" ? (
                <li className="menu active">
                  <a
                    data-active="true"
                    className="menu-toggle"
                    onClick={() => {
                      setActivePayment("virement");
                      console.log(
                        enteredAmountCash,
                        enteredAmountCheque,
                        enteredAmountCreditCard,
                        enteredAmountGiftCard,
                        numVirement
                      );
                    }}
                    style={{
                      borderColor:
                        activePayment && activePayment === "virement"
                          ? "#009688" // Change to the desired background color
                          : "",
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-exchange-alt"></i>{" "}
                      </div>
                      <span>Virement</span>
                    </div>
                  </a>
                </li>
              ) : null}

              {total >= 0 ? (
                <>
                  <li className={total >= 0 ? "menu active" : "menu"}>
                    <a
                      data-active={total >= 0 ? "true" : "false"}
                      className="menu-toggle"
                      onClick={() => {
                        setActivePayment("cash");
                      }}
                      style={{
                        borderColor:
                          activePayment && activePayment === "cash"
                            ? "#009688" // Change to the desired background color
                            : "",
                      }}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-money-bill-alt"></i>{" "}
                        </div>
                        <span>Cash</span>
                      </div>
                    </a>
                  </li>

                  <li className={total < 0 ? "menu active" : "menu"}>
                    <a
                      data-active={total < 0 ? "true" : "false"}
                      className="menu-toggle"
                      onClick={() => {
                        setActivePayment("cheque");
                      }}
                      style={{
                        borderColor:
                          activePayment && activePayment === "cheque"
                            ? "#009688" // Change to the desired background color
                            : "",
                      }}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-money-check-alt"></i>
                        </div>
                        <span>Chéque</span>
                      </div>
                    </a>
                  </li>
                  {user && user.role_id?.libelle !== "onlineSale" ? (
                    <li className="menu">
                      <a
                        data-active="false"
                        className="menu-toggle"
                        onClick={() => {
                          setActivePayment("gift card");
                        }}
                        style={{
                          borderColor:
                            activePayment && activePayment === "gift card"
                              ? "#009688" // Change to the desired background color
                              : "",
                        }}
                      >
                        <div className="base-menu">
                          <div className="base-icons">
                            <i className="fas fa-gift"></i>
                          </div>
                          <span>Gift Card</span>
                        </div>
                      </a>
                    </li>
                  ) : null}
                  {user && user.role_id?.libelle !== "onlineSale" ? (
                    <li className="menu">
                      <a
                        data-active="false"
                        className="menu-toggle"
                        onClick={() => {
                          setActivePayment("credit card");
                        }}
                        style={{
                          borderColor:
                            activePayment && activePayment === "credit card"
                              ? "#009688" // Change to the desired background color
                              : "",
                        }}
                      >
                        <div className="base-menu">
                          <div className="base-icons">
                            <i className="fas fa-credit-card"></i>
                          </div>
                          <span>Credit Card</span>
                        </div>
                      </a>
                    </li>
                  ) : null}
                  {/**currentClient && currentClient.equivalentMoney > 0 && */}
                  {cart.defaultClient._id !== cart.client_id &&
                  currentClient &&
                  Number(currentClient.equivalentMoney) > 0 ? (
                    <li className="menu">
                      <a
                        data-active="false"
                        className="menu-toggle"
                        onClick={() => {
                          handleFideliteAmount();
                          setActivePayment("points");
                        }}
                        style={{
                          borderColor:
                            activePayment && activePayment === "points"
                              ? "#009688" // Change to the desired background color
                              : "",
                        }}
                      >
                        <div className="base-menu">
                          <div className="base-icons">
                            <i className="fas fa-wallet"></i>{" "}
                          </div>
                          <span>Points</span>
                        </div>
                      </a>
                    </li>
                  ) : null}
                </>
              ) : (
                <li className="menu">
                  <a
                    className="menu-toggle"
                    data-active={total < 0 ? "true" : "true"}
                    onClick={() => {
                      setActivePayment("bon");
                    }}
                    style={{
                      borderColor:
                        activePayment && activePayment === "bon"
                          ? "#009688" // Change to the desired background color
                          : "",
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-credit-card"></i>
                      </div>
                      <span>Contre bon</span>
                    </div>
                  </a>
                </li>
              )}
            </ul>
          </nav>

          {activePayment === "cash" && (
            <CashPaymentMethod
              total={total}
              onAmountChange={handleEnteredAmount}
              givenAmout={enteredAmount}
              enteredAmountCash={enteredAmountCash}
              remainingAmount={remainingAmount}
            />
          )}

          {activePayment === "virement" && (
            <VirementPaymentMethod
              numVirement={numVirement}
              setNumeVirement={setNumeVirement}
            />
          )}
          {activePayment === "cheque" ? (
            <>
              {cheques.map((cheque, index) => (
                <ChequePaymentMethod
                  key={index}
                  total={total}
                  enteredAmountCheque={cheque.amount}
                  tel={cheque.tel}
                  setTel={(tel: any) => updateCheque(index, { ...cheque, tel })}
                  num={cheque.num_cheque}
                  setNumCheque={(num_cheque: any) =>
                    updateCheque(index, { ...cheque, num_cheque })
                  }
                  echeance={cheque.echeance}
                  setEcheance={(echeance: any) =>
                    updateCheque(index, { ...cheque, echeance })
                  }
                  onAmountChange={(amount: any) =>
                    updateCheque(index, { ...cheque, amount })
                  }
                />
              ))}
              <button
                className="btn btn-primary"
                onClick={addCheque}
                style={{ width: "100%" }}
              >
                +
              </button>
            </>
          ) : null}
          {activePayment === "gift card" && (
            <GiftCardPaymentMethod
              total={total}
              onAmountChange={handleEnteredAmountGiftCard}
              givenAmout={enteredAmount}
              enteredAmountGiftCard={enteredAmountGiftCard}
            />
          )}
          {activePayment === "credit card" && (
            <CreditCardPaymentMethod
              total={total}
              onAmountChange={handleEnteredAmountCreditCard}
              givenAmout={enteredAmount}
              enteredAmountCreditCard={enteredAmountCreditCard}
            />
          )}

          {activePayment === "points" && (
            <PointsFidleliteMethod
              total={total}
              onAmountChange={handleEnteredAmountCreditCard}
              givenAmout={enteredAmount}
              pointsFideliteEquivalent={fideliteAmount}
              setpointsFideliteEquivalent={setFideliteAmount}
            />
          )}
          {activePayment === "bon" && (
            <BonReductionMethod
              total={total}
              onAmountChange={handleEnteredAmountCreditCard}
              givenAmout={enteredAmount}
              pointsFideliteEquivalent={BonReductionAmount}
              setpointsFideliteEquivalent={setBonReductionAmount}
            />
          )}
        </div>

        {cart.order_id && cart.avance ? (
          <div className="cart-footer">
            <div
              className="widget-calendar-lists-scroll"
              style={{ width: "100%" }}
            >
              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                onClick={(e) => {
                  PayerReservation(cart);
                }}
              >
                {t("Payer")}
              </button>
            </div>
          </div>
        ) : (
          <div className="cart-footer">
            <div
              className="widget-calendar-lists-scroll"
              style={{ width: "100%" }}
            >
              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                disabled={
                  cart.items?.length === 0 &&
                  (!openedOrder || openedOrder?.items?.length === 0) &&
                  !cart.selectedOrders.length
                }
                onClick={(e) => {
                  orderConfirmationAlert();
                }}
              >
                {t("confirm")}
              </button>

              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                disabled={total === 0}
                hidden={
                  user && user.role_id?.libelle === "onlineSale" ? true : false
                }
                onClick={(e) => {
                  handlereserverOrder();
                }}
              >
                {t("reserver")}
              </button>
            </div>
          </div>
        )}
      </div>
      <Reservationpopup
        showModal={showReservationpopup}
        setshowModal={setshowReservationpopup}
        t={t}
        listClient={listClient}
        cart={cart}
        confirmreservation={confirmreservation}
      />
    </div>
  );
};

export default PaymentDrawer;
