import React, { useEffect, useState } from "react";
import Numpad from "../NumPad/NumPad";

const GiftCardPaymentMethod = ({
  total,
  enteredAmountGiftCard,
  onAmountChange,
}: any) => {
  const [givenAmountGiftCard, setGivenAmountGiftCard] = useState<string | null>(
    null
  );
  const [isInputFocused, setIsInputFocused] = useState(false);

  // const containsOnlyNumbers = (str: string) => !/[^0-9.]/.test(str) && /^\d+(\.\d+)?$/.test(str);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: any = e.target.value === "" ? 0 : e.target.value;
    setGivenAmountGiftCard(isNaN(amount) ? null : amount);
    onAmountChange(isNaN(amount) ? null :amount);
  };

  useEffect(() => {
    setGivenAmountGiftCard(null);
  }, [total]);

  return (
    <div>
      <div className="navbar-item flex-row search-ul navbar-dropdown">
        <div className="nav-item align-self-center search-animated">
          <form
            className="form-inline search-full form-inline search"
            role="search"
          >
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control  ml-lg-auto"
                placeholder="Numéro GiftCard"
              />
            </div>
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control  ml-lg-auto"
                placeholder="Montant"
                value={
                  enteredAmountGiftCard
                    ? enteredAmountGiftCard
                    : givenAmountGiftCard !== null
                    ? givenAmountGiftCard
                    : ""
                }
                onChange={handleAmountChange}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GiftCardPaymentMethod;
