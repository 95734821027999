import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { t } from "i18next";
import ActionModel from "./ActionModel";
import Filtre from "../Stock/Filtre";

import {
  findvendeursBySocieteApi,
  Vendeurs,
} from "../../_App/Redux/Slices/users/vendeurSlice";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Select from "react-select";
import {
  findClientBySocieteApi,
  ListClient,
} from "../../_App/Redux/Slices/client/clientSlice";
import Loader from "../../_Layouts/Loader/Loader";
import { Spinner } from "react-bootstrap";
import TicketDeCaissePaid from "../Caisses/TicketDeCaisse/TicketDeCaissePaid";
import { currentpage, ListPaymentMouvement, mouvementPayementApi, totalPages, Totaux } from "../../_App/Redux/Slices/payments/PaymentSlice";

export default function Mouvementpaiement() {
  const dispatch = useAppDispatch();
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [ticket_id, setticket_id] = useState<String|null>(null);

  const listMouvementPayement = useAppSelector(ListPaymentMouvement);
  const totaux = useAppSelector(Totaux);

  const current_page = useAppSelector(currentpage);
  const vendeurs = useAppSelector(Vendeurs);
  const clients = useAppSelector(ListClient);
  const societe_id = useAppSelector(societeId);
  const totalpages = useAppSelector(totalPages);
  const OrganisationList = useAppSelector(SelectedOrganisations);
  const [date_debut, setdate_debut] = useState("");
  const [date_fin, setdate_fin] = useState("");
  const [spinner, setspinner] = useState(false);
  const [index, setindex] = useState(!current_page ? 0 : current_page);
  const [vendeurOption, setvendeurOption] = useState<any[]>([]);
  const [selectedvendeur, setselectedvendeur] = useState<any[]>([]);
  const [OrganisationOption, setOrganisationOption] = useState<any[]>([]);
  const [selectedorganisation, setselectedorganisation] = useState<any[]>([]);
  const [clientOption, setclientOption] = useState<any[]>([]);
  const [selectedclient, setselecteclient] = useState<any[]>([]);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    // Add one day to today
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setdate_debut(formatDate(lastMonth));
    setdate_fin(formatDate(tomorrow)); 
    setspinner(true);
    const data = {
      organisations: selectedorganisation,
      index: 1,
      dateDebut: lastMonth,
      dateFin: tomorrow,
    };
    setindex(1);
    dispatch(mouvementPayementApi(data));
  }, []);

  useEffect(() => {
    setvendeurOption(
      vendeurs?.map((option) => ({
        value: option._id,
        label: option.nom,
      }))
    );
  }, [vendeurs]);

  useEffect(() => {
    if (OrganisationList)
      setOrganisationOption(
        OrganisationList?.map((option) => ({
          value: option._id,
          label: option.nom,
        }))
      );
  }, [OrganisationList]);

  useEffect(() => {
    if (clients)
      setclientOption(
        clients?.map((option) => ({
          value: option._id,
          label: option.nom,
        }))
      );
  }, [clients]);

  useEffect(() => {
    if (societe_id) {
      dispatch(findvendeursBySocieteApi(societe_id));
      dispatch(findClientBySocieteApi(societe_id));
    }
  }, [societe_id]);

  useEffect(() => {
    setspinner(false);
  }, [listMouvementPayement]);

  useEffect(() => {

    if (index !==0){

      setspinner(true);
     
      const data = {
        organisations: selectedorganisation,
        index: index,
        dateDebut: date_debut,
        dateFin: date_fin,
      };
      setindex(index);
      dispatch(mouvementPayementApi(data));
    }
  }, [index]);

  const findMouvement = async () => {
    
    setspinner(true);
    const data = {
      organisations: selectedorganisation,
      index: 1,
      dateDebut: date_debut,
      dateFin: date_fin,
    };
    setindex(1);
    dispatch(mouvementPayementApi(data));
  };

  const handelSelectVendeur = (selectedOption: any) => {
    const listvendeurs = selectedOption.map((item: any) => item.value);
    setselectedvendeur(listvendeurs);
  };
  const handelSelectOrganisation = (selectedOption: any) => {
    const listOrganisations = selectedOption.map((item: any) => item.value);
    setselectedorganisation(listOrganisations);
  };
  const handelSelectClient = (selectedOption: any) => {
    const listclient = selectedOption.map((item: any) => item.value);
    setselecteclient(listclient);
  };

  return (
    <>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
        <div className="col-2">
          <label>{t("Date debut")}</label>
          <input
            type="date"
            className="form-control "
            value={date_debut}
            onChange={(e) => setdate_debut(e.target.value)}
          />
        </div>
        <div className="col-2">
          <label>{t("Date fin")}</label>
          <input
            type="date"
            className="form-control "
            value={date_fin}
            onChange={(e) => setdate_fin(e.target.value)}
          />
        </div>
      

        <div className="col-2">
          <label>{t("Organisation")}</label>
          <Select
            isMulti
            options={OrganisationOption}
            isSearchable={true}
            onChange={handelSelectOrganisation}
            value={selectedorganisation?.map((item: any) =>
              OrganisationOption?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
   
        {spinner ?(
          <Spinner animation="border" />

        ):
        <div
        className="col-1 mt-4"
        style={{ fontSize: "30px" }}
        onClick={findMouvement}
      >
        {" "}
        <i className="fas fa-search"></i>
      </div>}
       
      </div>
      <div className=" row  mt-5  bg-white  " style={{ padding: "20px" }}>
       
          {totaux?.tiket_total_Espece &&
           <div  className="col-6 mt-3">
           <strong className="  lien-bleu-souligne" onClick={()=>{setticket_id(totaux?.tiket_total_Espece?.ticket_id) ;setShowModalTicket(true)}}> {t('Max Ticket Total Espece')}  : {totaux?.tiket_total_Espece?.ticket_id?.ticket_order?.order_number}{ ' valeur: ' + totaux?.tiket_total_Espece?.amount.toFixed(3)} </strong> 
         </div>
          }
          {totaux?.tiket_total_Cheque &&
              <div className="col-6 mt-3">
              <strong className="  lien-bleu-souligne" onClick={()=>{setticket_id(totaux?.tiket_total_Cheque?.ticket_id) ;setShowModalTicket(true)}}> {t('Max Ticket Total Cheque')}   : {totaux?.tiket_total_Cheque?.ticket_id?.ticket_order?.order_number }{ ' valeur:' + totaux?.tiket_total_Cheque?.amount.toFixed(3)} </strong>
            </div>
          }
       
      
       
        </div>
      <div className="row layout-spacing mt-5">
      
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th>{t("Ticket")}</th>
                <th>{t("Organisation")}</th>
                <th>{t("Méthode de paiement")}</th>
                <th>{t("Montant")}</th>
                <th>{t("Rendu")}</th>
                <th>{t("code_caisse")}</th>
                <th>{t("Date")}</th>
              </thead>

              <tbody>
                {listMouvementPayement &&
                  listMouvementPayement.length !== 0 &&
                  listMouvementPayement.map((item, index) => (
                    <tr key={index}>
                      <td className="  lien-bleu-souligne" onClick={()=>{setticket_id(item?.ticket_id) ;setShowModalTicket(true)}}>{item?.ticket_id?.ticket_order?.order_number}</td>
                      <td>{item?.organisation_id?.nom}</td>
                      <td>{item?.payment_method}</td>
                      <td>{item?.amount?.toFixed(3)}</td>
                      <td>{item?.rendu?.toFixed(3)}</td>
                      <td>{item?.code_caisse}</td>
                      <td>{item.createdAt.split("T")[0]}</td>
                    </tr>
                  ))}
                  <tr>
                     
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><strong>{ listMouvementPayement.reduce((acc, item) => { return acc + Number(item.amount) ||0}, 0).toFixed(3)}</strong></td>
                      <td><strong>{ listMouvementPayement.reduce((acc, item) => { return acc +  Number(item.rendu) ||0}, 0).toFixed(3)}</strong></td>
                      <td></td>
                      <td></td>
                    </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
      <TicketDeCaissePaid
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        ticket={ticket_id}
      />
    </>
  );
}
