import { useAppSelector } from "../../../_App/Redux/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReptureStockList } from "../../../_App/Redux/Slices/article/articleSlice";

const ReptureStockTable = ({reptures,t}:any) => {

  
  return (
    <>
      <div className="row layout-spacing">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="navbar-item flex-row search-ul navbar-dropdown"></div>

            <div
              className="widget-content widget-content-area"
              style={{ overflowX: "auto" }}
            >
              <table id="style-2" className="table style-2  table-hover">
                <thead>
                  <tr>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("Article")}
                    </th>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("Famille")}
                    </th>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("Organisation")}
                    </th>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("Depots")}
                    </th>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("minimal Stock")}
                    </th>
                    <th style={{ fontSize: "15px" }}>{t("Stock")}</th>
                  </tr>
                </thead>
                <tbody>
                  {reptures && reptures.length > 0 ? (
                    reptures.map((item:any) => {
                      return (
                        <tr key={item._id}>
                          <td
                            className="checkbox-column"
                            style={{ fontSize: "15px" }}
                          >
                            {item.article?.titre?.fr}
                          </td>
                          <td
                            className="checkbox-column"
                            style={{ fontSize: "15px" }}
                          >
                            {item.article?.souscategorie[0]?.libelle?.fr}
                          </td>
                       
                          <td
                            style={{ fontSize: "15px" }}
                          >
                            {item.organisation[0]?.nom}
                          </td>
                          <td
                            style={{ fontSize: "15px" }}
                          >
                            {item.depots[0]?.libelle}
                          </td>
                          <td
                            style={{ fontSize: "15px" }}
                            className="text-danger"
                          >
                            {item.article?.minimalStock}
                          </td>
                          <td
                            style={{ fontSize: "15px" }}
                            className="text-danger"
                          >
                            {item.stock}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <td>aucun rupture</td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReptureStockTable;
