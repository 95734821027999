import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUserId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  DepotDefautOrganisation,
  findDepotsBySocieteApi,
  ListDepotByOrganisation,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import { t } from "i18next";
import {
  findArticleByBarCode,
  findArticleStockByBarCodeApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  createTransfertApi,
  creeDistributionApi,
} from "../../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { toast } from "react-toastify";
import { UserDataType } from "../../../_App/Types/Forms/UserDataType";
import { findUsersByCodeApi } from "../../../_App/Redux/Slices/users/userSlice";
export default function DistributionParDepots() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const list_organisation = useAppSelector(SelectedOrganisations);
  const depotdefaut = useAppSelector(DepotDefautOrganisation);
  const organisation = useAppSelector(SelectedOrganisation);
  const organisationid = useAppSelector(SelectedOrganisationId);
  const listDepots = useAppSelector(ListDepotByOrganisation);
  const [selectedorganisation, setselectedorganisation] = useState("");
  const [selecteddepot, setselecteddepot] = useState("");
  const societe = useAppSelector(societeId);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [distribution, setdistribution] = useState<any[]>([]);
  const [codeabarre, setcodeabarre] = useState("");
  const user_id = useAppSelector(selectUserId);
  const [userlivraison, setuserlivraison] = useState<UserDataType>();
  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
    }
  }, [societe]);
  
  useEffect(()=>{
    if(list_organisation&&list_organisation.length !== 0){
 
      setselectedorganisation(list_organisation[0]._id)
      if(listDepots[list_organisation[0]?._id])  setselecteddepot(listDepots[list_organisation[0]?._id][0]?._id || "");

    }
  },[list_organisation,listDepots])

  useEffect(() => {
    if (
      selectedorganisation &&
      listDepots &&
      listDepots[selectedorganisation]?.length > 0
    ) {
      setselecteddepot(listDepots[selectedorganisation][0]?._id || "");
    }
  }, [selectedorganisation]);



  const handleChange = async (e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {

        await findArticle(value);
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const findArticle = async (codeabarre: string) => {
    if (selecteddepot) {
      const resultArticle = await dispatch(
        findArticleStockByBarCodeApi({ barcode: codeabarre, depots: depotdefaut._id })
      );

      if (resultArticle.payload.success) {
        const findarticle = distribution.findIndex(
          (item) => item.article._id === resultArticle.payload.article._id
        );
        if (findarticle === -1) {
          const obj = {
            article: resultArticle.payload.article,
            Quantite: 0,
            stockinitiale: resultArticle.payload.stock.stock,
            stock: resultArticle.payload.stock._id,
          };
          setdistribution((prev) => [...prev, obj]);
          setcodeabarre("");
        } else {
          ToastWarning("l'article deja existe dans le tableau");
        }
      } else {
        ToastWarning("l'article n'existe pas");
      }
    }
  };
  const inputchange = async (value: string, index: number) => {
    const copie = [...distribution];
    copie[index].stockinitiale =
      copie[index].stockinitiale +
      copie[index].Quantite -
      (parseFloat(value) || 0);
    if (copie[index].stockinitiale < 0) {
      ToastWarning(" vous avez depassez la quantite du depot");
    } else {
      copie[index].Quantite = parseFloat(value) || 0;
      setdistribution(copie);
    }
  };
  const saveDistribution = async () => {
    if (selectedorganisation !== "" && selecteddepot !== "") {
      const listtransfert = distribution.map((element) => {
        if (element.Quantite > 0) {
          return {
            article_id: element.article._id,
            Quantite: element.Quantite,
            Quantiteenvoyee: element.Quantite,
            stock: element.stock,
          };
        }
      });
      const filteredArray = listtransfert.filter(Boolean);

      const data = {
        depots_id: depotdefaut._id,
        depots_reception: selecteddepot,
        organisation_demande: selectedorganisation,
        organisation_transfer: organisationid,
        articles: listtransfert,
        societe_id: societe,
        demander: null,
        lignes: filteredArray,
        accepter_transfer: {
          etat: true,
          user_id: user_id,
          date: new Date(),
        },
        approver_reception: {
          etat: false,
        },
        agentLivraison: userlivraison,
      };

      if (filteredArray.length > 0) {
        const resp = await dispatch(creeDistributionApi([data]));
        if (resp.payload.success) {
          ToastSuccess("la distributrion est cree ");
          navigate("/listdemande/apprové");
        } else {
          ToastWarning(resp.payload.message);
        }
      } else {
        ToastWarning("Veuillez renseigner les champs des distributions.");
      }
    } else {
      ToastWarning("vous devez choisir l'organisation et le dépôt receveur");
    }
  };
  const deleteItem = (index: number) => {
    const newDistribution = distribution.filter((_, i) => i !== index);
    setdistribution(newDistribution);
  };
  const handleChangeAgentLivraison = async (e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        const data={societe:societe,code:value}

        const resultArticle = await dispatch(findUsersByCodeApi(data));
        if (resultArticle.payload.success) {
          const user = resultArticle.payload.data;
          setuserlivraison(user);
        } else {
          ToastWarning(resultArticle.payload.message);
        }
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };
  const removeAgentlivraison=()=>{
    setuserlivraison(undefined);

  }
  return (
    <div>
      <div className="row ">
        <div className="col-2 mt-2">
          <strong>{t("Organisation  destocké : ")}</strong>
          <strong>{organisation?.nom}</strong>
          <br />
          <strong>{t("Dépôt  destocké : ")}</strong>
          <strong>{depotdefaut?.libelle}</strong>
        </div>
        <div className="col-2">
          <strong>{t("Organisation  recerveur : ")}</strong>

          <select
            className="form-control"
            onChange={(e) => setselectedorganisation(e.target.value)}
            value={selectedorganisation}
          >
            {list_organisation?.map((item, index) => (
              <option key={index} value={item._id}>
                {item?.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <strong>{t("Dépôt  recerveur : ")}</strong>

          <select className="form-control" value={selecteddepot}>
            {selectedorganisation &&
              listDepots[selectedorganisation]?.map((item, index) => (
                <option key={index} value={item._id}>
                  {item?.libelle}
                </option>
              ))}
          </select>
        </div>
        <div className="col-2">
          <strong>{t("Agent de livraison : ")}</strong>

          {!userlivraison ? (
            <input
              type="text"
              className="form-control"
              onChange={(e) => handleChangeAgentLivraison(e)}
            />
          ) : (
            <>
              <span className="form-control">
                {userlivraison.nom + " " + userlivraison.prenom}
                <i  onClick={removeAgentlivraison}className="fas fa-times"></i>
              </span>
             
            </>
          )}
        </div>
        <div className="col-3">
          <strong>{t("Article : ")}</strong>
          <input
            className="form-control"
            value={codeabarre}
            onChange={(e) => {
              setcodeabarre(e.target.value);
              handleChange(e);
            }}
          />{" "}
        </div>
      </div>
      <div className="row">
        <div className="row layout-spacing mt-5">
          <div className="col-lg-12   bg-white  ">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <th colSpan={3}>Article</th>
                  <th>{t("Quantite depot destocké")}</th>
                  <th>Quantite</th>
                </thead>
                <tbody>
                  {distribution.map((item, index) => (
                    <tr>
                      <td onClick={() => deleteItem(index)}>
                        <i className="fas fa-trash-alt c-primary"></i>
                      </td>
                      <td>
                        {item.article.image &&
                        item.article.image !== null &&
                        item.article.image.length !== 0 ? (
                          <Zoom>
                            <img
                              alt="image"
                              src={
                                process.env.REACT_APP_API_PUBLIC_URL +
                                "article/" +
                                item?.article.image
                              }
                              width="100"
                            />
                          </Zoom>
                        ) : (
                          <img
                            src="assets/assets/img/no-image.jpg"
                            className="card-img-top"
                            alt="..."
                            style={{ width: "50px" }}
                          />
                        )}
                      </td>
                      <td>{item.article?.titre?.fr}</td>
                      <td>{item.stockinitiale}</td>
                      <td>
                        {" "}
                        <input
                          className="form-control"
                          value={distribution[index].Quantite}
                          onChange={(e) => inputchange(e.target.value, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {distribution.length > 0 && (
              <button
                className="btn btn-primary-app"
                onClick={saveDistribution}
              >
                {t("Enregistrer")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
