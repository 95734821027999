import { JournalCaisseType } from "./../../../Types/Entites/JournalCaisseType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { JournalCaisseDataType } from "../../../Types/Forms/JournalCaisseDataType";
import { RootState } from "../../store";

export const getAllJournalCaisses = createAsyncThunk(
  "journalCaisse/getAllJournalCaisses",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `journalCaisse/getAllJournalCaisses`,data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getAllJournalCaissesByOrganisation = createAsyncThunk(
  "journalCaisse/getAllJournalCaissesByOrganisation",
  async (organisation_id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `journalCaisse/getAllJournalCaissesByOrganisation/${organisation_id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const getJournalCaisseByIdApi = createAsyncThunk(
  "journalCaisse/getJournalCaisseByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `journalCaisse/getJournalCaisseByIdApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const createJournalCaisseApi = createAsyncThunk(
  "journalCaisse/createJournalCaisseApi",
  async (newJournalCaisse: JournalCaisseDataType, { rejectWithValue }) => {
    try {
      newJournalCaisse.date = moment().format("DD/MM/YYYY");

      const response = await axios.post(
        "/journalCaisse/createJournalCaisseApi",
        newJournalCaisse
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const getCurrentJournalApi = createAsyncThunk(
  "journalCaisse/getCurrentJournalApi",
  async (
    {
      date,
      idCaisse,
      userId,
    }: { date: string; idCaisse: string; userId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/journalCaisse/getCurrentJournalApi", {
        date,
        idCaisse,
        userId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const updateJournal = createAsyncThunk(
  "journalCaisse/updateJournal",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("/journalCaisse/updateJournal", data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const updateFondCourantApi = createAsyncThunk(
  "journalCaisse/updateFondCourantApi",
  async (
    { id, fond_courant }: { id: string; fond_courant: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put("/journalCaisse/updateFondCourantApi", {
        id,
        fond_courant,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const getPrecedentJournalApi = createAsyncThunk(
  "journalCaisse/getPrecedentJournalApi",
  async (id_caisse: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `journalCaisse/getPrecedentJournalApi/${id_caisse}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getJournalCaisseByOrganisationApi = createAsyncThunk(
  "journalCaisse/getJournalCaisseByOrganisationApi",
  async (organisation_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/journalCaisse/getJournalCaisseByOrganisation/${organisation_id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getJournalCaisseByIdCaisseApi = createAsyncThunk(
  "journalCaisse/getJournalCaisseByIdCaisseApi",
  async (id_caisse: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/journalCaisse/getJournalCaisseByIdCaisse/${id_caisse}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface MouvementCaisseState {
  listJournaux: JournalCaisseType[];
  journal: any;
  listJournauxByOrganisation: JournalCaisseType[];
  listJournauxByIdCaisse: JournalCaisseType[];
}

const initialState: MouvementCaisseState = {
  listJournaux: [],
  journal: {},
  listJournauxByOrganisation: [],
  listJournauxByIdCaisse: [],
};

const journalCaisseSlice = createSlice({
  name: "journalCaisse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllJournalCaisses.fulfilled, (state, action) => {
        return {
          ...state,
          listJournaux: action.payload,
        };
      })
      .addCase(getAllJournalCaissesByOrganisation.fulfilled, (state, action) => {
        return {
          ...state,
          listJournaux: action.payload,
        };
      })
      .addCase(createJournalCaisseApi.fulfilled, (state, action) => {})
      .addCase(getCurrentJournalApi.fulfilled, (state, action) => {})
      .addCase(updateJournal.fulfilled, (state, action) => {})
      .addCase(getJournalCaisseByIdApi.fulfilled, (state, action) => {
        return {
          ...state,
          journal: action.payload,
        };
      })
      .addCase(getJournalCaisseByOrganisationApi.fulfilled, (state, action) => {
        return {
          ...state,
          listJournauxByOrganisation: action.payload,
        };
      })
      .addCase(getJournalCaisseByIdCaisseApi.fulfilled, (state, action) => {
        return {
          ...state,
          listJournauxByIdCaisse: action.payload,
        };
      });
  },
});

export const ListJournaux = (state: RootState) => state.journaux.listJournaux;
export const Journal = (state: RootState) => state.journaux.journal;
export const listJournauxByCaisse = (state: RootState) =>
  state.journaux.listJournauxByIdCaisse;
export const listJournauxByOrganisation = (state: RootState) =>
  state.journaux.listJournauxByOrganisation;

export default journalCaisseSlice.reducer;
