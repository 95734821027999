import React, { useEffect, useState } from "react";
import { formadate } from "../../_App/Helpers/helpers";
import ClientType from "../../_App/Types/Entites/ClientType";

export default function ClientTable({
  Listclient,
  updateFunction,
  desactiveFunction,
  activeFunction,
  t,
  

}: any) { 
  
  const [list_client , setlist_client]=useState<ClientType[]>(Listclient)
  const [keyword , setkeyword]=useState("")

  useEffect(()=>{
    setlist_client(Listclient)
  },[Listclient])
  const handelsearch = () => {
    const lowercaseKeyword = keyword.toLowerCase();
    const listFiltrer = Listclient.filter(
      (item: any) =>
        item.nom.toLowerCase().includes(lowercaseKeyword) ||
        item.prenom.toLowerCase().includes(lowercaseKeyword)
    );

    setlist_client(listFiltrer);
    if (keyword === "") {
      setlist_client(Listclient);
    }
  };
  return (
    <div>
       <div className=" row ">
        <div className="col-4">
        <input
          type="text"
          className="form-control product-search br-30 "
          id="input-search"
          placeholder={t("chercher par mot cle")}
          onChange={(e) => setkeyword(e.target.value)}
        />
        </div>
        <div className="col-3"> 
          <button className="btn btn-primary-app" onClick={handelsearch}>{t('chercher')}</button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t('Nom')}</th>
              <th scope="col">{t('Prénom')}</th>
              <th scope="col">{t('Téléphone')}</th>
              <th scope="col">{t('Email')}</th>
              <th scope="col">{t('Date naissance')}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list_client.map((item: any) => (
              <tr key={item._id}>
                <td>{item.nom}</td>
                <td>{item.prenom}</td>
                <td>{item.mobile}</td>
                <td>{item.email}</td>
                <td>{item.date_naissance.split('T')[0]}</td>
                
              {item.actif ? (
                <><td>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        marginRight: "10px",
                        color: "green"
                      }}
                      onClick={() => desactiveFunction(item._id)}
                    >
                      {" "}
                      <i className="fas fa-power-off"></i>
                    </span>
                  </td><td>

                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                        onClick={() => updateFunction(item)}
                      >
                        <i
                          className="fas fa-edit"
                          style={{ color: "#06958d" }}
                        ></i>
                      </span>
                    </td></>
              ):
              <><td>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        marginRight: "10px",
                        color: "red"
                      }}
                      onClick={() => activeFunction(item._id)}
                    >
                      {" "}
                      <i className="fas fa-power-off"></i>
                    </span>
                  </td><td></td></>
              }
              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
