import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useEffect, useState } from "react";
import {
  findCheques,
  listCheques,
} from "../../_App/Redux/Slices/payments/PaymentSlice";
import Swal from "sweetalert2";
import RemiseTable from "../../Components/Remises/RemiseTable";
import ChequesTable from "../../Components/Caisses/Payment/ChequeTable";
import Navbar from "../../_Layouts/Navbar/Navbar";

const ChequesPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(SelectedOrganisationId);
  const cheques = useAppSelector(listCheques);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (currentOrg) {
      dispatch(findCheques(currentOrg));
    }
  }, [dispatch]);
  

  const verifcationMsg = (msg: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  return (
    <div>
      <Navbar titre={"Chèques"} />

      <div className="d-flex justify-content-end mb-3 mt-5">
        
      </div>

      {cheques && cheques.length !== 0 ? <ChequesTable /> : <p>aucun chèque</p>}
    </div>
  );
};

export default ChequesPage;
