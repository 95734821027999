import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  ConditionAchatType,
  ConditionClientType,
  ConditionProduitType,
  ProgramFideliteType,
  RemiseType,
} from "../../_App/Types/Entites/RemiseType";
import { createFideliteProgramApi } from "../../_App/Redux/Slices/client/clientSlice";
import ConditionForms from "../Fidelite&RemiseConditionsForms/ConditionForms";
import {
  createConditionAchat,
  createConditionClient,
  createConditionProduit, 
  createRemise,
  updateRemiseApi,
} from "../../_App/Redux/Slices/remise/remiseSlice";
import { formatdate } from "../../_App/Helpers/helpers";
export default function RemiseForm({
  ShowModal,
  setShowModal,
  programFidelite,
  verifcationMsg,
}: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentOrg = useAppSelector(SelectedOrganisationId);
  const [solde, setSolde] = useState(false);

  const [conditionClient, setConditionClient] =
    useState<ConditionClientType | null>(null);
  const [conditionAchat, setConditionAchat] =
    useState<ConditionAchatType | null>(null);
  const [conditionProduit, setConditionProduit] =
    useState<ConditionProduitType | null>(null);

  const { register, handleSubmit, reset, formState } = useForm<RemiseType>();

  const { errors } = formState;
  const today = new Date();
  const NextMonth = new Date(today);
  NextMonth.setMonth(today.getMonth() + 1);

  useEffect(() => {
    if (programFidelite !== null) {
      reset({
        _id: programFidelite._id,
        actif: programFidelite.actif,
        date_debut:formatdate(programFidelite.date_debut) ,
        date_fin: formatdate(programFidelite.date_fin),
        libelle: programFidelite.libelle,
        percentage: programFidelite.percentage,
        organisation_id: programFidelite.organisation_id,
        conditions:programFidelite.conditions
      });
      setSolde(programFidelite.solde);
    

    } else {
      reset({
        libelle: "",
        actif: true,
        date_debut: formatdate(today),
        date_fin: formatdate(NextMonth),
        percentage: 0,
        organisation_id: "",
        solde: false,
      });
      setSolde(false);
    }
  }, [programFidelite]);


  const onSubmit = async (data: any) => {
    let response;

    data.organisation_id = currentOrg;
    if (data._id) {
      if (await verifcationMsg(t("vous voulez modifier le programme fidelité")))
      {
      const obj = {
        remise:data ,
        conditionProduit:{...conditionProduit,remise_id:data._id},
        conditionClient:{...conditionClient,remise_id:data._id},
        conditionAchat:{...conditionAchat,remise_id:data._id},

      }
        response = await dispatch(updateRemiseApi(obj));
    } 
  }
    else {
      if (
        await verifcationMsg(t("vous voulez ajouter un programme fidelité"))
      ) {
        const response = await dispatch(createRemise(data));
        if (response.payload.success) {
          setShowModal(false);
          if (conditionAchat) {
            conditionAchat.remise_id = response.payload.data._id;
            dispatch(createConditionAchat(conditionAchat));
          }
          if (conditionClient) {
            conditionClient.remise_id = response.payload.data._id;
            dispatch(createConditionClient(conditionClient));
          }
          if (conditionProduit) {
            conditionProduit.remise_id = response.payload.data._id;
            dispatch(createConditionProduit(conditionProduit));
          }
        }
      }
    }
    if (response) {
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          libelle: "",
          actif: true,
          date_debut: new Date(),
          date_fin: new Date(),
          percentage: 0,
          organisation_id: "",
        });
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };

  
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-1"
      dialogClassName="modal-50w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {" "}
          {t("Ajouter un remise")}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="fidelite mb-5">
            <div className="row">
              <div className="col-6" style={{ textAlign: "left" }}>
                <label className="s-outline  s-outline-success  mb-4 mr-2">
                  Solde ?
                </label>
              </div>
              <div className="col-6" style={{ textAlign: "left" }}>
                <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                  <input
                    type="checkbox"
                    {...register("solde")}
                    checked={solde}
                    onChange={() => setSolde(!solde)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Libelle")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="libelle"
                    {...register("libelle")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Pourcentage")}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.libelle ? "is-invalid" : ""
                    }`}
                    id="points"
                    {...register("percentage")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Date début")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    style={{
                      background: "#f1f2f3",
                      border: "none",
                      width: "100%",
                      height: "auto",
                    }}
                    id="datepicker"
                    {...register("date_debut")}
                  />{" "}
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="nom">
                    {" "}
                    <span style={{ color: "red" }}>*</span>
                    {t("Date fin")}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    style={{
                      background: "#f1f2f3",
                      border: "none",
                      width: "100%",
                      height: "auto",
                    }}
                    id="datepicker"
                    {...register("date_fin")}
                  />{" "}
                </div>
              </div>
              {!solde ? (
                <div className="col-4">
                  <div className="form-group">
                    <label>
                      Et <span style={{ color: "red" }}></span>
                    </label>
                    <input
                      className="m-2"
                      type="radio"
                      id="et"
                      {...register("conditions")}
                      value={"et"}
                    />
                    <label htmlFor="ou">
                      Ou <span style={{ color: "red" }}></span>
                    </label>
                    <input
                      className="m-2"
                      type="radio"
                      id="ou"
                      value={"ou"}
                      {...register("conditions")}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="conditions">
            <ConditionForms
              type={"remise"}
              setConditionAchat={setConditionAchat}
              setConditionClient={setConditionClient}
              setConditionProduit={setConditionProduit}
              solde={solde}
              programFidelite={programFidelite}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            className="btn btn-primary-app"
            onClick={(e) => setShowModal(false)}
          >
            {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
