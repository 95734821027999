import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import { InventaireType } from "../../../Types/Entites/Inventaire/InventaireType";
import { InventaireDetailsType } from "../../../Types/Entites/Inventaire/InventaireDetailsType";

/**
 * Get list of  stock Inventaires
 * @public
 */
export const CalculStockInventaireApi = createAsyncThunk(
  "inventaire/CalculStockInventaireApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `inventaire/CalculStockInventaireApi/${data.date_inventaire}/${data.dateD}/${data.dateF}/${data.depots}`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of  stock Inventaires
 * @public
 */
export const CalculStockInventaireArticleApi = createAsyncThunk(
  "inventaire/CalculStockInventaireArticleApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `inventaire/CalculStockInventaireArticleApi/${data.article}/${data.depots}`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * create  inventaire
 * @private
 */
export const createInventaireApi = createAsyncThunk(
  "inventaire/createInventaireApi",
  async (data: any, { rejectWithValue }) => {
    try {
   

      const response = await axios.post(
        "inventaire/createInventaireApi",  
        data,
        
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "An error occurred",
        action: error.response?.data?.action || "",
        data: error.response?.data?.data || null,
      });
    }
  }
);

export const importInventaireExcelApi = createAsyncThunk(
  "inventaire/importInventaireExcelApi",
  async (data: any, { rejectWithValue }) => {
    try {
     const {
        file,
        organisation_id,
        depots_id,
        societe_id,
        user,
      }=data
      const formData = new FormData();
      formData.append('file', file);
      formData.append('organisation_id', organisation_id);
      formData.append('depots_id', depots_id);
      formData.append('societe_id', societe_id);
      formData.append('user', user);

      const response = await axios.post(
        "inventaire/importInventaireExcelApi",  
        formData,
        
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "An error occurred",
        action: error.response?.data?.action || "",
        data: error.response?.data?.data || null,
      });
    }
  }
);
 
/**
 * Get list of  Inventaires
 * @public
 */
export const findInventaireApi = createAsyncThunk(
  "inventaire/findInventaireApi",
  async (societeID: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `inventaire/findInventaireApi/${societeID}`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of  Inventaires details
 * @public
 */
export const findInventaireDetailsApi = createAsyncThunk(
  "inventaire/findInventaireDetailsApi",
  async (societeID: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `inventaire/findInventaireDetailsApi/${societeID}`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Put list ofIn ventaires details
 * @public
 */
export const updateInventaireDetailsApi = createAsyncThunk(
  "inventaire/updateInventaireDetailsApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        `inventaire/updateInventaireDetailsApi`,data
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Put inventaire
 * @public
 */
export const confirmerInventaireApi = createAsyncThunk(
  "inventaire/confirmerInventaireApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        `inventaire/confirmerInventaireApi`,data
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);




interface InventaireState {
  liststockinventaire: any[];
  listinventaire: InventaireType[]
  listinventairedetails :{ [inventaire_id: string]: InventaireDetailsType[] };

}



const initialState:InventaireState={
  liststockinventaire: [] ,
  listinventaire: [] ,
  listinventairedetails:{}

}
export const InventaireSlice = createSlice({
  name: "inventaire",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    
    builder.addCase(CalculStockInventaireApi.fulfilled, (state, action) => {
        state.liststockinventaire= action.payload;
     });
     builder.addCase(CalculStockInventaireArticleApi.fulfilled, (state, action) => {
      state.liststockinventaire= action.payload.data;
   });
     builder.addCase(createInventaireApi.fulfilled, (state, action) => {
      state.listinventaire.splice(0, 0, action.payload.data);
     
    });
    builder.addCase(importInventaireExcelApi.fulfilled, (state, action) => {
      state.listinventaire.splice(0, 0, action.payload.data);
     
    });
    builder.addCase(findInventaireApi.fulfilled, (state, action) => {
      state.listinventaire= action.payload;
   });

   builder.addCase(findInventaireDetailsApi.fulfilled, (state, action) => {
    const groupedByInventaire = _.groupBy(
      action.payload,
      "inventaire_id"
    );
    state.listinventairedetails= groupedByInventaire;
 });
 builder.addCase(updateInventaireDetailsApi.fulfilled, (state, action) => {
  const groupedByInventaire = _.groupBy(
    action.payload.data,
    "inventaire_id"
  );
  state.listinventairedetails= groupedByInventaire;
});
builder.addCase(confirmerInventaireApi.fulfilled, (state, action) => {
  const updated_inventaire= action.payload.data;
      var index = _.findIndex(state.listinventaire, {
        _id: updated_inventaire._id,
      });
      // Replace item at index using native splice
      state.listinventaire.splice(index, 1, updated_inventaire);
});

  },
});

export const Listinventaire = (state: RootState) => state.inventairs.listinventaire;
export const Liststockinventaire = (state: RootState) => state.inventairs.liststockinventaire;
export const Listinventairedetails = (state: RootState) => state.inventairs.listinventairedetails;

export default InventaireSlice.reducer;
