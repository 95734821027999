import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../_App/Redux/hooks";
import { ListOrganisations } from "../../../_App/Redux/Slices/organisations/organisationSlice";
import { ListDepots } from "../../../_App/Redux/Slices/depot/depotSlice";
import Select from "react-select";
import { findArticleByBarCode, findReptureDeStock } from "../../../_App/Redux/Slices/article/articleSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { Spinner } from "react-bootstrap";
import SousCategorieSelecter from "../../../Shared/SousCategorie/SousCategorieSelecter";

export default function FiltreReptureStock({
  spinner,
  dispatch,
  t,
  OrganisationSelected,
  setOrganisationSelected,
  filtre,
  setfiltre,
  handlSearch,
}: any) {
  const listOrganisations = useAppSelector(ListOrganisations);
  const listDepots = useAppSelector(ListDepots);
  const [listsouscategorie, setlistsouscategorie] = useState<any[]>([]);


  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const OrganisationOptions = listOrganisations?.map((option) => ({
    value: option._id,
    label: option.nom,
  }));
  const DepotsOptions = listDepots?.map((option) => ({
    value: option._id,
    label: option?.libelle,
  }));

  useEffect(()=>{
    if( filtre.depotsSelected.length ===0){
    
      const copie = {...filtre}
      copie.depotsSelected=[listDepots[0]?._id] 
      setfiltre(copie)
       dispatch(findReptureDeStock(copie));
    
    }
  },[listDepots])

  useEffect(() => {
    setfiltre({
      ...filtre,
      selectedSousCategorie: listsouscategorie.map((item) => item._id),
    });
  }, [listsouscategorie]);
  const handleselectOrganisation = (selectedOption: any) => {
    const organisation = selectedOption.map((item: any) => item.value);

    setOrganisationSelected(organisation);
  };

  const handleselectDepots = (selectedOption: any) => {
    const depots = selectedOption.map((item: any) => item.value);
    const copie = { ...filtre };
    copie.depotsSelected = depots;

    setfiltre(copie);
  };
  const handleChange = async (e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        const resultArticle = await dispatch(findArticleByBarCode(value));
        if (resultArticle.payload) {
          const copie = { ...filtre };
          copie.article = resultArticle.payload; // Fixed the closing parenthesis
          setfiltre(copie);
        } else {
          ToastWarning("l'article n'existe pas");
        }
      }else{
        const copie = { ...filtre };
        copie.article =null // Fixed the closing parenthesis
        setfiltre(copie);
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="  bg-white mb-3" style={{ padding: "10px" }}>
      <div className="row">
        <div className="col-2">
          <label>{t("Organisations")}</label>
          <Select
            isMulti
            options={OrganisationOptions}
            isSearchable={true}
            onChange={handleselectOrganisation}
            value={OrganisationSelected?.map((item: any) =>
              OrganisationOptions?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
        <div className="col-2">
          <label>{t("Depots")}</label>
          <Select
            isMulti
            options={DepotsOptions}
            isSearchable={true}
            onChange={handleselectDepots}
            value={filtre.depotsSelected?.map((item: any) =>
              DepotsOptions?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
        <div className=" col-2">
        <SousCategorieSelecter
          listsouscategorie={listsouscategorie}
          setlistsouscategorie={setlistsouscategorie}
        />
        </div>
        <div className="col-2">
          <label>{t("code a barre ")}</label>
          {filtre?.article?.titre?.fr}
          <input
          type="text"
            className="form-control"
            onChange={(e) => {
              handleChange(e);
            }}
          />{" "}
        </div>
        <div className="col-2">
          <label>Qte Min</label>
          <input
            type="text"
            className="form-control"
            value={parseInt(filtre.min) || ""}
            onChange={(e) =>
              setfiltre({ ...filtre, min: parseInt(e.target.value) })
            }
          />
        </div>
        <div className="col-2 mt-2">
          {!spinner ? (
            <div style={{ fontSize: "30px" }} onClick={handlSearch}>
              {" "}
              <i className="fas fa-search"></i>
            </div>
          ) : (
            <div className="icon-input-col6 ">
              {" "}
              <Spinner animation="border" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
