import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import {
  createRetourEchangeStatus,
  ListOnlineOrders,
  ListOrders,
  resetRetrievedOrder,
  resetSelectedOrder,
  selectedOrder,
  selectOrder,
} from "../../../../_App/Redux/Slices/Orders/OrderSlice";
import TicketDeCaisse from "../../../../Components/Caisses/TicketDeCaisse/TicketDeCaisseModal";
import TicketCommande from "../../../../Components/Caisses/TicketDeCaisse/TicketDeCaissePaid";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import {
  addClient,
  addToPanier,
  resetReduction,
  setOperationType,
  setReduction,
  setRefundedOrder,
} from "../../../../_App/Redux/Slices/Caisse/PanierSlice";
import Swal from "sweetalert2";
import { updateArticleStockApi } from "../../../../_App/Redux/Slices/article/articleSlice";
import { DepotDefautOrganisation } from "../../../../_App/Redux/Slices/depot/depotSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";
import {
  findTicketsByOrderApi,
  ListTickets,
} from "../../../../_App/Redux/Slices/tickets/TicketSlice";
import { OrderType } from "../../../../_App/Types/Entites/Orders/OrderType";
import {
  SelectedOrganisationId,
  selectUser,
  societeId,
} from "../../../../_App/Redux/Slices/Auth/authSlice";
import { Form } from "react-bootstrap";
import { Type } from "react-toastify/dist/utils";
import { AddArticleDefectueuxApi } from "../../../../_App/Redux/Slices/articledefectueux/articledefectueuxSlice";
import Zoom from "react-medium-image-zoom";
import TicketArticle from "../../../../Components/TicketArticle/TicketArticle";

const PaidOrder = ({ retrievedOrder }: any) => {
  const navigate = useNavigate();
  const societe = useAppSelector(societeId);
  const organisation_id = useAppSelector(SelectedOrganisationId);

  const dispatch = useAppDispatch();
  const parametrage = useAppSelector(parametrageCaisse);
  const order = useAppSelector(selectedOrder);
  const { OrderId } = useParams<{ OrderId?: string }>();

  const [showModalTicket, setShowModalTicket] = useState(false);
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const [typeAction, setTypeAction] = useState("");

  const { t } = useTranslation();
  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const boxClass = parametrage && parametrage.barcodeOnly ? " light-box" : "";
  const bgClass =
    parametrage && parametrage.barcodeOnly ? " light-background" : "";
  const [refundedItems, setRefundedItems] = useState<any>([]);
  const [refundMode, setRefundMode] = useState<Boolean>(false);
  const [ticketselected, setticketselected] = useState();
  const ticket = useAppSelector(ListTickets);

  const user = useAppSelector(selectUser);
  const isOnlineSale = user?.role_id?.libelle === "onlineSale";
  const listOrders = useAppSelector(
    isOnlineSale ? ListOnlineOrders : ListOrders
  );
  const [openedOrder, setopenedOrder] = useState<OrderType | null>(null);
  const [showModelTicketArticle, setshowModelTicketArticle] = useState(false);
  const [listTicket, setlistTicket] = useState<any[]>([]);
  useEffect(() => {
    if (OrderId) {
      setopenedOrder(listOrders.find((item) => item._id === OrderId) || null);

      dispatch(findTicketsByOrderApi(OrderId));
    }
  }, [OrderId, listOrders]);

  // Handle adding/removing items to/from the refundedItems array
  const handleItemCheck = (item: any, isChecked: boolean) => {
    if (isChecked) {
      const calculQuantitereste = ticket
        .map((t) =>
          t.ticket_order.items.reduce(
            (acc, e) =>
              e.article._id === item.article._id ? acc + e.quantity : acc,
            0
          )
        )
        .reduce((total, quantity) => total + quantity, 0);

      setRefundedItems([
        ...refundedItems,
        {
          ...item,
          ...{ Qteretour: calculQuantitereste },
          ...{ type: "", note: null, image: null },
        },
      ]);
    } else {
      setRefundedItems(
        refundedItems.filter((i: any) => i.article._id !== item.article._id)
      );
    }
  };
  const changeQteEchange = (index: number, Qteretour: number) => {
    const element = openedOrder?.items[index];
    const findindex = refundedItems.findIndex(
      (item: any) => item?.article?._id === element?.article._id
    );

    if (findindex !== -1) {
      const copie = [...refundedItems];
      const calculQuantitereste = ticket
        .map((t) =>
          t.ticket_order.items.reduce(
            (acc, e) =>
              e.article._id === copie[findindex].article._id
                ? acc + e.quantity
                : acc,
            0
          )
        )
        .reduce((total, quantity) => total + quantity, 0);
      if (Qteretour <= calculQuantitereste) {
        copie[findindex].Qteretour = Qteretour;
        setRefundedItems(copie);
      } else {
        ToastWarning(
          "La quantité souhaitée à retourner/échanger est supérieure à la quantité achetée restante"
        );
      }
    }
  };
  const changeinfodefaut = (index: number, name: string, value: any) => {
    const element = openedOrder?.items[index];
    const findindex = refundedItems.findIndex(
      (item: any) => item?.article?._id === element?.article._id
    );

    if (findindex !== -1) {
      const copie = [...refundedItems];

      if (name === "quantite") {
        const calculQuantitereste = ticket
          .map((t) =>
            t.ticket_order.items.reduce(
              (acc, e) =>
                e.article._id === copie[findindex].article._id
                  ? acc + e.quantity
                  : acc,
              0
            )
          )
          .reduce((total, quantity) => total + quantity, 0);
        if (parseInt(value) <= calculQuantitereste) {
          copie[findindex][name] = value;
          setRefundedItems(copie);
        } else {
          ToastWarning(
            "La quantité défectueuse est supérieure à la quantité achetée restante"
          );
        }
      } else {
        copie[findindex][name] = value;
        console.log("copie", copie);
      }

      setRefundedItems(copie);
    }
  };

  const headToRetour = async (amount: any, refunded: any) => {
    Swal.fire({
      title: `Voulez vous vraiment ${typeAction} ces articles ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // save if defaut
        const findArticleDefaut = refunded
          .filter((item: any) => item.type)
          .map((item: any) => {
            return { ...item, order: openedOrder?._id };
          });
        console.log("findArticleDefaut", findArticleDefaut);

        //  ajouter   les article au depot defecteux
        const resp: any = await dispatch(
          AddArticleDefectueuxApi({
            datalistAricle: findArticleDefaut,
            organisation_id: organisation_id,
            societe_id: societe,
            order: OrderId,
          })
        ); 
       /* const listticket = resp?.payload.map((element: any) => {
          return {
            marque: element?.article?.souscategorie?.libelle?.fr,
            ref: element?.article?.articleparent?.code_article,
            taille:
              element?.article?.caracteristiques.length !== 0
                ? element?.article?.caracteristiques[0]?.value?.libelle
                : element?.article?.code_article.split("-")[2],
            couleur:
              element?.article?.caracteristiques.length !== 0
                ? element?.article.caracteristiques[1]?.value?.libelle
                : element?.article?.code_article.split("-")[1],
            prix: element?.prix_vente_ttc,
            code_a_barre: element?.codeabarre,
            qte: element?.Quantite,
            solde: null,
          };
        });
        setlistTicket(listticket);
        setshowModelTicketArticle(true);*/
        await dispatch(
          createRetourEchangeStatus({
            retourEchangeData: {
              order_id: OrderId,
              confirmed: false,
              itemsToReturn: refunded,
              itemsToExchange: [],
              organisation_id: organisation_id,
              societe_id: societe,
              date: new Date(),
              user_id: user?._id,
            },
            smsInfo: {
              mobile: "55389992", // Replace with dynamic mobile number
              message: `Your RetourEchange for ticket ID ${OrderId} has been created successfully. Confirm here: ${window.location.origin}/confirmation?orderId=${OrderId}`,
              base64Image: null, // Optional: Add base64 image string if needed
            },
          })
        );
        // Loop through refunded items and add them to the cart with negative quantities and prices
        refunded.forEach(async (item: any) => {
          if (item.type) {
            await dispatch(
              addToPanier({
                article: item.article,
                quantity: -item.quantite, // Negative quantity for returns
                prix_unitaire: item.prix_unitaire, // Negative price for returns
                prix_total: -(item.prix_unitaire * item.Qteretour), // Total price negative
                paid_quantity: 0,
                type: "defaut",
                note: item.note,
                remise: item.remise || 0,
                remiseClient: 0,
                prixNet: -(
                  item.prix_unitaire *
                  item.Qteretour *
                  (item.remise / 100)
                ), // Negative net price
              })
            );
          }
          const qterestante =
            Number(item.Qteretour) - (Number(item.quantite) || 0);
          console.log("qterestante", qterestante);
          if (qterestante > 0) {
            await dispatch(
              addToPanier({
                article: item.article,
                quantity: -qterestante, // Negative quantity for returns
                prix_unitaire: item.prix_unitaire, // Negative price for returns
                prix_total: -(item.prix_unitaire * qterestante), // Total price negative
                paid_quantity: 0,
                type: "",
                remise: item.remise || 0,
                remiseClient: 0,
                prixNet: -(
                  item.prix_unitaire *
                  item.Qteretour *
                  (item.remise / 100)
                ), // Negative net price
              })
            );
          }
        });

        // Reset refunded items array and refund mode
        setRefundedItems([]);
        setRefundMode(false);

        dispatch(setRefundedOrder(openedOrder));
        dispatch(resetSelectedOrder());
        dispatch(
          setOperationType({ type: typeAction, refunded: refundedItems })
        );
        dispatch(addClient(openedOrder?.client_id));

        navigate("/caisse");
      }
    });
  };

  return (
    <div>
      <div className="cart-header">
        <div className="widget-message" style={{ padding: "10px" }}>
          <div className="widget-title mb-10">
            <h5>{t("Commande") + openedOrder?.order_number}</h5>
            <button
              className="delete-button"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              onClick={() => {
                dispatch(resetSelectedOrder());
                dispatch(resetRetrievedOrder());
                dispatch(resetReduction());
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      {parametrage && openedOrder ? (
        parametrage.barcodeOnly ? (
          <div className=" row widget-content searchable-container list full-width">
            <div className="searchable-items list full-width">
              <div className="items items-header-section item-content full-width">
                {refundMode && <div className="user-profile col- "></div>}
                <div className="user-email col-1">
                  <h4 onClick={() => console.log(refundedItems)}>Article</h4>
                </div>
                <div className="user-email   text-center ml-5 col-1">
                  <h4>{t("Prix")}</h4>
                </div>
                <div className="user-email   text-center col-1">
                  <h4>{t("Remise")}</h4>
                </div>
                <div className="user-email  text-center col-1">
                  <h4> {t("Quantité")}</h4>
                </div>
                {refundMode && (
                  <div className="user-email  text-center col-1">
                    <h4> {t("Quantité retour/echange")}</h4>
                  </div>
                )}
                <div className="user-email  text-center col-1">
                  <h4> {t("Total")}</h4>
                </div>
              </div>

              {refundMode && (
                <div className=" row">
                  <div className="user-email  text-center col-1"></div>
                  <div className="user-email  text-center col-4"></div>
                </div>
              )}
              {ticket.map((itemT: any, i: number) => (
                <div>
                  {console.log("itemT", itemT)}

                  <strong className="warning fs-25">{itemT.process}</strong>
                  {itemT.ticket_order.items.map((item: any, index: number) => (
                    <>
                      <div
                        className=" row items items-header-section item-content"
                        key={index}
                      >
                        {itemT.process !== "Retour" &&
                          itemT.process !== "Echange" &&
                          refundMode && (
                            <div className="user-profile col-">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleItemCheck(item, e.target.checked)
                                }
                                value={refundedItems.find(
                                  (e: any) => e.article._id === item.article._id
                                )}
                              />
                            </div>
                          )}
                        <div className="user-email col-1">
                          <div className="user-profile">
                            <Zoom>
                              <img
                                alt=""
                                src={
                                  item.article.image && item.article.image[0]
                                    ? process.env.REACT_APP_API_PUBLIC_URL +
                                      "article/" +
                                      item.article.image[0]
                                    : "/assets/assets/img/no-image.jpg"
                                }
                                width="100"
                              />
                            </Zoom>

                            <div className="user-meta-info">
                              <p className="user-name" data-name="Alan Green">
                                {item.article.titre.fr}
                              </p>
                              <p
                                className="user-work"
                                data-occupation="Web Developer"
                              >
                                {item.article.caracteristiques
                                  .map((caracteristique: any) =>
                                    caracteristique?.value?.libelle
                                      ? caracteristique?.value?.libelle + " "
                                      : caracteristique?.value + " "
                                  )
                                  .join("")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="user-email col-1 ml-5 text-center">
                          {item.prix_unitaire}
                        </div>
                        <div className="user-email col-1  text-center">
                          {item.remise || 0}%
                        </div>
                        <div className="user-email  col-1  text-center col-1">
                          <p>{item.quantity}</p>
                        </div>{" "}
                        {itemT.process !== "Retour" &&
                          itemT.process !== "Echange" &&
                          refundMode && (
                            <div className="user-email col-1  text-center col-1">
                              <input
                                type="number"
                                value={
                                  refundedItems.find(
                                    (e: any) =>
                                      e.article._id === item.article._id
                                  )?.Qteretour || 0
                                }
                                onChange={(e) =>
                                  changeQteEchange(
                                    index,
                                    Number(e.target.value)
                                  )
                                }
                                disabled={
                                  !refundedItems.find(
                                    (e: any) =>
                                      e.article._id === item.article._id
                                  )
                                }
                              />
                            </div>
                          )}
                        <div className="user-email text-center col-1">
                          <p>
                            {(
                              item.prix_unitaire *
                              item.quantity *
                              ((100 - (item.remise || 0)) / 100)
                            ).toFixed(3) + parametrage.defaultCurrency}
                          </p>
                        </div>
                        {refundedItems.find(
                          (e: any) => e.article._id === item.article._id
                        ) &&
                          itemT.process !== "Retour" &&
                          itemT.process !== "Echange" && (
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="user-email  text-center col-3">
                                <label>{t("Note")}</label>
                                <textarea
                                  className="form-control"
                                  onChange={(e) => {
                                    changeinfodefaut(
                                      index,
                                      "note",
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    refundedItems.find(
                                      (e: any) =>
                                        e.article._id === item.article._id
                                    )?.note || ""
                                  }
                                />
                              </div>
                              <div className="user-email  text-center mt-4 col-2">
                                <Form.Check
                                  // prettier-ignore
                                  type="switch"
                                  id="custom-switch"
                                  label="article défectueux"
                                  onChange={(e) => {
                                    // Safely find the item in refundedItems array
                                    const itemToUpdate = refundedItems.find(
                                      (e: any) =>
                                        e.article._id === item.article._id
                                    );

                                    if (itemToUpdate) {
                                      changeinfodefaut(
                                        index,
                                        "type",
                                        !itemToUpdate.type
                                      );
                                    }
                                  }}
                                  value={
                                    refundedItems.find(
                                      (e: any) =>
                                        e.article._id === item.article._id
                                    )?.type || false
                                  }
                                />
                              </div>

                              {refundedItems.find(
                                (e: any) => e.article._id === item.article._id
                              ).type && (
                                <>
                                  <div className="user-email  text-center col-2">
                                    <label>{t("Quantite defaut")}</label>
                                    <input
                                      type="number"
                                      className="form-control "
                                      onChange={(e) => {
                                        changeinfodefaut(
                                          index,
                                          "quantite",
                                          e.target.value
                                        );
                                      }}
                                      value={
                                        refundedItems.find(
                                          (e: any) =>
                                            e.article._id === item.article._id
                                        )?.quantite
                                      }
                                    />
                                  </div>
                                  <div className="user-email  text-center col-2">
                                    <label>{t("Image")}</label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      onChange={(e) => {
                                        const file = e.target.files
                                          ? e.target.files[0]
                                          : null;
                                        if (file) {
                                          changeinfodefaut(
                                            index,
                                            "image",
                                            file
                                          ); // Pass the file object instead of value
                                        }
                                      }}
                                      // Ensure that this value is managed correctly for file inputs (it should be an empty string or null)
                                      accept="image/*" // Optional: restrict to image files only
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        <div className="row mt-5">
                          <div className="col-4 row">
                            <span>
                              <strong>{t("Note")}: </strong>
                              <span>{item?.note}</span>
                            </span>
                          </div>
                          {itemT?.ticket_order?.defaut !== null &&
                            itemT?.ticket_order?.defaut?.length !== 0 && (
                              <>
                                {}
                                <div className="col-3">
                                  <span>
                                    {t("Nombre article defectueux : ")}{" "}
                                    {
                                      itemT?.ticket_order?.defaut?.find(
                                        (d: any) =>
                                          d.article === item.article._id
                                      )?.Quantite
                                    }
                                  </span>
                                </div>
                                {itemT?.ticket_order?.defaut?.find(
                                  (d: any) => d.article === item.article._id
                                )?.image !== null && (
                                  <div className="col-4">
                                    <Zoom>
                                      <img
                                        alt=""
                                        src={
                                          itemT?.ticket_order?.defaut?.find(
                                            (d: any) =>
                                              d.article === item.article._id
                                          )?.image
                                            ? process.env
                                                .REACT_APP_API_PUBLIC_URL +
                                              "articledefectueux/" +
                                              itemT?.ticket_order?.defaut?.find(
                                                (d: any) =>
                                                  d.article === item.article._id
                                              )?.image
                                            : "/assets/assets/img/no-image.jpg"
                                        }
                                        width="50"
                                      />
                                    </Zoom>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </>
                  ))}
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      setShowModalTicket(true);
                      setticketselected(itemT);
                    }}
                  >
                    {t("Ticket")}
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          openedOrder?.items.map((item: any, index: number) => (
            <div className={"widget-profile"} key={index}>
              <div className={"w-profile-view" + boxClass}>
                <div className="col-6">
                  <div
                    className="w-profile-content"
                    style={{ display: "flex" }}
                  >
                    <img
                      src={
                        item.image && item.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "/article/" +
                            item.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="admin-profile"
                      className="img-fluid"
                    />
                    <div>
                      <h6
                        style={{
                          fontSize:
                            parametrage && parametrage.barcodeOnly
                              ? "30px"
                              : "14px",
                          fontWeight: "lighter",
                        }}
                      >
                        {item.article.titre.fr}
                      </h6>
                      {item.article.caracteristiques.length ? (
                        <p>
                          {item.article.caracteristiques.map(
                            (caracteristique: any) =>
                              caracteristique?.value?.libelle
                                ? caracteristique?.value?.libelle + " "
                                : caracteristique?.value + " "
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{
                    alignItems: "center",
                    justifyContent: "right",
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <div
                    className="w-profile-links"
                    style={{
                      padding: "0",
                      alignItems: "center",
                    }}
                  >
                    <span className={"w-p-link" + btnClass}>
                      <p>{item.quantity}</p>
                    </span>

                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <p>{item.prix_total}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )
      ) : null}
      {openedOrder?.type !== "online" && (
        <div className="action-area">
          {refundMode ? (
            <button
              className="btn btn-danger"
              onClick={() => setRefundMode(!refundMode)}
            >
              {t("Annuler ")}
              {typeAction}
            </button>
          ) : (
            <>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setRefundMode(!refundMode);
                  setTypeAction("Echange");
                }}
              >
                {t("Echange")}
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setRefundMode(!refundMode);
                  setTypeAction("Retour");
                }}
              >
                {t("Retour")}
              </button>
            </>
          )}

          {refundMode && refundedItems.length > 0 && (
            <button
              className="btn btn-warning"
              onClick={
                () => headToRetour(0, refundedItems) // amount is 0 for returns
              }
            >
              {typeAction} les articles sélectionnés
            </button>
          )}
        </div>
      )}

      <TicketCommande
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        order_id={openedOrder?._id}
        ticket={ticketselected}
      />
      <TicketArticle
        t={t}
        ShowModal={showModelTicketArticle}
        setShowModal={setshowModelTicketArticle}
        tickets={listTicket}
        stateQte={false}
      />
    </div>
  );
};

export default PaidOrder;
