import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { JournalCaisseType } from "../../../Types/Entites/JournalCaisseType";
import { OrganisationType } from "../../../Types/Entites/OrganisationType";
import { User } from "../../../Types/Entites/User";
import { LoginDataType } from "../../../Types/Forms/LoginDataType";
import { SessionJournalDataType } from "../../../Types/Forms/SessionJournalDataType";
import { UserMacAdressDataType } from "../../../Types/Forms/UserMacAdressDataType";
import { RootState } from "../../store";
import {
  createJournalCaisseApi,
  updateFondCourantApi,
  updateJournal,
} from "../journalCaisse/journalCaisseSlice";
import {
  createOrganisationApi,
  deleteUserFromOrganisationApi,
} from "../organisations/organisationSlice";
import {
  clotureSessionApi,
  createSessionJournalApi,
} from "../sessionJournal/sessionJournalSlice";
import { createUserAddressMacApi } from "../userMacAdress/userAddressMacSlice";
import { updateOrganisationApi } from "../organisations/organisationSlice";

/**
 * Reset Password function.
 * @public
 */

export const updatePasswordApi = createAsyncThunk(
  "auth/updatePasswordApi",
  async (
    { token, newPassword }: { token: string | null; newPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`auth/reset-password/${token}`, {
        newPassword,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Send Mail Reset Password function.
 * @public
 */

export const initiateResetPasswordApi = createAsyncThunk(
  "auth/initiateResetPasswordApi",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await axios.post("auth/initiate-reset-password", {
        email,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * login function.
 * @public
 */

export const loginApi = createAsyncThunk(
  "auth/loginApi",
  async (data: LoginDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("auth/login", data);

      const organisations = response.data.user?.organisations;
      const selected_organisation = response.data.user?.selected_organisation;
      const societe_id = response.data.user?.societe_id;
      const id_selected_organisation =
        response.data.user?.selected_organisation._id;
      const caisse = response.data.caisse;

      return {
        ...response.data,
        organisations,
        selected_organisation,
        id_selected_organisation,
        societe_id,
        caisse,
      };
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const logoutApi = createAsyncThunk(
  "auth/logoutApi",
  async (_, { getState, rejectWithValue }) => {
    try {
      const userId = (getState() as RootState).auth.user?._id;

      if (!userId) {
        return rejectWithValue({
          success: false,
          message: "L'ID de l'utilisateur est requis pour la déconnexion.",
        });
      }

      const response = await axios.post("auth/logout", { userId });

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const removeOrganisationFromUser = createAction<OrganisationType>(
  "auth/removeOrganisation"
);

export const addOrganisationToUser = createAction<OrganisationType>(
  "auth/addOrganisation"
);

const initialState: {
  isLoggedIn: boolean;
  caisse: UserMacAdressDataType | null;
  journalCaisse: JournalCaisseType | null;
  sessionJournal: SessionJournalDataType | null;
  precedentJournalCaisse: JournalCaisseType | null;
  user: User | null;
  token: string | null;
  organisations: [];
  langue: string;
} = {
  isLoggedIn: false,
  caisse: null,
  journalCaisse: null,
  sessionJournal: null,
  precedentJournalCaisse: null,
  user: null,
  token: null,
  organisations: [],
  langue: "fr",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPrecedentJournalCaisse: (state, action) => {
      state.precedentJournalCaisse = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    resetPasswordInitiated: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    },
    updatePasswordInitiated: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    },
    updateUserLang: (state, action) => {
      if (state.user) state.user.langue = action.payload;
    },
    changeConnectOrganisation: (state, action) => {
      const findindex = state.user?.organisations?.findIndex(
        (item) => item._id === action.payload._id
      );
      if (findindex && findindex !== -1) {
        if (state.user) {
          state.user.organisations=state.user.organisations?.filter(item=> item._id !== action.payload._id);
          state.user.organisations?.unshift(action.payload);
          state.user.selected_organisation = action.payload;

        }
      }
    },
    resetAuthState: (state) => initialState,
    setError: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(loginApi.fulfilled, (state, action) => {
      if (action.payload.action === "GET_CODE_PIN") {
        state.isLoggedIn = false;
        state.token = null;
        state.user = null;
      } else {
        state.isLoggedIn = true;
        state.token = action.payload.token;
        state.user = {
          ...action.payload.payload.user,
        };
        state.caisse = {
          ...action.payload.payload.caisse,
        };
      }
    });
    builder.addCase(initiateResetPasswordApi.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    });

    builder.addCase(updatePasswordApi.fulfilled, (state, action) => {
      if (action.payload?.action === "PASSWORD_UPDATED") {
        console.log("Password Updated Successfully");
      }
    });
    builder.addCase(createOrganisationApi.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.user?.organisations?.push(action.payload.data);
        addOrganisationToUser(action.payload.data);
      }
    });
    builder.addCase(createUserAddressMacApi.fulfilled, (state, action) => {
      state.caisse = action.payload.data;
    });
    builder.addCase(
      deleteUserFromOrganisationApi.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          const organisationToRemove = action.payload.data;
          if (state.user?.organisations) {
            state.user.organisations = state.user.organisations.filter(
              (org) => org._id !== organisationToRemove._id
            );
          }
          removeOrganisationFromUser(organisationToRemove);
        }
      }
    );
    builder.addCase(createJournalCaisseApi.fulfilled, (state, action) => {
      state.journalCaisse = action.payload.data;
    });
    builder.addCase(createSessionJournalApi.fulfilled, (state, action) => {
      state.sessionJournal = action.payload.data;
    });
    builder.addCase(updateJournal.fulfilled, (state, action) => {
      state.journalCaisse = action.payload.data;
    });
    builder.addCase(updateFondCourantApi.fulfilled, (state, action) => {
      state.journalCaisse = action.payload.data;
    });

    builder.addCase(clotureSessionApi.fulfilled, (state, action) => {
      state.sessionJournal = action.payload.data;
    });
    builder.addCase(logoutApi.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    });
    builder.addCase(updateOrganisationApi.fulfilled, (state, action) => {
      const updatedOrganisation = action.payload.data;
      const user = state.user;

      if (user && user.organisations) {
        const index = user.organisations.findIndex(
          (org) => org._id === updatedOrganisation._id
        );

        if (index !== undefined && index !== -1) {
          user.organisations[index] = updatedOrganisation;
        }
      }
    });
  },
});

export const { setLoggedIn, setError, resetAuthState, updateUserLang,changeConnectOrganisation } =
  authSlice.actions;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectUser = (state: RootState) => state.auth.user;
export const currentCaisse = (state: RootState) => state.auth.caisse;

export const selectUserId = (state: RootState) => state.auth.user?._id;
export const selectToken = (state: RootState) => state.auth.token;
export const SelectedOrganisations = (state: RootState) =>
  state.auth.user?.organisations;
export const SelectedOrganisation = (state: RootState) =>
  state.auth.user?.selected_organisation;
export const SelectedOrganisationId = (state: RootState) =>
  state.auth.user?.selected_organisation?._id;

export const SelectedRole = (state: RootState) =>
  state.auth.user?.role_id?.redirectTo;

export const societeId = (state: RootState) => state.auth.user?.societe_id;
export const userLang = (state: RootState) => state.auth.user?.langue;

export const selectCaisse = (state: RootState) => state.auth.caisse;

export const selectJournalCaisse = (state: RootState) =>
  state.auth.journalCaisse;
export const selectedSessionJournal = (state: RootState) =>
  state.auth.sessionJournal;

export const selectedAutorisations = (state: RootState) =>
  state.auth.user?.autorisations;

export default authSlice.reducer;
