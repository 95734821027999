import React, { useEffect, forwardRef, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  getOrderByIdApi,
  order,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  findPaymentsByOrderApi,
  findPaymentsByTicketApi,
  listOperationsByPaymentMethod,
  listPaymentOperations,
} from "../../../_App/Redux/Slices/payments/PaymentSlice";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { SelectedOrganisation } from "../../../_App/Redux/Slices/Auth/authSlice";
import Barcode from "react-barcode";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import _ from "lodash";
import { findTicketsByOrderApi, getTicketById } from "../../../_App/Redux/Slices/tickets/TicketSlice";

const TicketDeCaissePaid = ({ ShowModal, setShowModal, order_id ,ticket }: any) => {
  const formatDate = ({ date }: { date: string }) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const mainOrder = useAppSelector(order);

  const formatTime = ({ date }: { date: string }) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(date).toLocaleTimeString(undefined, options);
  };
  const orderItemsGroupedByTva = _.groupBy(ticket?.ticket_order?.items, "article.tva");
  const currentDate = new Date();
  const paymentDetails = useAppSelector(listOperationsByPaymentMethod);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ticket) {
      dispatch(findPaymentsByTicketApi(ticket?._id));
    }
  
  }, [ticket]);


  const componentRef = useRef<HTMLDivElement>(null);
  const magasin = useAppSelector(SelectedOrganisation);
  const parametrage = useAppSelector(parametrageCaisse);
  const handlePrint = () => {
    // Access the hidden iframe and trigger its print method
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      printFrame.contentWindow?.print();
    }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="ticket-modal"
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <div ref={componentRef}>
        <div className="container-ticket" id="print-content">
          <div className="receipt_header">
            <h1>
              Ticket de caisse <span>{ticket?.ticket_order?.societe_id?.nom}</span>
            </h1>
            <h2>
              {ticket?.ticket_order?.organisation_id?.description ||""} <span>{"Tel: " + (ticket?.ticket_order?.societe_id?.tel ||'')}</span>
            </h2>
          </div>

          <div className="row">
            <div className="col-4" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Numro</p>
              <p className="inv-email-address">{ticket?.ticket_order?.order_number}</p>
            </div>

            <div className="col-4" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Caissier</p>
              <p className="inv-email-address">
                {(ticket?.ticket_order?.user_id?.nom || "") +
                  " " +
                  (ticket?.ticket_order?.user_id?.prenom || "")}
              </p>
            </div>
            <div className="col-4" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Vendeur</p>
              <p className="inv-email-address">
                {(ticket?.ticket_order?.vendeur?.nom || "") +
                  " " +
                  (ticket?.ticket_order?.vendeur?.prenom || "")}
              </p>
            </div>
          </div>

          <div className="receipt_body">
            <div className="date_time_con">
              <div className="date">
                {" "}
                {formatDate({
                  date: currentDate.toISOString(),
                })}{" "}
              </div>
              <div className="time">
                {" "}
                {formatTime({ date: new Date().toISOString() })}
              </div>
            </div>

            <div className="ticket-items">
              <table
                style={{
                  whiteSpace: "normal",
                  borderBottom: "1px dashed #000",
                }}
              >
                <thead>
                  <tr>
                    <th>Désignation</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ticket?.ticket_order
                    ? ticket?.ticket_order?.items.map((item: any) => {
                        return (
                          <tr key={item.article._id}>
                            <td>
                              <b></b> {item.quantity + "x "}
                              {item.article.titre.fr}
                              {item.article.caracteristiques &&
                              item.article.caracteristiques.length
                                ? item.article.caracteristiques
                                    .map((caracteristique: any) =>
                                      caracteristique.value.libelle
                                        ? caracteristique.value.libelle
                                        : caracteristique.value
                                    )
                                    .join(", ") // joining values to separate multiple characteristics
                                : null}
                            </td>
                            <td>{(item.prix_total)?.toFixed(3)}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>

                <tfoot>
                  <tr></tr>
                  {/* {reduction() > 0 ? (
                    <tr>
                      <td>Reduction</td>

                      <td> {-reduction()} </td>
                    </tr>
                  ) : null} */}
                  <tr>
                    <td>Total</td>

                    <td> {Number(ticket?.ticket_order?.total)?.toFixed(3)} </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {ticket?.ticket_order?.items.map((item:any, index:any) =>
                        item.remise > 0 && item.remise !== 100 ? (
                          <div
                            className="row"
                            key={index}
                            style={{
                              borderBottom: "1px dashed #000",
                            }}
                          >
                            <div className="col-8" style={{ fontSize: "12px" }}>
                              <p style={{ textAlign: "left" }}>
                                {item.article.titre.fr}
                              </p>
                            </div>
                            <div className="col-4" style={{ fontSize: "12px" }}>
                              <p>{item.remise + "%"}</p>
                            </div>
                          </div>
                        ) : null
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      {ticket?.ticket_order?.items.map((item:any, index:any) =>
                        item.remiseClient > 0 ? (
                          <div
                            className="row"
                            key={index}
                            style={{
                              borderBottom: "1px dashed #000",
                            }}
                          >
                            <div className="col-8" style={{ fontSize: "12px" }}>
                              <p style={{ textAlign: "left" }}>Remise client</p>
                            </div>
                            <div className="col-4" style={{ fontSize: "12px" }}>
                              <p>{item.remiseClient + "%"}</p>
                            </div>
                          </div>
                        ) : null
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Remise</td>
                    <td></td>
                    <td></td>
                  </tr> */}
                  <tr style={{ fontWeight: "bold", fontSize: "20px" }}>
                    <td>Total Net</td>

                    <td>{Number(ticket?.ticket_order?.totalNet)?.toFixed(3)} </td>
                  </tr>

                  <>
                    <tr>
                      <td style={{ textAlign: "left" }} colSpan={4}>
                        Réglement(s)
                      </td>
                    </tr>
                    {Object.entries(paymentDetails).map(([key, value]) => (
                      <tr key={key}>
                        <td> {key} </td>

                        <td>
                          {value.reduce((acc, item) => {
                            return acc + item.amount;
                          }, 0)}
                        </td> 
                      </tr>
                    ))}

                    {Object.entries(paymentDetails).map(([key, value]) => (
                     ( key === "Espéces" )&&
                     (
                        <tr key={key}>
                        <td> rendu {key} </td>

                        <td>
                          {value.reduce((acc, item) => {
                            return acc + (Number(item?.rendu) || 0);
                          }, 0)}
                        </td>
                      </tr>
                      )
                     
                    ))}
                  </>
                </tfoot>
              </table>
              {Object.entries(orderItemsGroupedByTva).map(
                ([key, items]: any) => (
                  <div
                    className="row"
                    key={key}
                    style={{
                      borderBottom: "1px dashed #000",
                      margin: "2px",
                    }}
                  >
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p className="inv-street-addr mb-0">Taxe</p>
                      <p className="inv-email-address">TVA</p>
                    </div>
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p className="inv-street-addr mb-0">Base HT</p>
                      <p className="inv-email-address">
                        {items
                          .reduce(
                            (acc: any, val: any) =>
                              acc +
                              val.article.prix_vente_ht * val.quantity -
                              val.article.prix_vente_ht *
                                val.quantity *
                                (val.remise / 100) -
                              val.article.prix_vente_ht *
                                val.quantity *
                                (val.remiseClient / 100),
                            0
                          )
                          .toFixed(3)}
                      </p>
                    </div>
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p
                        className="inv-street-addr mb-0"
                        onClick={() => console.log(key)}
                      >
                        Taux
                      </p>
                      <p className="inv-email-address">
                        {key === "undefined" ? 0 : key}
                      </p>
                    </div>
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p className="inv-street-addr mb-0">Montant</p>
                      <p className="inv-email-address">
                        {(
                          (items.reduce(
                            (acc: any, val: any) =>
                              acc + val.article.prix_vente_ht,
                            0
                          ) *
                            Number(key)) /
                          100
                        ).toFixed(3)}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              className="barcode"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {ticket && <Barcode value={ticket?.ticket_order?.order_number} />}
            </div>
          </div>
          <div className="receipt-footer mt-2">
            <p style={{ textAlign: "center" }}>
              {parametrage && parametrage.message_promo}
            </p>
          </div>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            className="btn-info"
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
      {/* Hidden iframe for printing */}
      <iframe
        id="print-frame"
        title="print-frame"
        style={{ display: "none" }}
        onLoad={() => handlePrint()}
      />
    </Modal>
  );
};

export default TicketDeCaissePaid;
