import { string, boolean, object, mixed } from "yup";

export const SousCategorieSchema = object({
  libelle: object().shape({
    fr: string().required("Libellé (fr) obligatoire!!"),
    abr: string()
  }),

  image: mixed().optional().nullable(),
  categorie_id: string().optional().nullable(),
  minimalStock: mixed().optional().nullable(),

});
