import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  findGratuitesByOrganisations,
  findRemisesByOrganisation,
  ListGratuite,
  ListRemises,
} from "../../../_App/Redux/Slices/remise/remiseSlice";
import {
  findNiveauxFidelite,
  findZonesByOrganisation,
} from "../../../_App/Redux/Slices/client/clientSlice";
import { Navbar } from "react-bootstrap";
import RemiseTable from "../../../Components/Remises/RemiseTable";
import RemiseForm from "../../../Components/Remises/RemiseForm";
import GratuiteForm from "../../../Components/Remises/GratuiteForm";
import GratuiteTable from "../../../Components/Remises/GratuiteTable";

const GratuitePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(null);

  const gratuites = useAppSelector(ListGratuite);

  useEffect(() => {
    if (currentOrg && societe) {
      dispatch(findGratuitesByOrganisations(currentOrg));
      dispatch(findNiveauxFidelite(societe));
      dispatch(findZonesByOrganisation(currentOrg));
    }
  }, [dispatch, showModal]);

  const updateFunction = (item: any) => {
    setItem(item);
    setShowModal(true);
  };
  useEffect(()=>{
    if(!showModal){
      setItem(null)
    }
  },[showModal])
  const verifcationMsg = (msg: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  return (
    <div>
      <Navbar />

      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className="btn btn-primary-app"
          onClick={() => setShowModal(true)}
        >
          {t("Ajouter un gratuite")}
        </button>
      </div>
      <GratuiteForm
        t={t}
        verifcationMsg={verifcationMsg}
        ShowModal={showModal}
        setShowModal={setShowModal}
        programFidelite={item}
      />
      {gratuites && gratuites.length !== 0 ? (
        <GratuiteTable
          t={t}
          ProgramsFidelites={gratuites}
          setItem={setItem}
          updateFunction={updateFunction}
        />
      ) : (
        <p>aucun gratuité</p>
      )}
    </div>
  );
};

export default GratuitePage;
