import { isArray } from "lodash";
import React, { useState, useEffect } from "react";

import { Produit } from "../../../_App/Types/Entites/Produit";

export default function ProductItem({
  t,
  product,
  openEditForm,
  desactiveitem,
  setShowModalProducTarif,
  setProductselected,
  activeitem
}: any) {


  const addTarifproduct = (produit: Produit) => {
    setProductselected(produit._id);
    setShowModalProducTarif(true);
  };
  return (
  
     <div className="card component-card_3 mb-2">
     <div className="card-body">
     {product.image!== null && isArray(product.image)  && product.image.length !== 0 ? (
      <><img
            src={process.env.REACT_APP_API_PUBLIC_URL + 'produits/' + product.image[0]}
            className="card-img-top"
            alt="" /></>
    ) : (
      <img
        src="assets/assets/img/no-image.jpg"
        className="card-img-top"
        alt="widget-card-2"
      />
    )}
       <h5 className="card-user_name"> {product?.titre?.fr}</h5>
       <p className="card-user_occupation">{product?.description?.fr}{' '}{product?.type}</p>



      {product.actif ? (
      <div className="row mt-2">
              <button
                className="btn btn-primary-app"
                onClick={() => {
                  desactiveitem(product._id);
                }}
              >
                {t('Desactiver')}
              </button>
              <button
                className="btn btn-primary-app mt-2"
                onClick={() => {
                  openEditForm(product);
                }}
              >
                {t('Modifer')}
              </button>
            </div>
      ):
      <div className="row mt-2">
            <h5 className="card-user_name"> {t('produit inactive')}</h5>
            <button
                className="btn btn-primary-app"
                onClick={() => {
                  activeitem(product._id);
                }}
              >
                {t('Activer')}
              </button>
    </div>
      } 
    
     </div>
   </div>
  );
}
/*<>
<div className="col-xxxl-2 col-xl-3 col-lg-6 col-12">
  <div className="card component-card_2">
    {isArray(product.image) ? (
      <img
        src={process.env.REACT_APP_API_BASE_URL + product.image[0]}
        className="card-img-top"
        alt={process.env.REACT_APP_API_BASE_URL + product.image[0]}
      />
    ) : (
      <img
        src="assets/assets/img/no-image.jpg"
        className="card-img-top"
        alt="widget-card-2"
      />
    )}

    <div className="card-body">
      <h5 className="card-title">{product.titre.fr}</h5>
      <h5 className="card-title">{product?.type}</h5>
      <div className="row">
        <div className="col-md-4">
          <button
            className="btn btn-primary"
            onClick={() => {
              openEditForm(product);
            }}
          >
            Edit
          </button>
        </div>
        <div className="col-md-4">
          {" "}
          <button
            className="btn btn-danger"
            onClick={() => {
              deleteitem(product._id);
            }}
          >
            Delete
          </button>
        </div>
      
      </div>
      <div  className="row " style={{marginTop:'10px'}}>
    
        <div className="col-md-4">
          <span
            onClick={() => {
              addTarifproduct(product);
            }}
          >
           <i className="fas fa-tags fa-lg"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</>*/