import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findCarteBySocieteApi,
  ListCartes,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import {
  findCategorieBySocieteApi,
  ListCategories,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  findSousCategorieByLibelleApi,
  findSousCategorieBySocieteApi,
  ListSousCategories,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { stockfilterApi } from "../../_App/Redux/Slices/article/articleSlice";
import {
  findProductsCaracteristiquesApiBySociete,
  ListProductCaracteristiques,
} from "../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";

export default function Filtre({
  t,
  spinner,
  handleSearch,
  setkeyword,
  type,
  settype,
  setdatafilter,
  setspinner,
  setindex,
  searchfiltre,
  selectedCarte,
  setSelectedCarte,
  selectedCategorie,
  setSelectedCategorie,
  selectedSousCategorie,
  setSelectedSousCategorie,
  categoriesOptions,
  setcategoriesdOptions,
  souscategoriesOptions,
  setsouscategoriesdOptions,
  selections,
  setSelections,
}: any) {
  const dispatch = useAppDispatch();

  const societeid = useAppSelector(societeId);
  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategories);
  const sousCategories = useAppSelector(ListSousCategories);
  const carateristique = useAppSelector(ListProductCaracteristiques);
  const [souscategorieindex, setsouscategorieindex] = useState(0);
  const [listsouscategorie, setlistsouscategorie] = useState<any[]>([]);
  const [libelle, setlibelle] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (societeid) {
      dispatch(findCarteBySocieteApi(societeid));
      dispatch(findCategorieBySocieteApi(societeid));
      const data = { societe: societeid, index: souscategorieindex };
      dispatch(findSousCategorieBySocieteApi(data));
      dispatch(findProductsCaracteristiquesApiBySociete(societeid));
    }
  }, [societeid]);
  useEffect(() => {
    if (selectedCarte?.length !== 0) {
      const listcategoriescarte = categories?.filter((item: any) =>
        selectedCarte?.includes(item.carte_id)
      );
      setcategoriesdOptions(
        listcategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [societeid]);

  useEffect(() => {
    if (selectedCarte?.length !== 0) {
      const listcategoriescarte = categories?.filter((item: any) =>
        selectedCarte?.includes(item.carte_id)
      );
      setcategoriesdOptions(
        listcategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [selectedCarte]);

  useEffect(() => {
    if (selectedCategorie?.length !== 0) {
      const ListSousCategoriescategories = sousCategories?.filter((item: any) =>
        selectedCategorie?.includes(item.categorie_id)
      );
      setsouscategoriesdOptions(
        ListSousCategoriescategories?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [selectedCategorie]);

  const CartesOptions = cartes?.map((option) => ({
    value: option._id,
    label: option.libelle.fr,
  }));
  useEffect(() => {
    const updatedOptions =
      sousCategories?.map((option) => ({
        value: option._id,
        label: option.libelle.fr,
      })) || [];

    setsouscategoriesdOptions([
      {
        value: "up",
        label: <i className="fas fa-arrow-up"></i>,
      },
      ...updatedOptions,
      {
        value: "more",
        label: <i className="fas fa-arrow-down"></i>,
      },
    ]);
  }, [sousCategories]);

  const handleSelectChangeCartes = (selectedOption: any) => {
    const cartes = selectedOption.map((item: any) => item.value);
    setSelectedCarte(cartes);
  };
  const handleSelectChangeCategories = (selectedOption: any) => {
    const categorie = selectedOption.map((item: any) => item.value);
    setSelectedCategorie(categorie);
  };
  const handleSelectChangesousCategories = (selectedOption: any) => {
    let souscategorie = selectedOption.map((item: any) => item.value);
    if (souscategorie.includes("up")) {
      const data = { societe: societeid, index: souscategorieindex - 12 };
      setsouscategorieindex(souscategorieindex - 12);
      dispatch(findSousCategorieBySocieteApi(data));
    }
    if (souscategorie.includes("more")) {
      souscategorie = souscategorie.filter((item: any) => item !== "more");
      const data = { societe: societeid, index: souscategorieindex + 12 };
      setsouscategorieindex(souscategorieindex + 12);
      dispatch(findSousCategorieBySocieteApi(data));
    }
    setSelectedSousCategorie(souscategorie);
  };

  const handleSelectcarateristique = (newSelectedOptions: any, item: any) => {
    setSelections({
      ...selections,
      [item._id]: newSelectedOptions,
    });
  };

  const showsouscategories = async (op: any) => {
    if (op === "more") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societeid,
          index: souscategorieindex + 12,
        })
      );
      setsouscategorieindex(souscategorieindex + 12);
    } else if (op === "up") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societeid,
          index: souscategorieindex - 12,
        })
      );
      setsouscategorieindex(souscategorieindex - 12);
    } else {
      // verif si selected
      const findindex = listsouscategorie.findIndex(
        (item: any) => item._id === op._id
      );
      if (findindex !== -1) {
        setlistsouscategorie(
          listsouscategorie.filter((item: any) => item._id !== op._id)

        );
         setSelectedSousCategorie( selectedSousCategorie.filter((item: any) => item !== op._id))
      } else {
         setlistsouscategorie([... listsouscategorie,  op]);
         setSelectedSousCategorie( [...selectedSousCategorie,op._id])

      }

      setIsOpen(false);
    }
  };
  const removeitem = (op: any) => {
    const findindex = listsouscategorie.findIndex(
      (item: any) => item._id=== op._id
    );
    if (findindex !== -1) {
       setlistsouscategorie(
         listsouscategorie.filter((item: any) => item._id !== op._id)
      );
   
      setSelectedSousCategorie( selectedSousCategorie.filter((item: any) => item !== op._id))

    }
  };
  const findSearch = async () => {
    setsouscategorieindex(0);
    const data = { index: 0, libelle: libelle, societe_id: societeid };
    dispatch(findSousCategorieByLibelleApi(data));
  };
  const refresh = async () => {
    setsouscategorieindex(0);
    setlibelle('')
    await dispatch(
      findSousCategorieBySocieteApi({ societe: societeid, index: 0 })
    );
  };
  return (
    <div>
      <div className="  bg-white" style={{ padding: "10px" }}>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="carte">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Carte")}
              </label>
              <Select
                isMulti
                options={CartesOptions}
                isSearchable={true}
                onChange={handleSelectChangeCartes}
                value={selectedCarte?.map((item: any) =>
                  CartesOptions?.find((cat: any) => cat.value === item)
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="categorie">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Categorie")}
              </label>
              <Select
                isMulti
                options={categoriesOptions}
                isSearchable={true}
                onChange={handleSelectChangeCategories}
                value={selectedCategorie?.map((item: any) =>
                  categoriesOptions?.find((cat: any) => cat.value === item)
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sous">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Sous categorie")}
              </label>
              <div className="custom-dropdown">
                <div
                  className="form-control row"
                  onClick={() => setIsOpen(!isOpen)}
                  style={{
                    fontSize: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }} // Adjust maxWidth as needed
                >
                  {listsouscategorie &&listsouscategorie.length >0 &&
                    listsouscategorie.map((item: any) => (
                      <span className="pointer  " key={item.id}>
                        {item?.libelle?.fr}
                        <span onClick={() => removeitem(item)}> x </span>
                      </span>
                    ))
                  }
                </div>

                {isOpen && (
                  <div className="custom-dropdown-options">
                    <div className="custom-dropdown-option">
                              <input
                                type="text"
                                value={libelle}
                                onChange={(e) => setlibelle(e.target.value)}
                              />
                              <i
                                className="fas fa-search"
                                onClick={findSearch}
                              ></i>
                               {' '}
                              <i className="fas fa-sync-alt" onClick={refresh}></i>
                            </div>
                    {souscategorieindex > 0 && (
                      <div
                        className="custom-dropdown-option"
                        onClick={() => showsouscategories("up")}
                      >
                        <i className="fas fa-arrow-up"></i>
                      </div>
                    )}
                    {sousCategories.map((option, i) => (
                      <div
                        key={i}
                        className="custom-dropdown-option"
                        onClick={() => showsouscategories(option)}
                        style={{
                          background: selectedSousCategorie.find(
                            (item: any) => item === option._id
                          )
                            ? "#eff1f1"
                            : "white",
                        }}
                      >
                        {option.libelle.fr}
                      </div>
                    ))}

                    <div
                      className="custom-dropdown-option"
                      onClick={() => showsouscategories("more")}
                    >
                      <i className="fas fa-arrow-down"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {carateristique &&
            carateristique.map((item, index) => (
              <div className="col-3" key={index}>
                <label htmlFor="sous">
                  <span style={{ color: "red" }}></span>
                  {item.libelle}
                </label>

                <Select
                  isMulti
                  options={item.valeurs?.map((v: any) => ({
                    value: { caracteristique_id: item._id, value: v },
                    label: v?.libelle || v,
                  }))}
                  isSearchable={true}
                  onChange={(newSelectedOptions) =>
                    handleSelectcarateristique(newSelectedOptions, item)
                  }
                />
              </div>
            ))}

          <div
            className="col-1 mt-4"
            style={{ fontSize: "30px" }}
            onClick={searchfiltre}
          >
            {" "}
            <i className="fas fa-search"></i>
          </div>

          <div className="col-md-4 mt-3 mb-2">
            <div className="wrapper">
              {!spinner ? (
                <div className="icon-input-col6 " onClick={handleSearch}>
                  {" "}
                  <i className="fas fa-search"></i>
                </div>
              ) : (
                <div className="icon-input-col6 " onClick={handleSearch}>
                  {" "}
                  <Spinner animation="border" />
                </div>
              )}
              <input
                type="text"
                className="w-100 form-control product-search br-30"
                id="input-search"
                placeholder={t("mot cle")}
                onChange={(e) => setkeyword(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <select          className="w-100 form-control product-search br-30 mt-3" value={type}  onChange={(e)=>settype(e.target.value)}>
              <option value={'Tout'}>Tout</option>
              <option value={'parent'}>Parents</option>
              <option value={'fils'}>Fils</option>

            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
