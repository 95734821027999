import React, { useEffect, useRef, useState } from "react";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { getOrderByIdApi } from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { findsocieteinfoApi } from "../../../_App/Redux/Slices/societe/societeSlice";
import SocieteType from "../../../_App/Types/Entites/SocieteType";
import { numberToWords } from "../../../_App/Helpers/helpers";

const OnlineOrderInvoice = ({ order_id, show, setShow }: any) => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderType | null>(null);
  const societe_id = useAppSelector(societeId);
  const invoiceRef = useRef<HTMLDivElement>(null); // Reference for the invoice section
  const [ste, setste] = useState<SocieteType | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      if (order_id && societe_id) {
        try {
          const res = await dispatch(getOrderByIdApi(order_id));
          const res1 = await dispatch(findsocieteinfoApi(societe_id));

          if (res.payload && res.payload._id) {
            setOrder(res.payload);
          }
          if (res1.payload && res1.payload._id) {
            setste(res1.payload);
          }
        } catch (error) {
          console.error("Échec de la récupération de la commande:", error);
        }
      }
    };

    fetchData();
  }, [dispatch, order_id, societe_id]);

  useEffect(() => {
    if (!show) {
      setOrder(null); // Clear order on modal hide
    }
  }, [show]);

  const handlePrint = () => {
    if (invoiceRef.current) {
      const printContent = invoiceRef.current.innerHTML;
      const originalContent = document.body.innerHTML;

      document.body.innerHTML = printContent; // Temporarily replace the entire page content with the invoice
      window.print(); // Trigger print
      document.body.innerHTML = originalContent; // Restore original content after printing
      window.location.reload(); // Refresh to ensure the page returns to its previous state
    }
  };
  const calculateTotalHT = () => {
    if (!order?.items) return 0;
    return order.items
      .reduce((total, item) => {
        const itemTotalHT = item.article.prix_vente_ht * item.quantity;
        return total + itemTotalHT;
      }, 0)
      .toFixed(3); // Keep three decimal places for precision
  };
  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      {order ? (
        <div className="row invoice layout-spacing">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="doc-container">
              <div className="invoice-container" ref={invoiceRef}>
                <div className="invoice-inbox">
                  <div className="invoice-00001">
                    <div className="content-section">
                      {/* Invoice Header */}
                      <div className="inv--head-section inv--detail-section">
                        <div className="row">
                          <div className="col-sm-6">
                            <div>
                              <h3 className="in-heading ml-0">{ste?.nom} </h3>
                              <p className="inv-client-name">
                                <strong>{ste?.tel} </strong>
                              </p>
                              <p className="inv-client-name">
                                <strong>{ste?.address} </strong>
                              </p>
                            </div>
                            {/* Client Info */}
                            <div>
                              <h3 className="in-heading ml-0">Facturé à :</h3>
                              <p className="inv-client-name">
                                <strong>Nom: </strong>
                                {order?.client_id?.nom ?? "N/A"}
                              </p>

                              <p className="inv-client-address">
                                <strong>Adresse: </strong>
                                {order?.client_id?.address ?? "N/A"}
                              </p>
                              <p className="inv-client-contact">
                                <strong>Contact: </strong>
                                {order?.client_id?.mobile ?? "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-6 text-sm-right">
                            {/* Order Details */}
                            <p className="inv-list-number">
                              <span className="inv-title">Facture : </span>{" "}
                              <span className="inv-number">
                                {order?.order_number ?? "N/A"}
                              </span>
                            </p>

                            <p className="inv-created-date">
                              <span className="inv-title">Date: </span>{" "}
                              <span className="inv-date">
                                {order?.date?.toString().split("T")[0] ?? "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Product Info */}
                      <div className="inv--product-table-section">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Réf</th>

                                <th scope="col">Article</th>
                                <th className="text-right" scope="col">
                                  Quantité
                                </th>
                                <th scope="col">Prix U. HT</th>
                                <th scope="col">Taux TVA</th>
                                <th className="text-right" scope="col">
                                  Prix U. TTC
                                </th>
                                <th className="text-right" scope="col">
                                  Prix T. TTC
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {order?.items?.map((item) => (
                                <tr key={item.article._id}>
                                  <td>{item.article?.code_article}</td>

                                  <td>{item.article.titre.fr}</td>
                                  <td className="text-right">
                                    {item.quantity}
                                  </td>
                                  <td className="text-right">
                                    {item.article?.prix_vente_ht}
                                  </td>
                                  <td className="text-right">
                                    {item.article.tva}
                                  </td>

                                  <td className="text-right">
                                    {item.article?.prix_vente_ttc}
                                  </td>
                                  <td className="text-right">{item.prixNet}</td>
                                </tr>
                              )) || (
                                <tr>
                                  <td colSpan={3} className="text-center">
                                    Aucun article trouvé.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <h6 className="mb-0">
                          Arrêtée la présente facture à la somme de :{" "}
                          {numberToWords((order?.totalNet + 8))}
                        </h6>
                      </div>
                      {/* Total Amount */}
                      <div className="inv--total-amounts">
                        <div className="row mt-4">
                          <div className="col-sm-12 text-sm-right">
                            <div className="grand-total-amount">
                              <h4 className="inv-total">
                                Net à payer HT: {calculateTotalHT()}{" "}
                              </h4>
                            </div>{" "}
                            <div className="grand-total-amount">
                              <h4 className="inv-total">TVA: 19</h4>
                            </div>{" "}
                            <div className="grand-total-title">
                              <h4 className="inv-total-title">
                                Total TTC :{" "}
                                {order?.totalNet?.toFixed(3) ?? "N/A"} TND
                              </h4>
                            </div>
                            <div className="grand-total-amount">
                              <h4 className="inv-total">
                                Net à payer TTC inclus frais de livraison:{" "}
                                {(order?.totalNet + 8).toFixed(3)}
                              </h4>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="row" style={{ justifyContent: "center" }}>
                <button
                  className="btn btn-success"
                  style={{ width: "50%" }}
                  onClick={handlePrint}
                >
                  Imprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default OnlineOrderInvoice;
