import { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import IntlTelInput from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";
import Select from "react-select";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  SelectedOrganisations,
  selectedAutorisations,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { ListUsersRoles } from "../../_App/Redux/Slices/userRoles/userRoleSlice";
import { updateUserApi } from "../../_App/Redux/Slices/users/userSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const UpdateUserForm = ({ ShowModal, setShowModal, userData }: any) => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState, setValue, watch, getValues } =
    useForm<Partial<UserDataType>>();
  const roles = useAppSelector(ListUsersRoles);

  const { errors } = formState;
  const organisations = useAppSelector(SelectedOrganisations);
  const [mobile, setMobile] = useState<string>("");
  const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>(
    []
  );
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isConge, setIsConge] = useState<boolean>(false);
  const autorisations = useAppSelector(selectedAutorisations);
  const CanActivateUser = autorisations?.CAN_ACTIVATE_USER;

  useEffect(() => {
    setValue("_id", userData?._id || "");
    setValue("nom", userData?.nom || "");
    setValue("prenom", userData?.prenom || "");
    setValue("email", userData?.email || "");
    setValue("code", userData?.code || "");
    setValue("my_code_pin", userData?.my_code_pin || "");

    setValue("password", userData?.password || "");
    setMobile(userData?.mobile || "");
    setIsActive(userData?.is_active || false);
    setIsConge(userData?.conge || false);

    setSelectedOrganisations(
      (userData?.organisations || []).map((org: { _id: string }) => org._id)
    );

    setValue("gender", userData?.gender || "");
    setValue("role_id", userData?.role_id?._id || "");
  }, [userData, setValue]);

  useEffect(() => {
    if (!ShowModal) {
      setMobile("");
    }
  }, [ShowModal]);

  const onSubmit = async (data: Partial<UserDataType>) => {
    try {
      data.organisations = selectedOrganisations.map((orgId) => ({
        _id: orgId,
      }));

      data.is_active = isActive;
      data.conge = isConge;

      if (data.nom !== undefined) {
        const response = await dispatch(updateUserApi(data as UserDataType));

        if (response.payload && response.payload.success) {
          ToastSuccess(response.payload.message);
          setShowModal(false);
        } else {
          ToastWarning(response.payload.message);
        }
      } else {
        ToastWarning("Le champ 'nom' ne peut pas être undefined.");
      }
    } catch (error) {
      ToastWarning(
        "Une erreur s'est produite lors de la mise à jour de l'utilisateur."
      );
    }
  };

  const handleActivateSwitch = () => {
    setIsActive(!isActive);
  };

  const handleCongeSwitch = () => {
    setIsConge((prevIsConge) => !prevIsConge);
  };
  useEffect(() => {}, [isConge]);
  const { t } = useTranslation();

  const langueOptions = [
    { value: "fr", label: "Français" },
    { value: "en", label: "Anglais" },
    { value: "ar", label: "Arabe" },
  ];
  const [selectedLangue, setSelectedLangue] = useState<{
    value: string;
    label: string;
  } | null>(null);
  useEffect(() => {
    const currentLangueValue = userData?.langue?.value || null;
    setValue("langue", currentLangueValue);

    setSelectedLangue(currentLangueValue !== null ? userData?.langue : null);
  }, [userData, setValue]);

  return (
    <CustomModal show={ShowModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex justify-content-between align-items-center w-100">
          <span>{t("Modifier l'utilisateur")}</span>
          <Form.Group controlId="formIsActive" className="ml-auto mb-0">
            <p className="d-flex align-items-center mb-0">
              {isActive ? (
                <i
                  className="fas fa-unlock"
                  style={{
                    color: "green",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                ></i>
              ) : (
                <i
                  className="fas fa-ban"
                  style={{
                    color: "red",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                ></i>
              )}
              {CanActivateUser && (
                <Form.Check
                  type="switch"
                  id="activate-switch"
                  label={isActive ? "Actif" : "Inactif"}
                  checked={isActive}
                  onChange={handleActivateSwitch}
                />
              )}
            </p>
          </Form.Group>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {/* <Row>
            <Col md={6}>
              <Form.Group controlId="formLangue" className="mb-3">
                <Form.Label>{t("Langue")}</Form.Label>
                <Select
                  options={langueOptions}
                  value={selectedLangue}
                  onChange={(selectedOption) => {
                    setValue(
                      "langue",
                      selectedOption?.value !== null
                        ? selectedOption?.value
                        : undefined
                    );
                    setSelectedLangue(
                      selectedOption?.value !== null ? selectedOption : null
                    );
                  }}
                  isMulti={false}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.langue?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNom">
                <Form.Label>{t("Code")} </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={getValues("code")}
                  {...register("code")}
                  disabled
                  isInvalid={!!errors.code}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formNom">
                <Form.Label>{t("code pin")}</Form.Label>
                <Form.Control
                  type="password" // Changez le type à "password"
                  placeholder="Entrez un code PIN de 4 chiffres"
                  {...register("my_code_pin", {
                    required: "Le code PIN est requis",
                    pattern: {
                      value: /^[0-9]{4}$/,
                      message: "Le code PIN doit être composé de 4 chiffres",
                    },
                  })}
                  isInvalid={!!errors.my_code_pin}
                />
               
                <Form.Control.Feedback type="invalid">
                  {errors.my_code_pin?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNom">
                <Form.Label>{t("Nom")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Nom")}
                  {...register("nom", { required: "Nom obligatoire!!" })}
                  isInvalid={!!errors.nom}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nom?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPrenom">
                <Form.Label>{t("Prénom")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Prénom")}
                  {...register("prenom", { required: "Prénom obligatoire!!" })}
                  isInvalid={!!errors.prenom}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.prenom?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>{t("Email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("Email")}
                  {...register("email", {
                    required: "Email obligatoire!!",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Veuillez saisir un email valide",
                    },
                  })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formRoleId">
                <Form.Label>{t("Role")}</Form.Label>
                <Form.Control
                  as="select"
                  {...register("role_id")}
                  isInvalid={!!errors.role_id}
                >
                  <option value="">{t("Sélectionner un rôle")}</option>
                  {roles.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.libelle}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.role_id?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Label>{t("Téléphone")}</Form.Label>
              <Form.Group controlId="formMobile">
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                  placeholder={t("Votre numéro mobile")}
                  fieldName="mobile"
                  value={mobile}
                  onPhoneNumberChange={(value, country, e, formattedValue) => {
                    setMobile(formattedValue || "");
                    setValue("mobile", formattedValue || "");
                  }}
                  style={{ width: "100%" }}
                  defaultCountry="tn"
                />
                {errors.mobile && (
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formOrganisations">
                <Form.Label>{t("Organisation")}</Form.Label>
                <Select
                  isMulti
                  options={organisations?.map((org) => ({
                    value: org._id,
                    label: org.nom,
                  }))}
                  value={organisations
                    ?.filter((org) => selectedOrganisations.includes(org._id))
                    .map((org) => ({ value: org._id, label: org.nom }))}
                  onChange={(selectedOptions: any) => {
                    const selectedOrganisations = selectedOptions.map(
                      (selectedOption: any) => selectedOption.value
                    );
                    setSelectedOrganisations(selectedOrganisations);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGender">
                <Form.Label> {t("Genre")}</Form.Label>
                <Form.Control
                  as="select"
                  {...register("gender")}
                  isInvalid={!!errors.gender}
                >
                  <option value="">{t("Sélectionner le genre")}</option>
                  <option value="homme">Homme</option>
                  <option value="femme">Femme</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formConge">
                <Form.Label className="mr-2">{t("Congé")}</Form.Label>
                <div className="d-flex align-items-center">
                  <FormCheck
                    type="switch"
                    id="conge-switch"
                    label={isConge ? "En congé" : "En travail"}
                    checked={isConge}
                    onChange={handleCongeSwitch}
                  />
                  {isConge ? (
                    <i
                      className="fas fa-umbrella-beach"
                      style={{
                        color: "#c12cdd",
                        marginLeft: "20px",
                        fontSize: "20px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-business-time"
                      style={{
                        color: "blue",
                        marginLeft: "20px",
                        fontSize: "20px",
                      }}
                    ></i>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            type="reset"
            onClick={() => setShowModal(false)}
            className="btn btn-primary-app"
          >
            {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </Form>
    </CustomModal>
  );
};

export default UpdateUserForm;
