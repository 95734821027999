import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { layout } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  Currentpage,
  ListOnlineOrders,
  ListOrders,
  TotalNet,
  TotalPages,
  findOrdersFiltreApi,
  resetSelectedOrder,
  selectOrder,
  updateOrderApi,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  selectUser,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { formatdate, statusList } from "../../../_App/Helpers/helpers";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { findPaymentsByOrderApi } from "../../../_App/Redux/Slices/payments/PaymentSlice";
import {
  addClient,
  addToPanier,
  ReservationToPanier,
  resetPanier,
  SelectedOrders,
  setClientRemise,
  setNetPriceArticle,
  setOperationType,
  setOrderid,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { useNavigate } from "react-router-dom";
import OnlineOrderInvoice from "./OnlineOrderInvoice";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import OrderStatusPopUp from "./OrderStatusPopUp";
import { findReservationByorderId } from "../../../_App/Redux/Slices/Reservation/ReservationSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { UserDataType } from "../../../_App/Types/Forms/UserDataType";
import Select, { MultiValue } from "react-select";
import {
  findUserByOrganisationApi,
  ListUsers,
} from "../../../_App/Redux/Slices/users/userSlice";
import { Spinner } from "react-bootstrap";
import Pagination from "../../../Shared/Pagination/Pagination";
import PaginationLayout from "../../../_Layouts/Pagination/PaginationLayout";
import Swal from "sweetalert2";
import {
  findTicketsByOrganisationApi,
  ordersTickets,
} from "../../../_App/Redux/Slices/tickets/TicketSlice";

const OrdersList = () => {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const parametrage = useAppSelector(parametrageCaisse);
  const navigate = useNavigate();
  const selectedOrganisation = useAppSelector(SelectedOrganisation);
  const user = useAppSelector(selectUser);

  const isOnlineSale = user?.role_id?.libelle === "onlineSale";

  const Orders = useAppSelector(isOnlineSale ? ListOnlineOrders : ListOrders);
  const currentpage = useAppSelector(Currentpage);
  const totalPages = useAppSelector(TotalPages);
  const totalNet = useAppSelector(TotalNet);

  const listusers = useAppSelector(ListUsers);
  const [index, setindex] = useState(1);
  const [loader, setloader] = useState(false);

  /*****************filtre*************** */
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  interface FiltreInterface {
    organisation: any;
    dateDebut: any;
    dateFin: any;
    listcaissiers: UserDataType[];
    listvendeur: UserDataType[];
    status: any;
    numOrder: any;
    index: any;
  }
  const [filtre, setFiltre] = useState<FiltreInterface>({
    organisation: currentOrganisation,
    dateFin: formatdate(tomorrow),
    dateDebut: formatdate(lastMonth),
    listcaissiers: [],
    listvendeur: [],
    status: "Tout",
    numOrder: null,
    index: 1,
  });
  const handleSelectChangeUsers = (selectedUsers: any) => {
    const copie = { ...filtre };
    copie.listcaissiers = selectedUsers.map((item: any) => item.value);
    setFiltre(copie);
  };
  const handleSelectChangeVendeur = (selectedUsers: any) => {
    const copie = { ...filtre };
    copie.listvendeur = selectedUsers.map((item: any) => item.value);
    setFiltre(copie);
  };
  const handleSelectMultipleOrders = async () => {
    if (selectedOrders.length) {
      await dispatch(SelectedOrders(selectedOrders));
      await dispatch(setOperationType({ type: "Pay", refunded: [] }));

      window.location.href = "/caisse";
    }
  };
  const handleSelectOrder = async (order: any) => {
    if (order.status === "reservation") {
      const findReservation = await dispatch(
        findReservationByorderId(order._id)
      );
      if (findReservation) {
        const todayDate = new Date();
        const echeanceDate = new Date(findReservation.payload?.date_echeance);

        if (todayDate <= echeanceDate) {
          if (parametrage && parametrage.barcodeOnly) {
            await dispatch(findPaymentsByOrderApi(order._id));
            await dispatch(
              ReservationToPanier({
                avance: findReservation.payload?.avance?.total,
                order_id: order._id,
                totalNet: findReservation.payload?.achat_totalNet,
                items: order.items,
                client: findReservation.payload?.infoclient.client,
              })
            );
            await dispatch(
              setClientRemise(findReservation.payload?.infoclient.client.remise)
            );
            await dispatch(setNetPriceArticle());
            // window.location.href = "/caisse";
            navigate("/caisse");
          }
        } else {
          ToastWarning("vous avez dépassé la date  d'échéance");
        }
      }
    } else if (order.status === "Payée") {
      navigate(`/PaidOrder/${order._id}`);
    } else if (order.status === "demande") {
      Swal.fire({
        title: `Commande numéro ${order.order_number}`,
        text: "est toujours en état de demande, elle n'a pas encore été traitée.",
      });
    } else if (order.status === "demande Invalide") {
      Swal.fire({
        title: `Commande numéro ${order.order_number}`,
        text: "La demande n'est pas acceptée.",
      });
    } else {
      await dispatch(addClient(order?.client_id));
      await dispatch(setOrderid(order._id));
      order.items.forEach(async (item: any) => {
        await dispatch(
          addToPanier({
            article: item.article,

            quantity: item.quantity, // Negative quantity for returns
            prix_unitaire: item.prix_unitaire, // Negative price for returns
            prix_total: item.prix_unitaire * item.quantity, // Total price negative
            paid_quantity: 0,
            remise: item.remise || 0,
            remisepropsition: item.remisepropsition,
            remiseClient: 0,
            type: order.status ==='demande Valide'? 'defaut': item?.type ? item.type:'fils',
            note:item?.note,
            prixNet: -(
              item.prix_unitaire *
              item.Qteretour *
              (item.remise / 100)
            ), // Negative net price
          })
        ); 
      });
  
      if(isOnlineSale) {
        await(dispatch(selectOrder({order_id:order._id,orderdata:order})))
      }
    //  await dispatch(resetSelectedOrder());

      navigate("/caisse");
    }
  };
  useEffect(() => {
    dispatch(resetPanier());
  }, []);

  useEffect(() => {
    getListOrder();
  }, []);
  function formatDateAndTime(timestamp: Date) {
    const dateObject = new Date(timestamp);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const seconds = dateObject.getSeconds().toString().padStart(2, "0");

    const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateAndTime;
  }
  const [stateShow, setStateShow] = useState(false);
  const [show, setShow] = useState("");

  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findUserByOrganisationApi(currentOrganisation));
      dispatch(findTicketsByOrganisationApi(currentOrganisation));
    }
  }, [dispatch, currentOrganisation]);

  const tickets = useAppSelector(ordersTickets);

  const getListOrder = async () => {
    await dispatch(findOrdersFiltreApi(filtre));
  };

  const { t } = useTranslation();
  const [facture, setFacture] = useState(false);
  const [orderId, setOrderId] = useState<String | undefined>(undefined);

  const handleOpenTicket = async (order_id: String) => {
    setOrderId(order_id);
    setFacture(true);
  };
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({ id: "", status: "" });
  const handleStatusClick = (order: OrderType) => {
    setSelectedOrder({ id: order._id, status: order.status });
    setShowStatusModal(true);
  };

  // Update selected status
  const handleStatusChange = (newStatus: any) => {
    setSelectedOrder((prev) => ({ ...prev, status: newStatus }));
  };

  const UsersOptions = listusers?.map((option) => ({
    value: option._id,
    label: option.nom + " " + option.prenom,
  }));

  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<OrderType[]>([]);

  const handleSelectToggle = () => {
    setSelectMode((prev) => !prev);
    setSelectedOrders([]); // Reset selection when exiting select mode
  };
  const selectAll = () => {
    setSelectedOrders(Orders);
  };

  const handleOrderSelect = (orderId: string, orders: OrderType[]) => {
    setSelectedOrders((prev) => {
      const isSelected = prev.some((order) => order._id === orderId);

      if (isSelected) {
        return prev.filter((order) => order._id !== orderId);
      } else {
        const selectedOrder = orders.find((order) => order._id === orderId);
        return selectedOrder ? [...prev, selectedOrder] : prev;
      }
    });
  };

  const saveStatusChange = async (
    orders: OrderType[],
    choosenStatus: string
  ) => {
    Swal.fire({
      title: "Voulez-vous confirmer ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        orders.map(async (item) => {
          const res = await dispatch(
            updateOrderApi({
              orderId: item._id,
              status: choosenStatus,
              paidProducts: [],
              vendeur: null,
            })
          );
          if (res.payload.success) {
            setSelectMode(false);
          }
        });
      }
    });



  };
  return (
    <>
      <div className="row layout-spacing">
        <div className=" row statbox widget box box-shadow">
          <div className="row">
            <div className=" col-lg-2 col-sm-6  col-md-6">
              <div className="search-bar">
                <input
                  style={{ background: "#f1f2f3", border: "none" }}
                  type="text"
                  className="form-control "
                  placeholder={t("search")}
                  onChange={(e) =>
                    setFiltre((prev) => ({
                      ...prev,
                      numOrder: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <input
              type="Date"
              className="form-control col-lg-2  col-sm-6  col-md-6"
              style={{
                background: "#f1f2f3",
                border: "none",
                width: "auto",
                height: "auto",
              }}
              id="datepicker"
              value={filtre.dateDebut}
              onChange={(e) =>
                setFiltre((prev) => ({
                  ...prev,
                  dateDebut: e.target.value,
                }))
              }
            />{" "}
            <input
              type="Date"
              className="form-control col-lg-2  col-sm-6  col-md-6"
              style={{
                background: "#f1f2f3",
                border: "none",
                width: "auto",
                height: "auto",
              }}
              id="datepicker"
              value={filtre.dateFin}
              onChange={(e) =>
                setFiltre((prev) => ({
                  ...prev,
                  dateFin: e.target.value,
                }))
              }
            />{" "}
            <Select
              isMulti
              options={UsersOptions}
              isSearchable={true}
              placeholder="caissiers"
              className="col-lg-2    col-sm-3"
              onChange={(newSelectedOptions) =>
                handleSelectChangeUsers(newSelectedOptions)
              }
            />
            <Select
              isMulti
              options={UsersOptions}
              isSearchable={true}
              placeholder="vendeurs"
              className=" col-lg-2  col-sm-3"
              onChange={(newSelectedOptions) =>
                handleSelectChangeVendeur(newSelectedOptions)
              }
            />
            <div
              className=" col-lg-2  col-sm-6  col-md-6 "
              onClick={open}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                className={"dropdown custom-dropdown-icon " + show}
                style={{ cursor: "pointer" }}
              >
                <a
                  className="dropdown-toggle btn"
                  href="#"
                  role="button"
                  id="customDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={stateShow}
                >
                  <span> {t(filtre.status)} </span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </a>
                <div
                  className={"dropdown-menu dropdown-menu-right " + show}
                  aria-labelledby="customDropdown"
                >
                  {statusList?.map(({ status, label }, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      data-value={label} // If needed for tracking
                      onClick={() =>
                        setFiltre((prev) => ({
                          ...prev,
                          status: status,
                        }))
                      }
                    >
                      {t(label)} {/* Assuming t is for translation */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <span className="col-lg-1 " onClick={getListOrder}>
              <i style={{ fontSize: "20px" }} className=" fas fa-search"></i>
            </span>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-9">
              {" "}
              <h3>{t("Orders List")}</h3>
            </div>
            {user && user.role_id?.libelle === "onlineSale" && (
              <div className="col-3">
                {" "}
                {selectMode ? (
                  <button
                    className="btn btn-primary"
                    onClick={selectAll}
                    style={{ height: "fit-content" }}
                  >
                    Sélectionner tous
                  </button>
                ) : null}
                <button
                  className={selectMode ? "btn btn-danger" : "btn btn-primary"}
                  onClick={handleSelectToggle}
                  style={{ height: "fit-content" }}
                >
                  {selectMode ? "Annuler" : "Sélectionner"}
                </button>
              </div>
            )}
          </div>
          {user &&
          user.role_id?.libelle === "onlineSale" &&
          selectedOrders.length &&
          selectMode ? (
            <div className="row">
              <div className="col-2"> Status commande</div>
              <div className="col-2">
                {" "}
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    saveStatusChange(selectedOrders, "En livraison")
                  }
                  style={{ height: "fit-content" }}
                >
                  En livraison
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  className="btn btn-danger"
                  onClick={() => saveStatusChange(selectedOrders, "Annulée")}
                  style={{ height: "fit-content" }}
                >
                  Annulée
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  className="btn btn-danger"
                  onClick={() => saveStatusChange(selectedOrders, "Retour")}
                  style={{ height: "fit-content" }}
                >
                  Retour
                </button>
              </div>
              <div className="col-2">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={handleSelectMultipleOrders}
                  style={{ height: "fit-content" }}
                >
                  Payer
                </button>
              </div>
            </div>
          ) : null}

          <div
            className="widget-content widget-content-area"
            style={{ overflowX: "auto" }}
          >
            <p style={{ float: "right" }}>Total Net : {totalNet?.toFixed(3)}</p>

            <table id="style-2" className="table style-2  table-hover">
              <thead>
                <tr>
                  <th
                    className="checkbox-column dt-no-sorting"
                    style={{ fontSize: "15px" }}
                  >
                    {t("N° commande")}
                  </th>
                  <th style={{ fontSize: "15px" }}>{t("Date")}</th>
                  {selectedOrganisation &&
                    selectedOrganisation?.Type?.table && (
                      <th style={{ fontSize: "15px" }}>{t("Table")}</th>
                    )}
                  <th style={{ fontSize: "15px" }}>{t("client")}</th>
                  <th style={{ fontSize: "15px" }}>{t("total")}</th>
                  <th style={{ fontSize: "15px" }}>{t("total net")}</th>

                  <th className="text-center" style={{ fontSize: "15px" }}>
                    {t("status")}
                  </th>
                  <th className="text-center" style={{ fontSize: "15px" }}>
                    {t("facture")}
                  </th>
                </tr>
              </thead>
              {loader ? (
                <Spinner />
              ) : (
                <tbody>
                  {Orders && Orders.length > 0 && (
                    <>
                      {Orders.map((item) => (
                        <tr key={item._id}>
                          <td
                            className="checkbox-column"
                            style={{ fontSize: "15px" }}
                          >
                            {selectMode && (
                              <input
                                type="checkbox"
                                checked={selectedOrders.includes(item)}
                                onChange={() =>
                                  handleOrderSelect(item._id, Orders)
                                }
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            <a
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleSelectOrder(item)}
                            >
                              {item.order_number}
                            </a>{" "}
                          </td>
                          <td style={{ fontSize: "15px" }}>
                            {formatDateAndTime(item.date)}
                          </td>
                          {selectedOrganisation?.Type?.table && (
                            <td style={{ fontSize: "15px" }}>
                              {item.table_id?.numero || 1}
                            </td>
                          )}
                          <td style={{ fontSize: "15px" }}>
                            {item.client_id?.nom}
                          </td>
                          <td style={{ fontSize: "15px" }}>
                            {item.total.toFixed(3)}
                          </td>
                          <td style={{ fontSize: "15px" }}>
                            {item?.totalNet?.toFixed(3)}
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "15px" }}
                          >
                            <span
                              className={
                                item.status === "Nouvelle"
                                  ? "shadow-none badge badge-info"
                                  : item.status === "En attente" ||
                                    item.status === "En cours"
                                  ? "shadow-none badge badge-warning"
                                  : item.status === "Payée"
                                  ? "shadow-none badge badge-success"
                                  : item.status === "Partiellement payée"
                                  ? "shadow-none badge badge-danger"
                                  : item.status === "En livraison"
                                  ? "shadow-none badge badge-primary"
                                  : "shadow-none badge badge-danger"
                              }
                              style={{
                                width: "100%",
                                height: "25px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                              /*onClick={() => handleStatusClick(item)}*/
                            >
                              {t(item.status)}
                            </span>
                          </td>
                          <td onClick={() => handleOpenTicket(item._id)}>
                            <a className="link" style={{ cursor: "pointer" }}>
                              {"facture"}
                            </a>{" "}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={3}></td>
                        <td colSpan={4}>
                          {Orders.reduce(
                            (acc, rec) => acc + Number(rec.totalNet),
                            0
                          )?.toFixed(3)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              )}
            </table>
          </div>
          <PaginationLayout
            index={index}
            nbrpage={currentpage}
            totalpages={totalPages}
            setindex={setindex}
          />
        </div>
      </div>
      <OnlineOrderInvoice
        order_id={orderId}
        show={facture}
        setShow={setFacture}
      />
      <OrderStatusPopUp
        show={showStatusModal}
        order={selectedOrder}
        onClose={() => setShowStatusModal(false)}
        selectedStatus={selectedOrder.status}
        onChangeStatus={handleStatusChange}
      />
    </>
  );
};

export default OrdersList;
