import React, { useEffect, useState } from "react";
import { formadate } from "../../_App/Helpers/helpers";
import { ProgramFideliteType, RemiseType } from "../../_App/Types/Entites/RemiseType";

export default function RemiseTable({
  ProgramsFidelites,
  updateFunction,
  desactiveFunction,
  activeFunction,
  t,
}: any) {
  const [List, setList] = useState<RemiseType[]>([]);
  const [keyword, setkeyword] = useState("");

  useEffect(()=>{
    setList(ProgramsFidelites)
  },[ProgramsFidelites])
  const handelsearch = () => {
    const lowercaseKeyword = keyword.toLowerCase();
    const listFiltrer = ProgramsFidelites.filter(
      (item: any) =>
        item.nom.toLowerCase().includes(lowercaseKeyword) ||
        item.prenom.toLowerCase().includes(lowercaseKeyword)
    );

    setList(listFiltrer);
    if (keyword === "") {
      setList(ProgramsFidelites);
    }
  };
  return (
    <div>
      <div className=" row ">
        <div className="col-4">
          <input
            type="text"
            className="form-control product-search br-30 "
            id="input-search"
            placeholder={t("chercher par mot cle")}
            onChange={(e) => setkeyword(e.target.value)}
          />
        </div>
        <div className="col-3">
          <button className="btn btn-primary-app" onClick={handelsearch}>
            {t("chercher")}
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Libelle")}</th>
              <th scope="col">{t("Pouçentage")}</th>
              <th scope="col">{t("Date début")}</th>
              <th scope="col">{t("Date fin")}</th>
              <th scope="col">{t("Status")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {List.map((item, index) => (
              <tr key={index}>
                <td>{item.libelle}</td>
                <td>{item.percentage}</td>
                <td>{item.date_debut?.toString().split("T")[0]}</td>
                <td>{item.date_fin?.toString().split("T")[0]}</td>
                <td> {item.actif ? "Actif" : "Inactif"} </td>

                {item.actif ? (
                  <>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                          color: "green",
                        }}
                        onClick={() => desactiveFunction(item)}
                      >
                        {" "}
                        <i className="fas fa-power-off"></i>
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                        onClick={() => updateFunction(item)}
                      >
                        <i
                          className="fas fa-edit"
                          style={{ color: "#06958d" }}
                        ></i>
                      </span>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                          color: "red",
                        }}
                        onClick={() => activeFunction(item)}
                      >
                        {" "}
                        <i className="fas fa-power-off"></i>
                      </span>
                    </td>
                    <td></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
