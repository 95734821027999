import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";


export const getTypeActivite = createAsyncThunk(
  "TypeActivite/",
  async (Null, { rejectWithValue }) => {
    try {
      const response = await axios.get("TypeActivite/");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

  
const initialState: any[]=[]
  
export const typeactiviteSlice = createSlice({
  name: "typeactivite",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
 
    builder.addCase(
        getTypeActivite.fulfilled,
       (state, action) => {

       return state = action.payload;
      }
    
    );
  },
})


export const ListTypeActivite = (state: RootState) =>state.typeactivites;
export default typeactiviteSlice.reducer;
