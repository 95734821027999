import React, { useEffect, useState } from "react";
import Numpad from "../NumPad/NumPad";
import { useTranslation } from "react-i18next";

const VirementPaymentMethod = ({ numVirement, setNumeVirement }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="navbar-item flex-row search-ul navbar-dropdown">
        <div className="nav-item align-self-center search-animated">
          <form
            className="form-inline search-full form-inline search"
            role="search"
          >
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control  ml-lg-auto"
                placeholder={t("Numéro de virement")}
                value={numVirement}
                onChange={(e) => setNumeVirement(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VirementPaymentMethod;
