import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BonTransfert from "../TransfertArticles/files/BonTransfert";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../_App/Redux/hooks";
import ReactToPrint from "react-to-print";

export default function ActionModel({ t, data, showModal, setshowModal }: any) {
  const societe = useAppSelector(societeId);
  const [type, settype] = useState("");
  const [showbon, setshowbon] = useState(false);
  const componentRef = useRef<any>();

  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      className="modal-1"
      dialogClassName="modal-90w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header closeButton>
        <Modal.Title>{data?.type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data?.type === "Transfert" && (
          <>
            <div className="row">
              <div className="col-2">
                <strong>{t(" Organisation sortie")}</strong>{" "}
                {data?.data?.organisation_transfer?.nom}
              </div>
              <div className="col-2">
                <strong>{t(" Depots sortie ")}</strong>{" "}
                {data?.data?.depots_id?.libelle}
              </div>
              <div className="col-4">
                <strong>{t(" User confirmation sortie ")}</strong>{" "}
                {data?.data?.accepter_transfer?.user_id?.nom}{" "}
                {data?.data?.accepter_transfer?.user_id?.prenom}
              </div>

              <div className="col-2">
                <strong>{t(" Agent Livraison")}</strong>{" "}
                {data?.data?.agentLivraison?.nom}
              </div>
              <div className="col-2 mt-2">
                <strong>{t(" Organisation entrée")}</strong>{" "}
                {data?.data?.organisation_demande?.nom}
              </div>
              <div className="col-2 mt-2">
                <strong>{t(" Depots entrée ")}</strong>{" "}
                {data?.data?.depots_reception?.libelle}
              </div>
              <div className="col-4 mt-2">
                <strong>{t(" User confirmation reception ")}</strong>{" "}
                {data?.data?.approver_reception?.user_id?.nom}{" "}
                {data?.data?.approver_reception?.user_id?.prenom}
              </div>

              <div className="col-2 mt-2">
                <button
                  className="btn c-primary"
                  onClick={() => {
                    settype("transfert");
                    setshowbon(true);
                  }}
                >
                  {t(" Bon Transfert ")}
                </button>
              </div>
              <div className="col-2 mt-2">
                <button
                  className="btn c-primary"
                  onClick={() => {
                    settype("reception");
                    setshowbon(true);
                  }}
                >
                  {" "}
                  {t(" Bon Reception ")}
                </button>
              </div>
            </div>
            {showbon && (
              <BonTransfert
                societeid={societe}
                data={data.data}
                t={t}
                type={type}
                setshowbon={setshowbon}
              />
            )}
          </>
        )}
        {data?.type === "Reservation" && (
          <div className="row">
            <div className="col-2">
              <strong>{t(" Organisation")}</strong>{" "}
              {data?.data?.organisation_id?.nom}
            </div>
            <div className="col-2">
              <strong>{t(" Depots")}</strong> {data?.data?.depots_id?.nom}
            </div>
            <div className="col-2">
              <strong>
                {" "}
                {data?.data?.infoclient?.client?.defaultClient
                  ? "client passagé"
                  : "Client"}
              </strong>{" "}
            </div>
            <div className="col-2">
              <strong>{t("Nom Client")}</strong> {data?.data?.infoclient?.nom}
            </div>
            <div className="col-2">
              <strong>{t(" Client Num telephone")}</strong>{" "}
              {data?.data?.infoclient?.mobile}
            </div>
            <div className="col-2">
              <strong>{t(" Commande")}</strong>{" "}
              {data?.data?.code_commande?.order_number}
            </div>
            <div className="col-2">
              <strong>{t(" Total")}</strong>{" "}
              {data?.data?.code_commande?.totalNet}
            </div>
            <div className="col-2">
              <strong>{t(" Avance")}</strong> {data?.data?.avance?.total}
            </div>
            <div className="col-2">
              <strong>{t(" Date")}</strong> {data?.data?.date.split("T")[0]}
            </div>
            <div className="col-2">
              <strong>{t(" Date echeance")}</strong>{" "}
              {data?.data?.date_echeance.split("T")[0]}
            </div>
            <div className="col-2">
              {data?.data?.payer ? "Payé" : "non payé"}
            </div>
          </div>
        )}
        {data?.type === "Reception" && (
          <div className="row">
            <div className="col-2">
              <strong>{t(" Code Reception")}</strong>{" "}
              {data?.data?.code_reception}
            </div>
            <div className="col-2">
              <strong>{t(" Organisation")}</strong>{" "}
              {data?.data?.organisation_id?.nom}
            </div>
            <div className="col-2">
              <strong>{t(" Depots")}</strong> {data?.data?.depot_id?.nom}
            </div>
            <div className="col-2">
              <strong>{t(" Fournisseur")}</strong>{" "}
              {data?.data?.fournisseur?.nom}
            </div>
          </div>
        )}
        {data?.type === "BonSortie" && (
          <>
            <div className="mt-5" ref={componentRef}>
              <div className="row mb-3 ">
                <strong className="col-3">
                  {t("Code Reception")} :{" "}
                  {data.data.reception_id?.code_reception}
                </strong>
                <strong className="col-3">
                  {t("Organisation")} :{" "}
                  {data.data.reception_id?.organisation_id?.nom}
                </strong>
                <strong className="col-3">
                  {t("Depot")} : {data.data.reception_id?.depot_id?.libelle}
                </strong>
                <strong className="col-3">
                  {t("Utilisateur")} :{" "}
                  {data.data.reception_id?.updatedBy?.nom +
                    " " +
                    data.data.reception_id?.updatedBy?.prenom}
                </strong>
              </div>
              <div className="row">
                <div className="col-4">
                  <strong>
                    {t("Article parent")}:
                    {data?.data?.ligne_reception_id?.code_article}
                  </strong>
                </div>

                <div className="col-4">
                  <strong>
                    {t("Famille")}:
                    {
                      data?.data?.ligne_reception_id?.sous_categorie?.libelle
                        ?.fr
                    }
                  </strong>
                </div>
              </div>
              <table className="table table-bordered">
                <thead className="table-primary">
                  <tr>
                    <th>{t("Code Article")}</th>
                    <th>{t("Article")}</th>
                    <th>{t("Quantité")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.data.article?.code_article}</td>
                    <td>{data.data.article?.titre?.fr}</td>
                    <td>{data.data.Quantite}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ReactToPrint
              trigger={() => <button className="btn btn-primary">Print</button>}
              content={() => componentRef.current}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
